<template>
  <div id="customers">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Customers </v-card-title
        ><v-spacer></v-spacer>
        <v-btn
          class="mr-3"
          @click="createOrUpdateReportingTeacherEmails"
          :disabled="
            creatingReportingData ||
            !initiallyLoaded ||
            !userGroups?.includes('superadmin')
          "
          :loading="creatingReportingData"
          >Create Reporting Data</v-btn
        >
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="filteredReportingTeacherArray"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :custom-filter="filterDataTable"
          :loading="!reportingTeacherSubscriptionSynced"
          loading-text="Loading Customers..."
          no-data-text="No Customers found"
        >
          <template v-slot:[`item.registrationDate`]="{ item }">
            {{ formatDate(item.registrationDate) }}
          </template>
          <template v-slot:[`item.owner`]="{ item }">
            {{ item.owner?.split("::").pop() }}
          </template>
          <template v-slot:[`item.teacherProfileSchoolId`]="{ item }">
            {{
              item.teacherProfileSchoolId
                ? schoolName(item.teacherProfileSchoolId)
                : ""
            }}
          </template>
          <!-- <template v-slot:[`item.productSubscriptions`]="{ item }">
            {{
              item.productSubscriptions?.length > 0
                ? [...new Set(JSON.parse(item.productSubscriptions))].length
                : 0
            }}
          </template> -->

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="openCustomerProductOrders(item)"
                  :disabled="offline"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-book-open
                </v-icon>
              </template>
              <span>View Orders</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="addCustomerProductOrders(item)"
                  :disabled="offline"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-book-plus-multiple-outline
                </v-icon>
              </template>
              <span>New Order</span>
            </v-tooltip>
            <!-- <v-icon small @click="convertProducts(item)" :disabled="offline">
              mdi-account-convert
            </v-icon>
            <v-icon
              small
              @click="removeDuplicateProducts(item)"
              :disabled="offline"
            >
              mdi-content-duplicate
            </v-icon> -->
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Auth, API, DataStore } from "aws-amplify";
import AWS from "aws-sdk";
import {
  School,
  Secret,
  Product,
  TeacherProfile,
  ProductCategory,
  ReportingTeacher,
} from "@/models";
import _ from "lodash";
import pako from "pako";
// import { Buffer } from "buffer";

export default {
  name: "AdminCustomers",
  data: () => ({
    creatingReportingData: false,
    initiallyLoaded: false,
    syncedCustomerArray: [],

    reportingTeacherSubscription: null,
    syncedReportingTeacherArray: [],
    reportingTeacherSubscriptionSynced: false,

    teacherProfileSubscription: null,
    syncedTeacherProfileArray: [],
    teacherProfiles: [],
    teacherProfileSubscriptionSynced: false,

    secretSubscription: null,
    syncedSecretArray: [],
    secretSubscriptionSynced: false,

    schoolSubscription: null,
    syncedSchoolArray: [],
    schoolSubscriptionSynced: false,

    timePickerDateModel: null,
    valid: false,
    dialog: false,
    sortBy: "name",
    sortDesc: true,
    search: "",
    headers: [],
    // customers: [],
    editedIndex: -1,
    editedItem: {
      label: "",
      date: null,
    },
    defaultItem: {
      label: "",
      date: null,
    },
    formRules: {
      label: [(v) => !!v || "Label is required"],
      date: [(v) => !!v || "Date is required"],
    },
  }),

  async mounted() {
    try {
      this.headers = [
        { text: "Title", value: "title" },
        { text: "Name", value: "name" },
        { text: "Surname", value: "surname" },
        {
          text: "Username",
          value: "owner",
        },
        {
          text: "Registration Date",
          value: "createdAt",
        },
        { text: "Phone", value: "phone", sortable: false },
        {
          text: "Email",
          value: "email",
          sort: (a, b) => this.customSortEmail(a, b),
        },
        { text: "User Group(s)", value: "roles" },
        // {
        //   text: "Subscription Status",
        //   value: "payfastSubscriptionResponse",
        //   sort: (a, b) => this.customSortPayfastStatus(a, b),
        // },
        { text: "Province", value: "province" },
        { text: "Language", value: "language" },
        { text: "School", value: "teacherProfileSchoolId" },
        // {
        //   text: "Products",
        //   value: "productSubscriptions",
        //   sort: (a, b) => this.customSortProducts(a, b),
        // },
        { text: "Actions", value: "actions", sortable: false },
      ];

      // Get the Customers
      this.syncSchools();
      this.syncReportingTeachers();
      // await this.getCustomers();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      userGroups: (state) => state.userGroups,
    }),

    schoolName() {
      return (schoolId) => {
        const school = _.find(this.syncedSchoolArray, { id: schoolId });
        return school ? school.name : "";
      };
    },

    filteredReportingTeacherArray() {
      return (
        this.syncedReportingTeacherArray.filter(
          (item) =>
            item.roles?.includes("teacher") || item.roles?.includes("admin")
        ) || []
      );
    },
  },

  watch: {
    userGroups: {
      handler(val) {
        // console.log("userGroups", val);
        if (val?.length > 0 && val.includes("superadmin")) {
          this.syncTeacherProfiles();
          this.syncSecrets();
        } else if (
          val?.length > 0 &&
          !val.includes("superadmin") &&
          (this.teacherProfileSubscription || this.secretSubscription)
        ) {
          this.teacherProfileSubscription.unsubscribe();
          this.syncedTeacherProfileArray = [];
          this.teacherProfileSubscriptionSynced = false;

          this.secretSubscription.unsubscribe();
          this.syncedSecretArray = [];
          this.secretSubscriptionSynced = false;
        }
      },
      immediate: true,
    },
  },

  methods: {
    openCustomerProductOrders(item) {
      // console.log("openCustomerProductOrders", item);

      // const customerOrdersPath = `/admin/productorders/customer/${
      //   item.id
      // }/${this.getCognitoAttributeValue(item.cognitoAttributes, "sub")}::${
      //   item.owner
      // }`;

      const customerOrdersPath = `/admin/productorders/customer/${item.teacherprofileID}/${item.owner}`;

      this.$router.push({ path: customerOrdersPath });
    },
    addCustomerProductOrders(item) {
      // console.log("addCustomerProductOrders", item);

      // const customerOrdersPath = `/admin/productorders/customer/${
      //   item.id
      // }/${this.getCognitoAttributeValue(item.cognitoAttributes, "sub")}::${
      //   item.owner
      // }/new`;

      const customerOrdersPath = `/admin/productorders/customer/${item.teacherprofileID}/${item.owner}/new`;

      this.$router.push({ path: customerOrdersPath });
    },
    async removeDuplicateProducts(teacherProfile) {
      if (
        !Array.isArray(teacherProfile.products) ||
        teacherProfile.products.length === 0
      ) {
        return;
      }
      const modelToConvert = teacherProfile;
      const productIdArray = modelToConvert.products;
      console.log("OLDproductIdArray", productIdArray);

      let newProductArray = [...new Set(productIdArray)];

      if (
        newProductArray.length === 0 ||
        JSON.stringify(newProductArray) === JSON.stringify(productIdArray)
      ) {
        return;
      }

      console.log("newProductArray", newProductArray);

      const queriedTeacherProfile = await DataStore.query(
        TeacherProfile,
        teacherProfile.id
      );

      console.log("queriedTeacherProfile", queriedTeacherProfile);

      await DataStore.save(
        TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
          updatedModel.products = newProductArray;
        })
      );
    },

    async addType(teacherProfile) {
      console.log("teacherProfile", teacherProfile);
      const queriedTeacherProfile = await DataStore.query(
        TeacherProfile,
        teacherProfile.id
      );

      console.log("queriedTeacherProfile", queriedTeacherProfile);

      await DataStore.save(
        TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
          updatedModel.type = "TeacherProfile";
        })
      );
    },

    async convertProducts(teacherProfile) {
      if (
        !Array.isArray(teacherProfile.products) ||
        teacherProfile.products.length === 0
      ) {
        const queriedTeacherProfile = await DataStore.query(
          TeacherProfile,
          teacherProfile.id
        );

        await DataStore.save(
          TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
            updatedModel.payfastSubscriptionPackage = {
              title: "Monthly",
              frequency: 3,
              amount: 200,
              limit: 50,
              grades: [],
              grandFathered: true,
            };
          })
        );

        return;
      }
      const modelToConvert = teacherProfile;
      const productIdArray = modelToConvert.products;
      const queryProducts = await DataStore.query(Product, (product) =>
        product.or((product) =>
          productIdArray.reduce((c, id) => c.id("eq", id), product)
        )
      );
      const queryProductCategories = await DataStore.query(ProductCategory);
      // console.log("queryProductCategories", queryProductCategories);

      let newProductArray = [];

      // Current date
      const currentDate = new Date();
      // console.log("currentDate.toISOString()", currentDate.toISOString());

      // Subtracting to get a date before this month
      const pastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
      );

      // Generating the ISO string timestamp from the past date
      const timeStamp = pastDate.toISOString();

      for (const productId of productIdArray) {
        const foundProduct = _.find(queryProducts, { id: productId });

        if (!foundProduct) continue;

        const foundCategory = _.find(queryProductCategories, {
          id: foundProduct.categoryId,
        });
        // console.log("foundCategory", foundCategory);

        let objToPush = {
          productId: productId,
          timeStamp: timeStamp,
        };

        if (foundCategory?.grade > "") {
          objToPush.grade = foundCategory.grade;
        }

        newProductArray.push(objToPush);
      }

      if (newProductArray.length === 0) {
        return;
      }
      // console.log("newProductArray", newProductArray);

      const queriedTeacherProfile = await DataStore.query(
        TeacherProfile,
        teacherProfile.id
      );

      // console.log("queriedTeacherProfile", queriedTeacherProfile);

      await DataStore.save(
        TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
          updatedModel.productSubscriptions = newProductArray;
          updatedModel.payfastSubscriptionPackage = {
            title: "Monthly",
            frequency: 3,
            amount: 200,
            limit: 50,
            grades: [],
            grandFathered: true,
          };
        })
      );
    },

    formatDate(date) {
      if (!date) return "";
      return new Date(date).toISOString().split("T")[0];
    },
    filterDataTable(value, search, item) {
      let searches = search.trim().toLowerCase().split(" ");

      let stringified = JSON.stringify(item).toLowerCase();
      let found = 0;
      for (let s in searches) {
        if (stringified.indexOf(searches[s]) > -1) found++;
      }
      // console.log(found);
      return found === searches.length;
    },
    customSortProducts(a, b) {
      const lengthA = a ? parseFloat(a.length) : 0; // Default to 0 if 'a' is null/undefined
      const lengthB = b ? parseFloat(b.length) : 0; // Default to 0 if 'b' is null/undefined

      if (this.sortDesc) {
        return lengthB - lengthA; // Descending sort
      }
      return lengthA - lengthB; // Ascending sort
    },

    customSortEmail(a, b) {
      const emailA = a.email;
      const emailB = b.email;

      if (!this.sortDesc) {
        return emailA.localeCompare(emailB);
      }

      return emailB.localeCompare(emailA);
    },

    customSortPayfastStatus(a, b) {
      const statusA = this.getPayFastStatus(a);
      const statusB = this.getPayFastStatus(b);

      if (!this.sortDesc) {
        return statusA.localeCompare(statusB);
      }

      return statusB.localeCompare(statusA);
    },

    getPayFastStatus(payFastResponse) {
      const responseObj = JSON.parse(payFastResponse);
      // const status_text = responseObj?.status_text
      //   ? responseObj.status_text
      //   : "";
      // const run_date = responseObj?.run_date ? responseObj.run_date : "";
      // return status_text + " UNTIL " + run_date.split("T")[0];
      return responseObj?.status_text ? responseObj.status_text : "";
    },

    getCognitoAttributeValue(attributesArray, attrName) {
      if (typeof attributesArray !== "object") return "";
      for (const attribute of attributesArray) {
        if (attribute.Name === attrName) {
          return attribute.Value;
        }
      }
      return "";
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSchoolArray !== items) {
              this.syncedSchoolArray = items;
              this.schoolSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.schoolSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncReportingTeachers() {
      try {
        this.reportingTeacherSubscription = DataStore.observeQuery(
          ReportingTeacher
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedReportingTeacherArray !== items
            ) {
              this.syncedReportingTeacherArray = items;
              this.reportingTeacherSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.reportingTeacherSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSecrets() {
      try {
        this.secretSubscription = DataStore.observeQuery(Secret).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSecretArray !== items) {
              this.syncedSecretArray = items;

              // console.log("this.syncedSecretArray", this.syncedSecretArray);

              this.secretSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.secretSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherProfiles() {
      try {
        this.teacherProfileSubscription = DataStore.observeQuery(
          TeacherProfile
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTeacherProfileArray !== items) {
              this.syncedTeacherProfileArray = items;

              // Populate this.teacherProfiles with items BUT dont override .cognitoAttributes if already set but all other fields, objects must be mutable
              // this.teacherProfiles = _.cloneDeep(items);
              this.teacherProfiles = _.cloneDeep(
                items.map((item) => {
                  const foundTeacherProfile =
                    this.syncedTeacherProfileArray.find(
                      (obj) => obj.id === item.id
                    );

                  if (foundTeacherProfile) {
                    return {
                      ...item,
                      cognitoAttributes: foundTeacherProfile.cognitoAttributes,
                    };
                  }

                  return item;
                })
              );

              // console.log(
              //   "this.syncedTeacherProfileArray",
              //   this.syncedTeacherProfileArray
              // );

              this.teacherProfileSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.teacherProfileSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async getCustomers() {
      try {
        const user = await Auth.currentAuthenticatedUser().catch(() => {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        });

        if (!user) return;

        await API.get("adminlambdaapi", "/item/queryCustomers", {
          queryStringParameters: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
          responseType: "text", // Ensure the response is treated as binary data
        })
          .then(async (response) => {
            // console.log("response", response);

            // Step 1: Decode the Base64 response
            const decodedBase64 = atob(response); // Decodes the Base64 string to binary

            // Step 2: Convert the decoded string to a Uint8Array (required by pako)
            const binaryArray = new Uint8Array(
              decodedBase64.split("").map((char) => char.charCodeAt(0))
            );

            // Step 3: Inflate the gzip data using pako
            const inflatedData = pako.inflate(binaryArray, { to: "string" });

            // Step 4: Parse the inflated JSON data
            const jsonResponse = JSON.parse(inflatedData);
            // console.log("jsonResponse", jsonResponse);

            if (jsonResponse.teacherProfiles) {
              this.syncedCustomerArray = jsonResponse.teacherProfiles;
              // console.log("syncedCustomerArray", this.syncedCustomerArray);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    async createOrUpdateReportingTeacherEmails() {
      this.creatingReportingData = true;

      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });

      const userPoolId = user.pool.userPoolId;

      // AWS Access Key ID and Secret Access Key------------------
      const accessKeyId = this.syncedSecretArray.find(
        (obj) => obj.type === "IAM"
      )?.value?.accessKeyId;

      const secretAccessKey = this.syncedSecretArray.find(
        (obj) => obj.type === "IAM"
      )?.value?.secretAccessKey;
      // ---------------------------------------------------------

      // console.log("keys", accessKeyId, secretAccessKey);

      AWS.config.update({
        region: "eu-west-2",
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      });

      // Create an instance of the CognitoIdentityServiceProvider
      const CognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      let allUsers = [];

      let nextPaginationToken = null;

      let fetchMore = true;

      while (fetchMore) {
        const listUsersParams = {
          AttributesToGet: ["email", "phone_number", "sub"],
          UserPoolId: userPoolId /* required */,
        };

        if (nextPaginationToken) {
          listUsersParams.PaginationToken = nextPaginationToken;
        }

        const allUsersData = await CognitoIdentityServiceProvider.listUsers(
          listUsersParams
        ).promise();

        // console.log("allUsersData:", allUsersData);

        // const allUsers = allUsersData.Users;

        allUsers.push(...allUsersData.Users);

        // Check if there are more pages (nextToken)
        if (allUsersData.PaginationToken) {
          nextPaginationToken = allUsersData.PaginationToken;
        } else {
          // No more pages left, break the loop
          fetchMore = false;
          break;
        }
      }

      // Add users to a map with usernames as keys
      const allUsersMap = {};
      allUsers.forEach((user) => {
        allUsersMap[user.Username] = user.Attributes;
      });

      // console.log("allUsersMap:", allUsersMap);

      // Match users with teacher profiles
      this.teacherProfiles.forEach((profile) => {
        const cognitoUsername = profile.owner;

        if (allUsersMap[cognitoUsername]) {
          profile.cognitoAttributes = allUsersMap[cognitoUsername];
        }
      });

      const listGroupsParams = {
        UserPoolId: userPoolId /* required */,
      };

      const groupsData = await CognitoIdentityServiceProvider.listGroups(
        listGroupsParams
      ).promise();
      const groups = groupsData.Groups;

      for (const group of groups) {
        let users = [];

        let nextUsersInGroupToken = null;

        let fetchMore2 = true;

        // Loop until all teacher profiles are retrieved or there are no more pages (no nextToken)
        while (fetchMore2) {
          const listUsersInGroupParams = {
            GroupName: group.GroupName,
            UserPoolId: userPoolId,
          };

          if (nextUsersInGroupToken) {
            listUsersInGroupParams.NextToken = nextUsersInGroupToken;
          }

          const usersData =
            await CognitoIdentityServiceProvider.listUsersInGroup(
              listUsersInGroupParams
            ).promise();
          // const users = usersData.Users;

          users.push(...usersData.Users);

          // Check if there are more pages (nextToken)
          if (usersData.NextToken) {
            nextUsersInGroupToken = usersData.NextToken;
          } else {
            // No more pages left, break the loop
            fetchMore2 = false;
            break;
          }
        }

        // Add users to a map with usernames as keys
        const usersMap = {};
        users.forEach((user) => {
          usersMap[user.Username] = true;
        });

        // Match users with teacher profiles
        this.teacherProfiles.forEach((profile) => {
          const cognitoUsername = profile.owner;

          if (
            !profile.cognitoUserGroups?.includes(group.GroupName) &&
            usersMap[cognitoUsername]
          ) {
            profile.cognitoUserGroups = profile.cognitoUserGroups || [];
            profile.cognitoUserGroups.push(group.GroupName);
          }
        });
      }

      console.log("this.teacherProfiles", this.teacherProfiles);

      try {
        for (const teacherProfile of this.teacherProfiles) {
          // console.log("teacherProfile", teacherProfile);

          // Retrieve the values from cognitoAttributes
          const email = this.getCognitoAttributeValue(
            teacherProfile.cognitoAttributes,
            "email"
          );
          const phoneNumber = this.getCognitoAttributeValue(
            teacherProfile.cognitoAttributes,
            "phone_number"
          );
          const sub = this.getCognitoAttributeValue(
            teacherProfile.cognitoAttributes,
            "sub"
          );

          // Check if a ReportingTeacher record already exists for this teacherprofileID
          const existingTeacher = await DataStore.query(ReportingTeacher, (c) =>
            c.teacherprofileID("eq", teacherProfile.id)
          );

          if (existingTeacher.length > 0) {
            // If record exists and email is different, update it
            const teacherRecord = existingTeacher[0];
            console.log("Found teacherRecord", teacherRecord);

            await DataStore.save(
              ReportingTeacher.copyOf(teacherRecord, (updated) => {
                updated.title = teacherProfile.title
                  ? teacherProfile.title
                  : null;
                updated.name = teacherProfile.name ? teacherProfile.name : null;
                updated.surname = teacherProfile.surname
                  ? teacherProfile.surname
                  : null;
                updated.language = teacherProfile.language
                  ? teacherProfile.language
                  : null;
                updated.province = teacherProfile.province
                  ? teacherProfile.province
                  : null;
                updated.registrationDate = teacherProfile.createdAt;
                updated.teacherProfileSchoolId =
                  teacherProfile.teacherProfileSchoolId
                    ? teacherProfile.teacherProfileSchoolId
                    : null;
                updated.email = email;
                updated.phone = phoneNumber;
                updated.roles = teacherProfile.cognitoUserGroups
                  ? teacherProfile.cognitoUserGroups
                  : null;
                updated.owner = `${sub}::${teacherProfile.owner}`;
                // updated.productCount= Int
              })
            );
          } else {
            // If record doesn't exist, create a new ReportingTeacher record
            await DataStore.save(
              new ReportingTeacher({
                teacherprofileID: teacherProfile.id,
                title: teacherProfile.title ? teacherProfile.title : null,
                name: teacherProfile.name ? teacherProfile.name : null,
                surname: teacherProfile.surname ? teacherProfile.surname : null,
                language: teacherProfile.language
                  ? teacherProfile.language
                  : null,
                province: teacherProfile.province
                  ? teacherProfile.province
                  : null,
                registrationDate: teacherProfile.createdAt,
                teacherProfileSchoolId: teacherProfile.teacherProfileSchoolId,
                email: email,
                phone: phoneNumber,
                roles: teacherProfile.cognitoUserGroups
                  ? teacherProfile.cognitoUserGroups
                  : null,
                owner: `${sub}::${teacherProfile.owner}`,
                // productCount: Int
              })
            );
            console.log(
              `Created new ReportingTeacher for teacherprofileID: ${teacherProfile.id}`
            );
          }
        }

        this.creatingReportingData = false;
      } catch (error) {
        console.log(error);
        this.creatingReportingData = false;
      }
    },

    // async createOrUpdateReportingTeacherEmails() {
    //   this.creatingReportingData = true;
    //   for (const syncedCustomerArrayItem of this.syncedCustomerArray) {
    //     console.log("syncedCustomerArrayItem", syncedCustomerArrayItem);

    //     try {
    //       // Retrieve the email from cognitoAttributes
    //       const email = this.getCognitoAttributeValue(
    //         syncedCustomerArrayItem.cognitoAttributes,
    //         "email"
    //       );

    //       // Check if a ReportingTeacher record already exists for this teacherprofileID
    //       const existingTeacher = await DataStore.query(ReportingTeacher, (c) =>
    //         c.teacherprofileID("eq", syncedCustomerArrayItem.id)
    //       );

    //       if (existingTeacher.length > 0) {
    //         // If record exists and email is different, update it
    //         const teacherRecord = existingTeacher[0];
    //         if (teacherRecord.email !== email) {
    //           await DataStore.save(
    //             ReportingTeacher.copyOf(teacherRecord, (updated) => {
    //               updated.email = email;
    //             })
    //           );
    //           console.log(
    //             `Updated ReportingTeacher for teacherprofileID: ${syncedCustomerArrayItem.id}`
    //           );
    //         } else {
    //           console.log(
    //             `No update needed for teacherprofileID: ${syncedCustomerArrayItem.id}`
    //           );
    //         }
    //       } else {
    //         // If record doesn't exist, create a new ReportingTeacher record
    //         await DataStore.save(
    //           new ReportingTeacher({
    //             teacherprofileID: syncedCustomerArrayItem.id,
    //             email: email,
    //           })
    //         );
    //         console.log(
    //           `Created new ReportingTeacher for teacherprofileID: ${syncedCustomerArrayItem.id}`
    //         );
    //       }
    //       this.creatingReportingData = false;
    //     } catch (error) {
    //       console.log(error);
    //       this.creatingReportingData = false;
    //     }
    //   }
    // },

    //     async deCompressGzipRequest(data) {
    //     const buffer = [];
    //     const gunzip = zlib.createGunzip();

    //     // Event handler for handling data chunks during decompression
    //     gunzip.on('data', (chunk) => {
    //         buffer.push(chunk);
    //     });

    //     // Promise to handle the end of decompression
    //     const decompressPromise = new Promise((resolve, reject) => {
    //         // Event handler for handling the end of decompression
    //         gunzip.on('end', () => {
    //             try {
    //                 const decompressedBuffer = Buffer.concat(buffer);
    //                 const jsonData = JSON.parse(decompressedBuffer);
    //                 resolve(jsonData);
    //             } catch (error) {
    //                 reject(error);
    //             }
    //         });

    //         // Event handler for handling errors during decompression
    //         gunzip.on('error', (err) => {
    //             console.error('Error during decompression:', err);
    //             reject(err);
    //         });
    //     });

    //     // Initiate decompression with the base64-encoded data
    //     gunzip.end(Buffer.from(data, 'base64'));

    //     return decompressPromise;
    // },
  },

  beforeDestroy() {
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
    if (this.reportingTeacherSubscription) {
      this.reportingTeacherSubscription.unsubscribe();
    }
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
    if (this.secretSubscription) {
      this.secretSubscription.unsubscribe();
    }
  },
};
</script>
