<template>
  <v-container fluid>
    <div
      class="d-flex flex-column"
      style="justify-content: center; align-items: center"
    >
      <!-- AWS Amplify Authenticator -->
      <amplify-auth-container
        style="display: flex; width: min-content"
        class="mb-6"
      >
        <v-card max-width="min-content" class="d-flex flex-column" flat>
          <v-card v-if="authState !== 'signedin'">
            <v-card-title class="text-h5 pb-0">Welcome back! </v-card-title>
            <v-card-text class="text-subtitle-1">
              If you've used
              <span class="primary--text">&nbsp;MissAnrisClassRoom.com</span>
              before, click below to retrieve your profile.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn to="/retrieveuserlogin" color="primary"
                >Retrieve Login</v-btn
              >
            </v-card-actions>
          </v-card>

          <v-alert
            v-if="authState !== 'signedin'"
            outlined
            dense
            type="info"
            class="my-3"
          >
            <v-row align="center">
              <v-col class="grow text-subtitle-1"
                >If you need help signing in, please send us a WhatsApp message
                to
                <a href="https://wa.me/270605294868" target="_blank"
                  >0605294868</a
                ></v-col
              >
            </v-row>
          </v-alert>

          <div
            style="
              position: right;
              align-self: end;
              z-index: 999;
              margin-top: 8px;
              margin-bottom: -40px;
              margin-right: 12px;
            "
            v-if="authState !== 'signedin'"
          >
            <helper topic="auth" />
          </div>

          <amplify-authenticator>
            <!-- Sign-in form (slot used to inject the custom sign-in component into the authenticator) -->
            <amplify-sign-in
              slot="sign-in"
              hide-sign-up="false"
              :formFields.prop="formFields"
              headerText="Existing Juffrou Anri users, Sign In here"
            >
              <!-- <div slot="hint" class="password-requirements">
            Password requirements:
            <ul>
              <li>Minimum length 8 character(s)</li>
              <li>Contains at least 1 number</li>
              <li>Contains at least 1 special character</li>
              <li>Contains at least 1 uppercase letter</li>
              <li>Contains at least 1 lowercase letter</li>
            </ul>
          </div> -->
            </amplify-sign-in>
          </amplify-authenticator>
        </v-card>
      </amplify-auth-container>
    </div>
  </v-container>
</template>

<script>
import "@aws-amplify/ui/dist/style.css";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import Helper from "@/components/global-components/Helper.vue";
import store from "@/store";
import { API, Auth } from "aws-amplify";

export default {
  name: "VueAuthenticator",
  components: { Helper },
  data() {
    return {
      authState: undefined, // Holds the current authentication state
      unsubscribeAuth: undefined, // Function to unsubscribe from auth state changes
      // Form fields for sign-in form (email and password)
      formFields: [
        // {
        //   type: "email",
        //   inputProps: { required: true, autocomplete: "Username" },
        // },
        // {
        //   type: "password",
        //   inputProps: { required: true, autocomplete: "Password" },
        // },
      ],
    };
  },

  created() {
    // Subscribe to authentication state changes
    // and update the authState property accordingly
    this.unsubscribeAuth = onAuthUIStateChange((authState) => {
      this.authState = authState;
    });
  },

  watch: {
    // Watcher to react to changes in authentication state
    authState: {
      async handler() {
        // console.log("authState: ", this.authState);
        // If user is signed in
        if (this.authState === AuthState.SignedIn) {
          try {
            await this.getUserGroups();

            // Redirect user
            if (this.$route.query.redirect) {
              // this.$router.push({ path: this.$route.query.redirect });

              window.location.href = this.$route.query.redirect;
            } else {
              // this.$router.push({ path: "/" });

              window.location.href = "/";
            }
          } catch (error) {
            console.error("Error fetching user group: ", error);
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getUserGroups() {
      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });

      if (user) {
        await API.post("cognitolambdaapi", "/item/listUserGroups", {
          body: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
        })
          .then(async (cognitoListUserGroupsResponse) => {
            if (cognitoListUserGroupsResponse.list) {
              const userGroups = JSON.parse(cognitoListUserGroupsResponse.list);
              // console.log('userGroups: ', userGroups)
              store.commit("storeUserGroups", userGroups);
            }
          })
          .catch((error) => {
            // Log any errors
            console.log("error:", error);
          });
      }
    },
  },

  beforeDestroy() {
    // Unsubscribe from authentication state changes
    // when the component is destroyed
    this.unsubscribeAuth();
  },
};
</script>

<style>
/* Customization of the AWS Amplify colors */
:root {
  --amplify-primary-color: #ff0082;
  --amplify-primary-tint: #ffa9b8;
  --amplify-primary-shade: #a06b74;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
}

amplify-authenticator {
  margin-bottom: -20px;
  --container-display: contents !important;
}

amplify-section .section {
  border-radius: none !important;
}
</style>
