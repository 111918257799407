<template>
  <div id="teachersubjecttemplates">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4">
          {{ $i18n.t("headings.lessons") }}
          <helper topic="lessons" />
        </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="openLessonTemplatesDialog"
          :disabled="offline"
        >
          {{ $i18n.t("otherText.new") + " " + $i18n.t("headings.lesson") }}
        </v-btn>
      </v-row>
      <v-card-title
        ><v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="`${$i18n.t('labels.search')}`"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="search"
              :items="syncedSchoolArray"
              :item-text="schoolName"
              :label="`${$i18n.t('labels.school')}`"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="computedTeacherSubjectTemplates"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :custom-filter="filterDataTable"
          :loading="
            !teacherSubjectTemplateSubscriptionSynced &&
            computedTeacherSubjectTemplates.length === 0
          "
          loading-text="Loading Lessons..."
          no-data-text="No Lessons found"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="duplicateItem(item)">
              mdi-content-copy
            </v-icon>
            <v-icon small class="mr-2" @click="printItem(item.id)">
              mdi-printer
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <!-- <v-dialog persistent v-model="dialog" v-if="dialog">
      <div>
        <v-card>
          <v-form ref="form" v-model="valid" @submit.prevent="save">
            <v-row no-gutters :style="screenWidth >= 960 ? 'overflow-y: hidden;' : 'overflow-y: auto;'
              " style="max-height: calc(90vh - 52px)">
              <v-col cols="12" md="6" class="pa-0" :style="screenWidth >= 960
                ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(90vh - 52px);'
                : ''
                ">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}
                    <helper topic="lessonedit" />
                  </span>
                </v-card-title>

                <v-expansion-panels class="px-2 mb-3" style="justify-content: left;" v-model="expPanels">

                  <div style="width: 100%;">
                    <v-card-subtitle class="pb-0 pt-2">
                      <span class="text-h6">Headings</span>
                    </v-card-subtitle>
                    <v-expansion-panel class="panelTopBorder">
                      <v-expansion-panel-header>
                        Change Defaults
                      </v-expansion-panel-header>
                      <v-expansion-panel-content id="expPanelContent" color="grey lighten-3">
                        <v-row class="py-2">
                          <v-col cols="12" sm="6" lg="4" class="py-0"
                            v-for="(heading, headingIndex) in editedItem.headings" :key="headingIndex">
                            <v-text-field v-model="heading.value" :label="heading.label" required
                              :rules="formRules.required" type="text"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>

                  <v-card-subtitle class="pb-0 pt-2">
                    <span class="text-h6">Lesson</span>
                  </v-card-subtitle>
                  <v-expansion-panel class="panelTopBorder">
                    <v-expansion-panel-header>
                      Details
                    </v-expansion-panel-header>
                    <v-expansion-panel-content id="expPanelContent" color="grey lighten-3">
                      <v-row class="py-2">
                        <v-col cols="12" sm="6" class="py-0">
                          <v-text-field v-model="editedItem.name" label="Name" :rules="formRules.name"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                          <v-autocomplete v-model="editedItem.subject" :items="subjects" :item-text="subjectName"
                            label="Subject" return-object required :rules="formRules.subject"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                          <v-autocomplete v-model="editedItem.school" :items="syncedSchoolArray" :item-text="schoolName"
                            label="School" return-object required :rules="formRules.school"></v-autocomplete>
                        </v-col>
                        <v-col cols="4" sm="3" class="py-0">
                          <v-select v-model="editedItem.complexity" label="Complexity" :items="complexityLevels" required
                            :rules="formRules.complexity"></v-select>
                        </v-col>
                        <v-col cols="4" sm="3" class="py-0">
                          <v-select v-model="editedItem.fontFamily" label="Font Family"
                            :items="fontFamilyItems"></v-select>
                        </v-col>
                        <v-col cols="4" sm="2" class="py-0">
                          <v-text-field v-model="editedItem.minutes" label="Time in minutes" type="number"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <div v-if="editedItem.template?.length > 0" class="plannerPanelTopBorder" style="width: 100%;">
                    <v-card-subtitle class="pb-0 pt-2">
                      <span class="text-h6">Planner</span>
                    </v-card-subtitle>
                    <draggable v-model="editedItem.template" style="width: 100%;">
                      <v-expansion-panel v-for="(templateObject, i) in editedItem.template" :key="i">
                        <v-expansion-panel-header class="pa-2">
                          <div class="d-flex align-center">
                            <v-icon class="mr-1">
                              mdi-drag-vertical
                            </v-icon>
                            <div style="text-wrap: nowrap;">
                              <span>Step {{ (i + 1) + ((templateObject.name && templateObject.name
                                !== "") ? ":&nbsp;" : "") }}</span>
                            </div>
                            <div>
                              <span>
                                {{ ((templateObject.name && templateObject.name !== '')
                                  ? templateObject.name : '')
                                }}
                              </span>
                            </div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="expPanelContent" color="grey lighten-3">
                          <v-row class="pa-3">
                            <v-col cols="12" class="py-0">
                              <v-text-field v-model="templateObject.name" label="Title" :rules="formRules.title"
                                required></v-text-field>
                            </v-col>
                          </v-row>

                          <div v-if="templateObject.fields.length > 0" style="width: 100%;" class="plannerPanelTopBorder">
                            <v-card-title class="pa-0">
                              <span class="text-h6">Fields</span>
                            </v-card-title>


                            <v-expansion-panels class="mb-3" style="justify-content: left;" v-model="expFieldPanels">
                              <draggable v-model="templateObject.fields" style="width: 100%;">
                                <v-expansion-panel v-for="(formField, index) in templateObject.fields" :key="index">
                                  <v-expansion-panel-header class="pa-2">
                                    <div class="d-flex align-center">
                                      <v-icon class="mr-1">
                                        mdi-drag-vertical
                                      </v-icon>
                                      <div style="text-wrap: nowrap;">
                                        <span>Field{{ ((formField.label && formField.label
                                          !== "") ? ":&nbsp;" : "") }}</span>
                                      </div>
                                      <div>
                                        <span>
                                          {{
                                            ((formField.label && formField.label !== "")
                                              ? formField.label
                                              : "")
                                          }}</span>
                                      </div>
                                    </div>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content id="expPanelContent" color="grey lighten-3">
                                    <v-row class="pt-3">
                                      <v-col cols="12" sm="6" md="4" class="py-0">
                                        <v-text-field v-model="formField.label" label="Label" :rules="formRules.label"
                                          required></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4" class="py-0">
                                        <v-select v-model="formField.type" label="Type" :rules="formRules.type" required
                                          :items="formFieldTypes"></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4" class="py-0">
                                        <v-text-field v-model="formField.placeholder" label="Placeholder"
                                          :rules="formRules.placeholder" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4" class="py-0">
                                        <v-text-field v-model="formField.hint" label="Hint" :rules="formRules.hint"
                                          required></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4" class="py-0">
                                        <v-text-field v-model="formField.difficulty" label="Difficulty"
                                          type="number"></v-text-field>
                                      </v-col>
                                      <v-col v-if="formField.type === 'Select' ||
                                        formField.type === 'Radio' ||
                                        formField.type === 'Checkbox'
                                        " cols="12" sm="6" md="4" class="py-0">
                                        <v-combobox v-model="formField.items" :label="formField.type === 'Select'
                                          ? 'Select Values'
                                          : formField.type === 'Radio'
                                            ? 'Radio Values'
                                            : formField.type === 'Checkbox'
                                              ? 'Checkbox Values'
                                              : ''
                                          " :error-messages="comboboxErrors(formField.items, formField.type)" required
                                          multiple chips deletable-chips clearable lazy-validation>
                                        </v-combobox>
                                      </v-col>
                                    </v-row>

                                    <v-card-title class="px-0 py-1">
                                      <span class="text-h6">Field</span>
                                    </v-card-title>
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <v-text-field v-if="formField.type === 'Text'" v-model="formField.value"
                                          :label="formField.label"></v-text-field>
                                        <v-textarea v-else-if="formField.type === 'Textarea'" v-model="formField.value"
                                          :label="formField.label" :hint="formField.hint"
                                          :placeholder="formField.placeholder"
                                          @dblclick="copyPlaceholderToValue(formField)" auto-grow rows="1"></v-textarea>
                                        <v-select v-else-if="formField.type === 'Select'" v-model="formField.value"
                                          :label="formField.label" :items="formField.items" :hint="formField.hint"
                                          :placeholder="formField.placeholder"></v-select>
                                        <v-radio-group v-else-if="formField.type === 'Radio'" v-model="formField.value"
                                          :label="formField.label" :hint="formField.hint">
                                          <v-radio v-for="(item, itemIndex) in formField.items" :key="'item-' + itemIndex"
                                            :label="item" :value="item"></v-radio>
                                        </v-radio-group>
                                        <template v-else-if="formField.type === 'Checkbox'">
                                          <v-checkbox v-for="(item, itemIndex) in formField.items" :key="'checkbox-' +
                                            i +
                                            '-' +
                                            index +
                                            '-' +
                                            itemIndex
                                            " v-model="formField.value" :label="item" :value="item"></v-checkbox>
                                        </template>
                                      </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                      <v-spacer />
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="red" fab small icon v-bind="attrs" v-on="on"
                                            @click="removeField(index, i)">
                                            <v-icon>mdi-delete</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Delete Field</span>
                                      </v-tooltip>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </draggable>
                            </v-expansion-panels>
                          </div>
                          <v-row no-gutters>
                            <v-btn color="primary" @click="addField(i)">
                              Add Field
                            </v-btn>
                            <v-spacer />
                            <v-btn color="secondary" @click="removeTemplateObject(i)">
                              Remove Step
                            </v-btn>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </draggable>
                  </div>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" class="pa-0" :style="screenWidth >= 960
                ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(90vh - 52px);'
                : ''
                ">
                <div>
                  <v-card-title>
                    <span class="text-h5">Print Preview</span>
                  </v-card-title>
                  <v-card outlined class="mx-2 pa-2">
                    <LessonTemplatePrinterExample :template="editedItem" />
                  </v-card>
                </div>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn color="primary" @click="addTemplateObject">
                Add Step
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close"> Cancel </v-btn>
              <v-btn :disabled="!valid" color="primary" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </div>
    </v-dialog> -->
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("otherText.deleteTemplate")
        }}</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="closeDelete">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteItemConfirm">{{
            $i18n.t("actions.ok")
          }}</v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialogLessonPlanner"
      v-if="dialogLessonPlanner"
    >
      <div>
        <v-card>
          <v-form
            ref="plannerForm"
            v-model="valid"
            @submit.prevent="submitLessonPlannerForm"
          >
            <v-row
              no-gutters
              :style="
                screenWidth >= 960 ? 'overflow-y: hidden;' : 'overflow-y: auto;'
              "
              style="max-height: calc(90vh - 52px)"
            >
              <v-col
                cols="12"
                md="6"
                class="pa-0"
                :style="
                  screenWidth >= 960
                    ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(90vh - 52px);'
                    : ''
                "
              >
                <v-card-title>
                  <span class="text-h5">{{ $i18n.t("headings.planner") }}</span>
                </v-card-title>

                <v-expansion-panels
                  class="px-2 mb-3"
                  style="justify-content: left"
                  v-model="expPanels"
                >
                  <v-card-subtitle class="pb-0 pt-2">
                    <span class="text-h6">{{
                      $i18n.t("headings.headings")
                    }}</span>
                  </v-card-subtitle>
                  <v-expansion-panel class="panelTopBorder">
                    <v-expansion-panel-header>
                      {{
                        $i18n.t("otherText.change") +
                        " " +
                        $i18n.t("headings.defaults")
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      id="expPanelContent"
                      color="grey lighten-3"
                    >
                      <v-row class="py-2">
                        <v-col
                          cols="12"
                          sm="6"
                          lg="4"
                          class="py-0"
                          v-for="(heading, headingIndex) in editedItem.headings"
                          :key="headingIndex"
                        >
                          <v-text-field
                            v-model="heading.value"
                            :label="heading.label"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-card-subtitle class="pb-0 pt-2">
                    <span class="text-h6">{{
                      $i18n.t("headings.lesson")
                    }}</span>
                  </v-card-subtitle>
                  <v-expansion-panel class="panelTopBorder">
                    <v-expansion-panel-header>
                      {{ $i18n.t("labels.details") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      id="expPanelContent"
                      color="grey lighten-3"
                    >
                      <v-row class="py-2">
                        <v-col cols="12" lg="4" sm="6" class="py-0">
                          <v-text-field
                            v-model="editedItem.name"
                            :label="`${
                              $i18n.t('otherText.your') +
                              ' ' +
                              $i18n.t('headings.template') +
                              ' ' +
                              $i18n.t('labels.name')
                            }`"
                            hint="The name of your new template"
                            required
                            :rules="formRules.name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" sm="6" class="py-0">
                          <v-autocomplete
                            v-model="editedItem.school"
                            :items="syncedSchoolArray"
                            :item-text="schoolName"
                            :label="`${$i18n.t('labels.school')}`"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="4" sm="6" class="py-0">
                          <v-autocomplete
                            v-model="editedItem.subject"
                            :items="
                              subjects?.length > 0
                                ? subjects.filter((a) =>
                                    syncedTeacherClassSubjectIDArray.some(
                                      (b) => a.id === b
                                    )
                                  )
                                : []
                            "
                            :item-text="subjectName"
                            :label="`${$i18n.t('labels.subject')}`"
                            return-object
                            required
                            :rules="formRules.subject"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" lg="4" class="py-0">
                          <v-select
                            v-model="editedItem.complexity"
                            :label="`${$i18n.t('labels.complexity')}`"
                            :items="complexityLevels"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" lg="4" class="py-0">
                          <v-select
                            v-model="editedItem.fontFamily"
                            :label="`${
                              $i18n.t('typography.font') +
                              ' ' +
                              $i18n.t('otherText.family')
                            }`"
                            :items="fontFamilyItems"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" lg="4" class="py-0">
                          <v-text-field
                            v-model="editedItem.minutes"
                            :label="`${
                              $i18n.t('labels.time') +
                              ' ' +
                              $i18n.t('otherText.in') +
                              ' ' +
                              $i18n.t('labels.minutes')
                            }`"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-stepper v-model="activeStep" flat v-if="editedItem">
                  <v-stepper-header style="height: auto">
                    <template
                      v-for="(templateStep, index) in editedItem.template"
                    >
                      <v-stepper-step
                        :complete="activeStep > index + 1"
                        :key="'step-' + index"
                        :step="index + 1"
                      >
                        {{ templateStep.name }}
                      </v-stepper-step>
                      <v-divider
                        v-if="index < editedItem.template.length - 1"
                        :key="'divider-' + index"
                      ></v-divider>
                    </template>
                  </v-stepper-header>
                  <v-progress-linear
                    :value="progress"
                    height="10"
                    color="primary"
                  ></v-progress-linear>

                  <v-stepper-items>
                    <template
                      v-for="(
                        templateObject, secondIndex
                      ) in editedItem.template"
                    >
                      <v-stepper-content
                        :key="'content-' + secondIndex"
                        :step="secondIndex + 1"
                        class="pa-5"
                      >
                        <v-form :ref="'form' + secondIndex" class="pa-1">
                          <template
                            v-for="(field, fieldIndex) in templateObject.fields"
                          >
                            <v-text-field
                              v-if="field.type === 'Text'"
                              :key="'text-' + secondIndex + '-' + fieldIndex"
                              v-model="field.value"
                              :label="field.label"
                              :hint="field.hint"
                              :placeholder="field.placeholder"
                              @dblclick="copyPlaceholderToValue(field)"
                            ></v-text-field>
                            <v-textarea
                              v-if="field.type === 'Textarea'"
                              :key="
                                'textarea-' + secondIndex + '-' + fieldIndex
                              "
                              v-model="field.value"
                              :label="field.label"
                              :hint="field.hint"
                              :placeholder="field.placeholder"
                              @dblclick="copyPlaceholderToValue(field)"
                              auto-grow
                            ></v-textarea>
                            <v-select
                              v-if="
                                field.type === 'Select' ||
                                field.type === 'Multi Select'
                              "
                              :key="'select-' + secondIndex + '-' + fieldIndex"
                              v-model="field.value"
                              :label="field.label"
                              :items="field.items"
                              :hint="field.hint"
                              :placeholder="field.placeholder"
                              :multiple="field.type === 'Multi Select'"
                            ></v-select>
                            <v-radio-group
                              v-if="field.type === 'Radio'"
                              :key="'radio-' + secondIndex + '-' + fieldIndex"
                              v-model="field.value"
                              :label="field.label"
                              :hint="field.hint"
                            >
                              <v-radio
                                v-for="(item, itemIndex) in field.items"
                                :key="'item-' + itemIndex"
                                :label="item"
                                :value="item"
                              ></v-radio>
                            </v-radio-group>
                            <template v-if="field.type === 'Checkbox'">
                              <v-checkbox
                                v-for="(item, itemIndex) in field.items"
                                :key="
                                  'checkbox-' +
                                  secondIndex +
                                  '-' +
                                  fieldIndex +
                                  '-' +
                                  itemIndex
                                "
                                v-model="field.value"
                                :label="item"
                                :value="item"
                              ></v-checkbox>
                            </template>
                          </template>
                          <v-btn
                            class="mr-2 black--text"
                            color="secondary"
                            @click="previousStep"
                            v-if="activeStep > 1"
                            >{{ $i18n.t("movement.back") }}</v-btn
                          >
                          <v-btn
                            color="primary"
                            @click="nextStep"
                            v-if="activeStep < editedItem.template.length"
                            >{{ $i18n.t("movement.next") }}</v-btn
                          >
                          <!-- <v-btn color="primary" @click="submitLessonPlannerForm"
                      v-if="activeStep === editedItem.template.length"
                      :disabled="progress < 100 || !valid || offline">Save</v-btn> -->
                        </v-form>
                      </v-stepper-content>
                    </template>
                  </v-stepper-items>
                </v-stepper>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="pa-0"
                :style="
                  screenWidth >= 960
                    ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(90vh - 52px);'
                    : ''
                "
              >
                <div>
                  <v-card-title>
                    <span class="text-h5">{{
                      $i18n.t("headings.print") +
                      " " +
                      $i18n.t("headings.preview")
                    }}</span>
                  </v-card-title>
                  <v-card outlined class="mx-2 pa-2">
                    <LessonTemplatePrinterExample :template="editedItem" />
                  </v-card>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="closeDialogLessonPlanner">
                {{ $i18n.t("actions.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="submitLessonPlannerForm"
                v-if="activeStep === editedItem.template.length"
                :disabled="progress < 100 || !valid || offline"
                >{{ $i18n.t("actions.save") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialogNewOrExistingTemplate"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("lessons.createFrom")
        }}</v-card-title>
        <v-row no-gutters class="py-3 px-4" style="gap: 12px">
          <v-btn color="primary" @click="closeNewOrExistingTemplate">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
          <div
            class="d-flex align-center"
            :class="screenWidth < 422 ? 'flex-wrap' : ''"
            style="gap: 12px"
          >
            <v-btn color="primary" @click="openLessonTemplatesDialog">{{
              $i18n.t("headings.lesson") + " " + $i18n.t("headings.template")
            }}</v-btn>
            <span class="text-uppercase">{{ $i18n.t("otherText.or") }}</span>
            <v-btn color="primary" @click="createItem">{{
              $i18n.t("otherText.blank") + " " + $i18n.t("headings.lesson")
            }}</v-btn>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogLessonTemplates">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("lessons.pickLesson")
        }}</v-card-title>
        <v-card-subtitle class="pb-0 pt-2 text-center">
          <!-- syncedTeacherProfileModel?.payfastSubscriptionPackage?.grandFathered
               ? $i18n.t("templates.restrictedGrandFathered")
               : $i18n.t("templates.restricted")-->
          {{ $i18n.t("templates.restrictedGrandFathered") }}
        </v-card-subtitle>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="searchLessonTemplates"
                append-icon="mdi-magnify"
                :label="`${$i18n.t('labels.search')}`"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="px-4">
          <v-data-table
            :footer-props="{
              showFirstLastPage: true,
            }"
            v-model="selectedLessonTemplate"
            :headers="lessonTemplateHeaders"
            :items="computedSubjectTemplates"
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            must-sort
            :search="searchLessonTemplates"
            single-select
            show-select
            :custom-filter="filterDataTable"
            :loading="
              !initiallyLoaded && computedSubjectTemplates?.length === 0
            "
            loading-text="Loading Lessons..."
            no-data-text="No Lessons found"
          >
            <!-- <template v-slot:[`item.totalMinutes`]="{ item }">
              {{ item.totalMinutes ? formattedTotalTime(item.totalMinutes) : "" }}
            </template> -->
          </v-data-table>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeLessonTemplates">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-btn
            color="primary"
            :disabled="selectedLessonTemplate.length < 1"
            @click="createItemFromTemplate"
            >{{ $i18n.t("actions.create") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import {
  SubjectTemplate,
  TeacherClassSubject,
  TeacherSubjectTemplate,
  School,
  Subject,
} from "@/models";
import _ from "lodash";
import LessonTemplatePrinterExample from "@/components/global-components/LessonTemplatePrinterExample.vue";
import Helper from "@/components/global-components/Helper.vue";
// import draggable from 'vuedraggable';

export default {
  name: "TeacherSubjectTemplates",

  components: {
    LessonTemplatePrinterExample,
    Helper,
    // draggable
  },

  data: () => ({
    initiallyLoaded: false,
    expPanels: 1,
    expFieldPanels: 0,
    // Planner
    dialogLessonPlanner: false,
    selectedGrade: null,
    selectedSubject: null,
    subjectTemplateSubscription: null,
    syncedSubjectTemplateArray: [],
    subjectTemplates: [],
    teacherClassSubjectSubscription: null,
    syncedTeacherClassSubjectArray: [],
    syncedTeacherClassSubjectIDArray: [],
    activeStep: 1,
    // duplicatingSubjectTemplate: false,
    // -----
    dialogNewOrExistingTemplate: false,
    dialogLessonTemplates: false,
    selectedLessonTemplate: [],
    lessonTemplateHeaders: [],
    teacherSubjectTemplateSubscription: null,
    teacherSubjectTemplateSubscriptionSynced: false,
    syncedTeacherSubjectTemplateArray: [],
    schoolSubscription: null,
    syncedSchoolArray: [],
    subjectSubscription: null,
    syncedSubjectArray: [],
    syncedSubscribedSubjectIDArray: [],
    subjects: [],
    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "name",
    sortDesc: false,
    search: "",
    searchLessonTemplates: "",
    headers: [
      // { text: "Name", value: "name" },
      // { text: "Type", value: "type" },
      // { text: "Complexity", value: "complexity" },
      // { text: "Minutes", value: "minutes" },
      // { text: "School", value: "school.name" },
      // { text: "Grade", value: "subject.grade" },
      // { text: "Subject", value: "subject.subject" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    teacherSubjectTemplates: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        // { label: 'Difficulty', value: 'Difficulty' },
      ],
      fontFamily: "Default",
      complexity: "",
      totalPoints: null,
      minutes: null,
      template: [
        // {
        //   name: "",
        //   fields: [
        //     {
        //       type: "",
        //       label: "",
        //       value: "",
        //       hint: "",
        //       placeholder: "",
        //       difficulty: null,
        //       points: null,
        //       items: []
        //     }
        //   ]
        // }
      ],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
    },
    defaultItem: {
      name: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        // { label: 'Difficulty', value: 'Difficulty' },
      ],
      fontFamily: "Default",
      complexity: "",
      totalPoints: null,
      minutes: null,
      template: [],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
    },
    formRules: {
      required: [(v) => !!v || "This field is required"],
      name: [(v) => !!v || "Name is required"],
      type: [(v) => !!v || "Type is required"],
      label: [(v) => !!v || "Label is required"],
      title: [(v) => !!v || "Title is required"],
      subject: [(v) => !!v || "Subject is required"],
      school: [(v) => !!v || "School is required"],
      complexity: [(v) => !!v || "Complexity is required"],
      placeholder: [(v) => !!v || "Placeholder is required"],
      hint: [(v) => !!v || "Hint is required"],
      points: [(v) => !!v || "Points are required"],
      minutes: [(v) => !!v || "Time is required"],
      difficulty: [(v) => !!v || "Difficulty is required"],
      value: [(v) => !!v || "Value is required"],
    },
    formFieldTypes: [
      "Text",
      "Textarea",
      "Select",
      "Multi Select",
      "Radio",
      "Checkbox",
    ],
    templateTypes: ["Lesson"],
    showTemplateType: "Lesson",
    complexityLevels: ["Simple", "Detailed"],
    fontFamilyItems: ["Default", "Arial"],
  }),

  async mounted() {
    try {
      this.headers = [
        { text: this.$i18n.t("labels.name"), value: "name" },
        // { text: "Type", value: "type" },
        { text: this.$i18n.t("labels.complexity"), value: "complexity" },
        { text: this.$i18n.t("labels.minutes"), value: "minutes" },
        { text: this.$i18n.t("labels.school"), value: "school.name" },
        {
          text: this.$i18n.t("headings.grade"),
          value: "subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: this.$i18n.t("headings.subject"), value: "subject.subject" },
        {
          text: this.$i18n.t("labels.actions"),
          value: "actions",
          sortable: false,
        },
      ];
      this.lessonTemplateHeaders = [
        { text: this.$i18n.t("labels.name"), value: "name" },
        // { text: "Type", value: "type" },
        { text: this.$i18n.t("labels.complexity"), value: "complexity" },
        // { text: "Minutes", value: "minutes" },
        { text: this.$i18n.t("labels.school"), value: "school.name" },
        {
          text: this.$i18n.t("headings.grade"),
          value: "subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: this.$i18n.t("headings.subject"), value: "subject.subject" },
        // { text: "Actions", value: "actions", sortable: false },
      ];

      await this.syncTeacherClassSubject();

      await this.syncTeacherSubjectTemplates();

      // await this.syncSchools();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      teacherProfileSchoolID: (state) => state.teacherProfileSchoolID,
      teacherProfileProvince: (state) => state.teacherProfileProvince,
      offline: (state) => !state.isOnline,
      syncedTeacherProfileModel: (state) => state.syncedTeacherProfileModel,
    }),

    computedTeacherSubjectTemplates() {
      if (this.subjects.length > 0 && this.syncedSchoolArray.length > 0) {
        this.teacherSubjectTemplates.forEach((item) => {
          if (!item.subject) {
            const subject = _.find(this.subjects, { id: item.subjectID });
            if (item.subject !== subject)
              item.subject = subject || { grade: "", subject: "" };
          }
          if (!item.school) {
            const school = _.find(this.syncedSchoolArray, {
              id: item.schoolID,
            });
            if (item.school !== school)
              item.school = school || {
                name: "",
                provinces: [],
                isGeneric: false,
              };
          }
        });
      }
      return this.teacherSubjectTemplates;
    },

    computedSubjectTemplates() {
      if (this.subjects.length > 0 && this.syncedSchoolArray.length > 0) {
        this.subjectTemplates.forEach((item) => {
          if (!item.subject) {
            const subject = _.find(this.subjects, { id: item.subjectID });
            if (item.subject !== subject)
              item.subject = subject || { grade: "", subject: "" };
          }

          if (!item.school) {
            const school = _.find(this.syncedSchoolArray, {
              id: item.schoolID,
            });
            if (item.school !== school) {
              item.school = school || {
                name: "",
                provinces: [],
                isGeneric: false,
              };
            }
          }
        });
      }
      return this.subjectTemplates;
    },

    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Lesson" : "Edit Lesson";
    },

    progress() {
      let completedFields = 0;
      let totalFields = 0;

      for (let step of this.editedItem.template) {
        for (let field of step.fields) {
          totalFields++;
          if (Array.isArray(field.value)) {
            if (field.value.length > 0) {
              completedFields++;
            }
          } else if (field.value) {
            completedFields++;
          }
        }
      }
      // Return the percentage of completed fields.
      return (completedFields / totalFields) * 100;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    async valid(val) {
      if (!val && this.dialog) {
        await this.validateForm();
      }
    },

    async expPanels() {
      this.expFieldPanels = 0;
      await this.validateForm();
    },

    syncedTeacherProfileModel: {
      handler: function (val) {
        if (val) {
          if (!this.schoolSubscription) {
            this.syncSchools();
          }
          if (!this.subjectSubscription) {
            this.syncSubjects();
          }
        }
      },
      immediate: true,
      deep: true,
    },

    syncedSchoolArray: {
      handler: function (val) {
        if (
          !this.subjectTemplateSubscription &&
          val.length > 0 &&
          this.syncedTeacherClassSubjectIDArray.length > 0
        ) {
          this.syncSubjectTemplates();
        }
      },
      immediate: true,
      deep: true,
    },

    syncedTeacherClassSubjectIDArray: {
      handler: function (val) {
        if (
          !this.subjectTemplateSubscription &&
          val.length > 0 &&
          this.syncedSchoolArray.length > 0
        ) {
          this.syncSubjectTemplates();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    filterDataTable(value, search, item) {
      let searches = search.trim().toLowerCase().split(" ");

      let stringified = JSON.stringify(item).toLowerCase();
      let found = 0;
      for (let s in searches) {
        if (stringified.indexOf(searches[s]) > -1) found++;
      }
      // console.log(found);
      return found === searches.length;
    },

    async validateForm() {
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.validate();
      }, 0);
    },

    nextStep() {
      if (this.activeStep < this.editedItem.template.length) {
        this.activeStep++;
      }
    },
    previousStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },
    copyPlaceholderToValue(field) {
      if (!field.value) {
        field.value = field.placeholder;
      }
    },

    comboboxErrors(itemArray, fieldType) {
      if (fieldType === "Select" && itemArray.length < 1) {
        return "Select values are required";
      } else if (fieldType === "Radio" && itemArray.length < 1) {
        return "Radio values are required";
      } else if (fieldType === "Checkbox" && itemArray.length < 1) {
        return "Checkbox values are required";
      } else return "";
    },

    customSortGrade(a, b) {
      a = a === "R" ? -Infinity : parseInt(a, 10);
      b = b === "R" ? -Infinity : parseInt(b, 10);
      if (this.sortDesc) {
        // Switch the order for descending
        [a, b] = [b, a];
      }
      return a - b;
    },

    schoolName(item) {
      return `${item.name}`;
    },

    subjectName(item) {
      return `${"Grade: " + item.grade + ", Subject: " + item.subject}`;
    },

    async addField(i) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template[i].fields.push({
          type: "",
          label: "",
          value: "",
          hint: "",
          placeholder: "",
          // difficulty: null,
          // points: null,
          items: [],
        });

        // this.editedItem = Object.assign({}, mutableObj);
        this.editedItem = mutableObj;

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeField(fieldIndex, templateIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template[templateIndex].fields.splice(fieldIndex, 1);

        // this.editedItem = Object.assign({}, mutableObj);
        this.editedItem = mutableObj;
      } catch (error) {
        console.log(error);
      }
    },

    async addTemplateObject() {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template.push({
          name: "",
          fields: [],
        });

        // this.editedItem = Object.assign({}, mutableObj);
        this.editedItem = mutableObj;

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeTemplateObject(i) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template.splice(i, 1);

        // this.editedItem = Object.assign({}, mutableObj);
        this.editedItem = mutableObj;
      } catch (error) {
        console.log(error);
      }
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.expPanels = 1;
      this.valid = false;
    },

    createItem() {
      this.dialogNewOrExistingTemplate = false;

      this.dialog = true;
    },

    createItemFromTemplate() {
      this.editedItem = _.cloneDeep(this.selectedLessonTemplate[0]);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) =>
          item.label !== "Difficulty" &&
          item.label !== "Minutes" &&
          item.label !== "Type"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialogLessonTemplates = false;
      this.dialogLessonPlanner = true;
    },

    duplicateItem(item) {
      this.editedItem = _.cloneDeep(item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) =>
          item.label !== "Difficulty" &&
          item.label !== "Minutes" &&
          item.label !== "Type"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialogLessonPlanner = true;
    },

    editItem(item) {
      this.editedIndex = this.teacherSubjectTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) =>
          item.label !== "Difficulty" &&
          item.label !== "Minutes" &&
          item.label !== "Type"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      // this.dialog = true;

      // PLANNER
      this.dialogLessonTemplates = false;
      this.dialogLessonPlanner = true;
    },

    openLessonTemplatesDialog() {
      this.dialogNewOrExistingTemplate = false;
      this.dialogLessonTemplates = true;
    },

    printItem(itemId) {
      // window.open(`${process.env.BASE_URL}app/printtemplate/` + itemId, '_blank');
      this.$router.push({
        path: `${process.env.BASE_URL}app/print/lesson/` + itemId,
      });
    },

    deleteItem(item) {
      this.editedIndex = this.teacherSubjectTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await DataStore.delete(
        this.syncedTeacherSubjectTemplateArray[this.editedIndex]
      );
      this.closeDelete();
    },

    closeNewOrExistingTemplate() {
      this.dialogNewOrExistingTemplate = false;
    },

    closeLessonTemplates() {
      this.dialogLessonTemplates = false;
      this.$nextTick(() => {
        this.selectedLessonTemplate = [];
      });
    },

    closeDialogLessonPlanner() {
      // this.selectedGrade = null;
      // this.selectedSubject = null;
      this.activeStep = 1;
      // this.valid = false;
      this.dialogLessonPlanner = false;
      this.selectedLessonTemplate = [];

      this.$nextTick(() => {
        this.resetForm();
      });
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.resetForm();
      });
    },

    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.resetForm();
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update subjectTemplate
        await this.updateSubjectTemplate();
      } else {
        // Create new subjectTemplate
        await this.createSubjectTemplate();
      }
      this.close();
    },

    async submitLessonPlannerForm() {
      await this.save();

      this.closeDialogLessonPlanner();
    },

    async createSubjectTemplate() {
      try {
        // this.editedItem.totalPoints = 0;

        this.editedItem.template.forEach((templateObj) => {
          templateObj.name = templateObj.name.trim();

          templateObj.fields.forEach((fieldObj) => {
            // fieldObj.points = parseInt(fieldObj.points);
            // fieldObj.difficulty = parseInt(fieldObj.difficulty);
            // this.editedItem.totalPoints += fieldObj.points;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox" &&
              fieldObj.type !== "Multi Select"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          new TeacherSubjectTemplate({
            name: this.editedItem.name.trim(),
            headings: this.editedItem.headings,
            fontFamily: this.editedItem.fontFamily,
            complexity: this.editedItem.complexity,
            totalPoints:
              typeof this.editedItem.totalPoints === "string"
                ? parseInt(this.editedItem.totalPoints)
                : this.editedItem.totalPoints,
            minutes: parseInt(this.editedItem.minutes),
            template: this.editedItem.template,
            schoolID: this.editedItem.school.id,
            subjectID: this.editedItem.subject.id,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateSubjectTemplate() {
      try {
        // this.editedItem.totalPoints = 0;

        this.editedItem.template.forEach((templateObj) => {
          templateObj.name = templateObj.name.trim();

          templateObj.fields.forEach((fieldObj) => {
            // fieldObj.points = parseInt(fieldObj.points);
            // fieldObj.difficulty = parseInt(fieldObj.difficulty);
            // this.editedItem.totalPoints += fieldObj.points;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox" &&
              fieldObj.type !== "Multi Select"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          TeacherSubjectTemplate.copyOf(
            _.find(this.syncedTeacherSubjectTemplateArray, {
              id: this.editedItem.id,
            }),
            (updateModel) => {
              updateModel.name = this.editedItem.name.trim();
              updateModel.headings = this.editedItem.headings;
              updateModel.fontFamily = this.editedItem.fontFamily;
              updateModel.complexity = this.editedItem.complexity;
              updateModel.totalPoints =
                typeof this.editedItem.totalPoints === "string"
                  ? parseInt(this.editedItem.totalPoints)
                  : this.editedItem.totalPoints;
              updateModel.minutes = parseInt(this.editedItem.minutes);
              updateModel.template = this.editedItem.template;
              updateModel.subjectID = this.editedItem.subject.id;
              updateModel.schoolID = this.editedItem.school.id;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherClassSubject() {
      try {
        this.teacherClassSubjectSubscription = DataStore.observeQuery(
          TeacherClassSubject
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedTeacherClassSubjectArray !== items
            ) {
              // console.log("TeacherClassSubject synced", items);
              this.syncedTeacherClassSubjectArray = items;

              this.syncedTeacherClassSubjectIDArray = items.map(
                (ts) => ts.subjectID
              );

              // if (!this.subjectTemplateSubscription) {
              //   // Sync the Admin Lessons from the datastore
              //   this.syncSubjectTemplates(this.syncedTeacherClassSubjectIDArray);
              // }

              // if (!this.teacherSubjectTemplateSubscription) {
              //   // Sync the Lessons from the datastore
              //   this.syncTeacherSubjectTemplates(this.syncedTeacherClassSubjectIDArray);
              // }

              // if (!this.subjectSubscription) {
              //   // Sync the Subjects from the datastore
              //   this.syncSubjects(this.syncedTeacherClassSubjectIDArray);
              // }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherSubjectTemplates() {
      try {
        // const predicate = (teacherSubjectTemplate) =>
        //   teacherSubjectTemplate.or((teacherSubjectTemplate) =>
        //     this.syncedTeacherClassSubjectIDArray.reduce(
        //       (c, id) => c.subjectID("eq", id),
        //       teacherSubjectTemplate
        //     )
        //   );

        this.teacherSubjectTemplateSubscription = DataStore.observeQuery(
          TeacherSubjectTemplate
          // predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherSubjectTemplateArray !== items
            ) {
              this.syncedTeacherSubjectTemplateArray = items;

              this.teacherSubjectTemplates = _.cloneDeep(items);

              this.teacherSubjectTemplateSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.teacherSubjectTemplateSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(
          School,
          (s) =>
            // s.and((s) => [
            s.or((s) => [
              s.and((s) => [
                s.isGeneric("eq", true),
                s.provinces("contains", this.teacherProfileProvince),
              ]),
              s.id("eq", this.teacherProfileSchoolID), // Check for ID match with the query
            ])
          // ])
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSchoolArray !== items
            ) {
              this.syncedSchoolArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjects(syncedTeacherClassSubjectIDArray) {
      try {
        // const predicate = (subject) =>
        //   subject.or((subject) =>
        //     syncedTeacherClassSubjectIDArray.reduce(
        //       (c, id) => c.id("eq", id),
        //       subject
        //     )
        //   );

        this.subjectSubscription = DataStore.observeQuery(
          Subject
          // predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectArray !== items
            ) {
              this.syncedSubjectArray = items;

              //HIDE-subscription-17Jan2025-----------------------------------------------

              // const validGrades =
              //   this.syncedTeacherProfileModel?.payfastSubscriptionPackage
              //     ?.grades || [];

              // // Filter subjects where the grade is included in the validGrades array
              // const filteredSubjects = items.filter((subject) =>
              //   validGrades.includes(subject.grade)
              // );

              // // Map the filtered subjects to their ids
              // const subjectIds = filteredSubjects.map((subject) => subject.id);

              // this.syncedSubscribedSubjectIDArray = subjectIds;

              //HIDE-subscription-17Jan2025-END-------------------------------------------

              this.subjects = _.cloneDeep(
                _.sortBy(items, (item) => {
                  if (item.grade === "R") {
                    return -Infinity; // 'R' should appear first
                  } else if (item.grade === "1") {
                    return 0; // '1' should appear second
                  } else {
                    return parseInt(item.grade); // Sort the remaining numbers normally
                  }
                })
              );
            }
          },
          (error) => {
            console.log(error);
            this.teacherSubjectTemplateSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjectTemplates() {
      try {
        // const schools = await DataStore.query(School, (s) =>
        //   s.and((s) => [
        //     s.isGeneric("eq", true),
        //     s.provinces("contains", this.teacherProfileProvince),
        //   ])
        // );

        // const grandFathered = this.syncedTeacherProfileModel
        //   ?.payfastSubscriptionPackage?.grandFathered
        //   ? true
        //   : false;

        // console.log(grandFathered);

        const schoolIdsArray = this.syncedSchoolArray.map(
          (school) => school.id
        );

        const predicate = (subjectTemplate) =>
          subjectTemplate.and((subjectTemplate) => [
            // grandFathered
            //   ?
            subjectTemplate.or((subjectTemplate) =>
              this.syncedTeacherClassSubjectIDArray.reduce(
                (c, id) => c.subjectID("eq", id),
                subjectTemplate
              )
            ),
            // : this.syncedSubscribedSubjectIDArray?.length > 0
            // ? subjectTemplate.or((subjectTemplate) =>
            //     this.syncedSubscribedSubjectIDArray.reduce(
            //       (c, id) => c.subjectID("eq", id),
            //       subjectTemplate
            //     )
            //   )
            // : subjectTemplate.id("eq", null),

            subjectTemplate.or((subjectTemplate) => [
              subjectTemplate.schoolID("eq", this.teacherProfileSchoolID),

              subjectTemplate.or((subjectTemplate) =>
                schoolIdsArray.reduce(
                  (c, id) => c.schoolID("eq", id),
                  subjectTemplate
                )
              ),
            ]),
          ]);

        this.subjectTemplateSubscription = DataStore.observeQuery(
          SubjectTemplate,
          predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectTemplateArray !== items
            ) {
              const filteredItems = items.filter((item) =>
                // this.syncedSubscribedSubjectIDArray.includes(
                //   item.subjectID
                // ) &&
                this.syncedTeacherClassSubjectIDArray.includes(item.subjectID)
              );
              this.syncedSubjectTemplateArray = filteredItems;

              this.subjectTemplates = _.cloneDeep(filteredItems);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.subjectTemplateSubscription) {
      this.subjectTemplateSubscription.unsubscribe();
    }
    if (this.teacherClassSubjectSubscription) {
      this.teacherClassSubjectSubscription.unsubscribe();
    }
    if (this.teacherSubjectTemplateSubscription) {
      this.teacherSubjectTemplateSubscription.unsubscribe();
    }
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>
<style>
#expPanelContent > * {
  padding: 12px !important;
}

.panelTopBorder {
  border-radius: 4px !important;
}

.panelTopBorder::after {
  border-top: none !important;
}

.plannerPanelTopBorder > div:nth-child(2) {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active + div {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--next-active {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active {
  border-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active #expPanelContent {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
