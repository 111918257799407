<template>
  <div id="appprofile">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12" :md="teacherProfileID ? '6' : '12'">
          <TeacherProfileForm />
        </v-col>
        <!-- //HIDE-subscription-17Dec2024 <v-col cols="12" md="6" v-if="teacherProfileID">
          <TeacherSubscription />
        </v-col> -->
        <v-col cols="12" md="6" v-if="teacherProfileID">
          <TeacherScheduler />
        </v-col>
        <v-col cols="12" md="6" v-if="teacherProfileID">
          <TeacherClassSubjects />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TeacherProfileForm from "@/components/app-components/teacher/TeacherProfileForm.vue";
//HIDE-subscription-17Dec2024
// import TeacherSubscription from "@/components/app-components/teacher/TeacherSubscription.vue";
import TeacherScheduler from "@/components/app-components/teacher/TeacherScheduler.vue";
import TeacherClassSubjects from "@/components/app-components/teacher/TeacherClassSubjects.vue";
import { mapState } from "vuex";

export default {
  name: "AppProfileView",
  components: {
    TeacherProfileForm,
    //HIDE-subscription-17Dec2024
    // TeacherSubscription,
    TeacherScheduler,
    TeacherClassSubjects,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      teacherProfileID: (state) => state.teacherProfileID,
    }),
  },
};
</script>
