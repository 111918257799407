<template>
  <div id="teachercart" class="d-flex flex-column align-center">
    <v-dialog
      v-model="cartDialog"
      :max-width="screenWidth > 1400 ? '1000px' : '800px'"
      @click:outside="closeCartDialog()"
    >
      <v-card class="overflow-x-hidden overflow-y-auto pa-3" max-height="90vh">
        <h2
          class="display-2 font-weight-bold mb-3 text-uppercase text-center primary--text"
        >
          {{ $i18n.t("products.shoppingCart") }}
        </h2>

        <!-- Horizontal divider -->
        <v-responsive class="mx-auto mb-6" width="56" height="2">
          <v-divider></v-divider>
          <v-divider></v-divider>
        </v-responsive>

        <v-row v-if="!queriedProducts">
          <v-col cols="12" class="d-flex align-center justify-center pa-4">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <div v-else>
          <v-row v-if="products.length === 0">
            <v-col
              cols="12"
              class="d-flex flex-column align-center justify-center pa-4"
              style="gap: 12px"
            >
              {{ $i18n.t("otherText.shopCartIsEmpty") }}
              <v-btn color="primary" @click="browseStore">{{
                $i18n.t("movement.goBack")
              }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-else :class="screenWidth < 600 ? 'flex-column-reverse' : ''">
            <v-col cols="12" sm="6">
              <v-card
                class="overflow-x-hidden overflow-y-auto pa-3 d-flex flex-column"
                style="gap: 12px"
                :style="
                  screenWidth < 600 ? '' : 'max-height: calc(90vh - 124px)'
                "
              >
                <v-card
                  v-for="product in products"
                  :key="product.id"
                  elevation="2"
                  min-height="fit-content"
                  height="100%"
                  width="100%"
                  class="d-flex flex-row"
                >
                  <!-- <v-img
                  :src="
                    product.unAvailable
                      ? require('@/assets/logo-v2-square.svg')
                      : product.thumbnailImages[0]
                  "
                  height="64"
                  width="64"
                  max-width="64"
                  max-height="64"
                  contain
                  style="
                    border-radius: 4px;
                    border-style: solid;
                    border-width: thin;
                    border-color: rgba(0, 0, 0, 0.12);
                  "
                  :loading="
                    !product.unAvailable &&
                    !isImageLoaded(product.id, product.thumbnailImages)
                  "
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0 align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->

                  <div class="d-flex flex-row" style="width: 100%">
                    <!-- style="width: calc(100% - 64px)" -->
                    <div
                      class="d-flex flex-column pa-2"
                      style="gap: 4px; width: calc(100% - 40px)"
                    >
                      <v-card-title
                        style="
                          font-size: 1.25rem;
                          line-height: 1.4rem;
                          text-decoration: none !important;
                          word-break: break-word;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          display: block;
                        "
                        class="pa-0"
                        ><div
                          v-html="
                            product.unAvailable
                              ? `<del>${product.title}</del>`
                              : product.title
                          "
                        ></div
                      ></v-card-title>

                      <span
                        v-if="product.unAvailable"
                        class="text-caption error--text"
                        >Product no longer available</span
                      >
                      <span v-else class="text-caption"
                        ><div
                          v-html="
                            productPrice(
                              product.price,
                              product.discountPercentage
                            )
                          "
                        ></div
                      ></span>
                    </div>
                    <v-spacer> </v-spacer>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red"
                          fab
                          class="mt-auto"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="removeProductFromCart(product)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $i18n.t("actions.remove") +
                        " " +
                        $i18n.t("otherText.from") +
                        " " +
                        $i18n.t("headings.cart")
                      }}</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-card>

              <v-btn
                v-show="screenWidth < 600"
                color="secondary"
                class="black--text mt-3"
                @click="closeCartDialog"
                >{{ $i18n.t("actions.close") }}</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                {{
                  $i18n.t("headings.products") +
                  " (" +
                  $i18n.t("labels.downloadable") +
                  ")"
                }}: <v-spacer></v-spacer>

                <!-- R{{ productsPrice.toFixed(2) }} -->
                <div
                  v-if="nonPhysicalProductsPrice === productsPriceWithCoupon"
                >
                  R{{ nonPhysicalProductsPrice.toFixed(2) }}
                </div>
                <div
                  v-else
                  v-html="
                    `<del>R${nonPhysicalProductsPrice.toFixed(
                      2
                    )}</del> R${productsPriceWithCoupon.toFixed(2)}`
                  "
                ></div>
              </v-row>
              <v-row no-gutters v-show="validCoupon">
                {{ $i18n.t("labels.coupon") }}: <v-spacer></v-spacer>
                {{ couponText(validCoupon) }}
              </v-row>
              <v-row no-gutters>
                {{
                  $i18n.t("headings.products") +
                  " (" +
                  $i18n.t("headings.shipping") +
                  ")"
                }}: <v-spacer></v-spacer> R{{
                  physicalProductsPrice.toFixed(2)
                }}
              </v-row>
              <v-row no-gutters v-show="physicalProducts?.length > 0">
                {{ $i18n.t("headings.shippingFee") }}: <v-spacer></v-spacer> R
                {{ (160).toFixed(2) }}
              </v-row>
              <v-row no-gutters>
                {{
                  $i18n.t("labels.subTotal") +
                  " (" +
                  $i18n.t("labels.vatIncluded") +
                  ")"
                }}: <v-spacer></v-spacer> R
                {{ subTotal.toFixed(2) }}
              </v-row>
              <v-row no-gutters>
                {{
                  $i18n.t("labels.total") +
                  " (" +
                  $i18n.t("labels.vatExcluded") +
                  ")"
                }}: <v-spacer></v-spacer> R
                {{ exclVAT.toFixed(2) }}
              </v-row>
              <v-row no-gutters>
                {{ $i18n.t("labels.vat") }} ( 15% ): <v-spacer></v-spacer> R
                {{ totalVat.toFixed(2) }}
              </v-row>
              <v-row no-gutters>
                {{ $i18n.t("labels.total") }}: <v-spacer></v-spacer> R
                {{ subTotal.toFixed(2) }}
              </v-row>

              <div class="d-flex flex-row align-center py-3" style="gap: 12px">
                <v-text-field
                  v-model="couponCode"
                  :label="`${$i18n.t('labels.couponCode')}`"
                  :error-messages="couponVerifyErrors"
                  @keyup.enter="verifyCoupon"
                  @input="onCouponCodeInput"
                ></v-text-field>

                <v-icon
                  v-if="validCoupon && validCoupon.couponCode === couponCode"
                  color="primary"
                  >mdi-check</v-icon
                >

                <v-btn
                  color="primary"
                  v-else
                  :disabled="
                    verifyingCoupon ||
                    !couponCode ||
                    couponCode === '' ||
                    checkingOut ||
                    !syncedTeacherProfileModel
                  "
                  :loading="verifyingCoupon"
                  @click="verifyCoupon"
                  >{{ $i18n.t("actions.verify") }}</v-btn
                >
              </div>

              <v-btn
                color="primary"
                :disabled="
                  checkingOut || anyUnAvailableProducts || verifyingCoupon
                "
                :loading="checkingOut"
                @click="proceedToCheckout"
                block
                v-if="physicalProducts?.length > 0"
                >{{ $i18n.t("actions.proceed") }}</v-btn
              >
              <v-btn
                color="primary"
                :disabled="
                  checkingOut || anyUnAvailableProducts || verifyingCoupon
                "
                :loading="checkingOut"
                @click="checkoutFreeProducts"
                block
                v-else-if="subTotal === 0"
                >{{ $i18n.t("actions.checkoutForFree") }}</v-btn
              >
              <v-btn
                color="primary"
                :disabled="
                  checkingOut || anyUnAvailableProducts || verifyingCoupon
                "
                :loading="checkingOut"
                @click="openPaymentMethodDialog"
                block
                v-else
                >{{ $i18n.t("actions.checkout") }}</v-btn
              >

              <v-alert
                v-if="anyUnAvailableProducts"
                dense
                type="warning"
                class="mb-0 mt-3"
                max-width="100%"
              >
                <v-row align="center">
                  <v-col class="grow text-subtitle-1"
                    >Some products are unavailable please remove them before
                    continuing</v-col
                  >
                </v-row>
              </v-alert>

              <div v-show="screenWidth > 599" style="height: 48px"></div>
              <v-btn
                v-show="screenWidth > 599"
                :disabled="checkingOut"
                color="secondary"
                absolute
                class="black--text"
                style="right: 12px; bottom: 12px"
                @click="closeCartDialog"
                >{{ $i18n.t("actions.close") }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkoutDialog"
      max-width="800px"
      @click:outside="closeCheckoutDialog()"
    >
      <v-card
        class="overflow-x-hidden overflow-y-auto px-1 pb-1 pt-3"
        max-height="90vh"
      >
        <v-form
          ref="form"
          v-model="validCheckoutForm"
          @submit.prevent="openPaymentMethodDialog"
        >
          <h2
            class="display-2 font-weight-bold mb-3 text-uppercase text-center primary--text"
          >
            {{ $i18n.t("headings.shippingInfo") }}
          </h2>

          <!-- Horizontal divider -->
          <v-responsive class="mx-auto mb-6" width="56" height="2">
            <v-divider></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="shippingDetails.address1"
                  :label="`${$i18n.t('labels.address')} 1`"
                  :rules="formRules.address1"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="shippingDetails.address2"
                  :label="`${$i18n.t('labels.address')} 2`"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="shippingDetails.city"
                  :label="$i18n.t('labels.city')"
                  :rules="formRules.city"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="shippingDetails.province"
                  :items="provinces"
                  :label="`${$i18n.t('labels.province')}*`"
                  :rules="formRules.province"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="shippingDetails.postalCode"
                  :label="$i18n.t('labels.postalCode')"
                  :rules="formRules.postalCode"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="shippingDetails.country"
                  readonly
                  :label="$i18n.t('labels.country')"
                  :rules="formRules.country"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="shippingDetails.phoneNumber"
                  :label="
                    $i18n.t('labels.phone') + ' ' + $i18n.t('labels.number')
                  "
                  :rules="formRules.phoneNumber"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap" style="gap: 12px">
            <v-btn
              color="secondary"
              :disabled="checkingOut"
              class="black--text"
              @click="backToCart"
              >{{ $i18n.t("movement.back") }}</v-btn
            >
            <v-spacer></v-spacer>
            <!-- <v-btn @click="closeCheckoutDialog">Close</v-btn> -->

            <div v-show="shippingChanged">
              {{ $i18n.t("products.saveShipping") }}
              <v-btn
                color="green"
                class="ml-3 white--text"
                @click="saveNewShippingDetails"
                >{{ $i18n.t("actions.yes") }}</v-btn
              >
            </div>
            <v-btn
              color="primary"
              :disabled="checkingOut || !validCheckoutForm"
              :loading="checkingOut"
              @click="openPaymentMethodDialog"
              >{{ $i18n.t("actions.checkout") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="paymentMethodDialog"
      max-width="800px"
      @click:outside="backFromPaymentMethod()"
    >
      <v-card
        class="overflow-x-hidden overflow-y-auto px-1 pb-1 pt-3"
        max-height="90vh"
      >
        <v-form
          ref="form"
          v-model="validPaymentMethodForm"
          @submit.prevent="confirmPaymentMethod"
        >
          <h2
            class="display-2 font-weight-bold mb-3 text-uppercase text-center primary--text"
          >
            {{ $i18n.t("products.paymentMethod") }}
          </h2>

          <!-- Horizontal divider -->
          <v-responsive class="mx-auto mb-6" width="56" height="2">
            <v-divider></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="chosenPaymentMethod"
                  :items="paymentMethods"
                  item-text="title"
                  item-value="value"
                  :label="`${$i18n.t('products.paymentMethod')}*`"
                  :rules="formRules.paymentMethod"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" v-show="chosenPaymentMethod === 'EFT'">
                <v-card class="pa-3">
                  <span class="mb-0 text-subtitle-1"
                    ><strong>Bank Details:</strong></span
                  >
                  <p class="mb-0">Account holder: A Dempers</p>
                  <p class="mb-0">Account number: 1216434069</p>
                  <p class="mb-0">Account type: Current account</p>
                  <p class="mb-0">Bank name: Nedbank</p>
                  <p class="mb-0">Branch code: 198765</p>
                  <p class="mb-0">Reference: Your Name</p>
                  <p class="mb-0">
                    Proof of payment: admin@missanrisclassroom.com / 0713230930
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap" style="gap: 12px">
            <v-btn
              color="secondary"
              :disabled="checkingOut"
              class="black--text"
              @click="backFromPaymentMethod"
              >{{ $i18n.t("movement.back") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="checkingOut || !validPaymentMethodForm"
              :loading="checkingOut"
              @click="confirmPaymentMethod"
              >{{ $i18n.t("actions.confirm") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import router from "@/router";
import { mapState } from "vuex";
import {
  DataStore,
  //  SortDirection,
  Storage,
  API,
} from "aws-amplify";
import {
  Product,
  TeacherProfile,
  // ProductTag,
  TeacherProductsOrder,
} from "@/models";
import _ from "lodash";
import MD5 from "crypto-js/md5";
// import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherCart",
  // components: { Helper },
  data: () => ({
    initiallyLoaded: false,
    productSubscription: null,
    queriedProducts: false,
    syncedProducts: false,

    changedProductsArray: false,
    checkingOut: false,

    syncedProductArray: [],
    products: [],
    loadedProductThumbnails: [],
    pendingTeacherProductsOrder: null,

    shippingDetails: {
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "South Africa",
      phoneNumber: "",
    },
    defaultShippingDetails: {
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "South Africa",
      phoneNumber: "",
    },

    chosenPaymentMethod: null,

    couponCode: "",
    verifyingCoupon: false,
    couponVerifyErrors: [],
    validCoupon: null,

    paymentMethods: [
      { title: "EFT", value: "EFT" },
      { title: "Card Payment", value: "Payfast" },
    ],

    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],

    cartDialog: true,
    checkoutDialog: false,
    paymentMethodDialog: false,

    merchant_id: "17705781", //live
    // merchant_id: "10002308", //sandbox/dev/staging

    merchant_key: "e9jy62j7ixjos", //live
    // merchant_key: "ho6e8zvucaysh", //sandbox/dev/staging

    return_url: `${window.location.origin}/app/bought-products`,
    cancel_url: `${window.location.origin}/app/products`,

    notify_url:
      "https://yfoov0qf5j.execute-api.eu-west-2.amazonaws.com/production/payfast/boughtProducts", //live production
    // notify_url:
    //   "https://ztd24p80lg.execute-api.eu-west-2.amazonaws.com/staging/payfast/boughtProducts", //sandbox staging

    passphrase: "PSWwerkboek1e", //live
    // passphrase: "MissAnrisClassNetwork.763-489-77", //sandbox/dev/staging

    formRules: {
      paymentMethod: [(v) => !!v || "a Payment Method is required"],
      address1: [(v) => !!v || "Address is required"],
      city: [(v) => !!v || "City is required"],
      province: [(v) => !!v || "Province is required"],
      postalCode: [(v) => !!v || "Postal code is required"],
      country: [(v) => !!v || "Country is required"],
      phoneNumber: [
        (v) => !!v || "Phone number is required",
        (v) =>
          /^(?:\+27|0)\s?\d{2}\s?\d{3}\s?\d{4}$/.test(v) ||
          "Invalid South African phone number",
      ],
    },
    validCheckoutForm: false,
    validPaymentMethodForm: false,
  }),

  async mounted() {
    try {
      await this.syncProducts();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      teacherProfilePayfastSubscriptionResponse: (state) =>
        state.teacherProfilePayfastSubscriptionResponse,
      syncedTeacherProfileModel: (state) => state.syncedTeacherProfileModel,
      syncedTeacherProductsOrderModels: (state) =>
        state.syncedTeacherProductsOrderModels,
      // teacherProfileID: (state) => state.teacherProfileID,
      userEmail: (state) => state.userEmail,
    }),

    shippingChanged() {
      if (
        !this.validCheckoutForm ||
        !this.syncedTeacherProfileModel?.productsOrderShippingDetails
      ) {
        return false;
      } else {
        const unchanged =
          JSON.stringify(
            this.syncedTeacherProfileModel.productsOrderShippingDetails
          ) === JSON.stringify(this.shippingDetails);

        return unchanged ? false : true;
      }
    },

    m_payment_id() {
      return Date.now();
    },

    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },

    physicalProducts() {
      return this.products.filter((product) => product.physical === true);
    },

    physicalProductsPrice() {
      if (this.physicalProducts.length === 0) return 0;

      let price = 0;

      for (let product of this.physicalProducts) {
        price +=
          product.price && product.discountPercentage
            ? product.price - product.price * (product.discountPercentage / 100)
            : product.price;
      }

      const finalPrice = price < 0 ? 0 : price;

      return finalPrice;
    },

    nonPhysicalProductsPrice() {
      if (this.products.length === 0) return 0;

      let price = 0;

      for (let product of this.products) {
        if (product.physical) continue;

        price +=
          product.price && product.discountPercentage
            ? product.price - product.price * (product.discountPercentage / 100)
            : product.price;
      }

      const finalPrice = price < 0 ? 0 : price;

      return finalPrice;
    },

    productsPriceWithCoupon() {
      if (this.products.length === 0) return 0;

      let price = 0;

      for (let product of this.products) {
        if (product.physical) continue;

        price +=
          product.price && product.discountPercentage
            ? product.price - product.price * (product.discountPercentage / 100)
            : product.price;
      }

      if (this.validCoupon) {
        const percentageType = this.validCoupon.discount.type === "PERCENTAGE";

        price = percentageType
          ? price - price * (this.validCoupon.discount.value / 100)
          : price - this.validCoupon.discount.value;
      }

      const finalPrice = price < 0 ? 0 : price;

      return finalPrice;
    },

    subTotal() {
      const price =
        this.physicalProducts?.length > 0
          ? 160 + this.productsPriceWithCoupon + this.physicalProductsPrice
          : this.productsPriceWithCoupon;

      return price;
    },

    exclVAT() {
      return this.subTotal ? Number.parseFloat(this.subTotal / (1 + 0.15)) : 0;
    },

    totalVat() {
      return this.subTotal
        ? Number.parseFloat((this.subTotal / (1 + 0.15) - this.subTotal) * -1)
        : 0;
    },

    allThumbnailsLoaded() {
      return this.products.every(
        (product) =>
          product.unAvailable ||
          this.loadedProductThumbnails.includes(product.id)
      );
    },

    anyUnAvailableProducts() {
      return this.products.some((product) => product.unAvailable);
    },
  },

  watch: {
    syncedTeacherProductsOrderModels: {
      handler: function (val) {
        if (this.syncedProducts && !this.queriedProducts && val?.length > 0) {
          this.queryProducts(val);
        } else if (val?.length > 0) {
          const pendingTeacherProductsOrder = _.find(val, {
            orderStatus: "PENDING",
          });

          if (pendingTeacherProductsOrder) {
            this.pendingTeacherProductsOrder = pendingTeacherProductsOrder;
          }
        }
      },
      immediate: true,
      deep: true,
    },

    syncedTeacherProfileModel: {
      handler: function (val) {
        if (val?.productsOrderShippingDetails) {
          this.shippingDetails = _.cloneDeep({
            ...val.productsOrderShippingDetails,
            country: "South Africa",
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    onCouponCodeInput(value) {
      // Convert to uppercase and remove non-alphanumeric characters
      this.couponCode = value
        ? value
            .trim()
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, "")
        : "";
    },

    couponText(coupon) {
      if (coupon) {
        return coupon.discount.type === "PERCENTAGE"
          ? `- ${coupon.discount.value}%`
          : `- R${coupon.discount.value.toFixed(2)}`;
      } else {
        return "";
      }
    },

    async verifyCoupon() {
      if (this.couponCode === "") {
        this.couponVerifyErrors = ["Coupon code is required"];
        return;
      }

      this.verifyingCoupon = true;
      try {
        await API.get("couponapi", "/item/verifyCoupon", {
          queryStringParameters: {
            teacherProfileID: this.syncedTeacherProfileModel.id,
            couponCode: this.couponCode,
          },
        })
          .then(async (response) => {
            if (response?.validCoupon) {
              this.couponVerifyErrors = [];
              this.validCoupon = response.validCoupon;
              console.log(this.validCoupon);
            } else {
              this.couponVerifyErrors = [response.error];
              this.validCoupon = null;
            }

            this.verifyingCoupon = false;
          })
          .catch((error) => {
            console.log(error);
            this.couponVerifyErrors = [
              "An error occurred while verifying the coupon, please check your internet connection or try again later.",
            ];
            this.validCoupon = null;

            this.verifyingCoupon = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async checkoutFreeProducts() {
      await this.checkout("Free");
    },

    productPrice(price, discountPercentage) {
      return price && discountPercentage
        ? `<del>R${price.toFixed(2)}</del> R` +
            (price - price * (discountPercentage / 100)).toFixed(2)
        : price
        ? "R" + price.toFixed(2)
        : "R" + 0;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.shippingDetails = Object.assign({}, this.defaultShippingDetails);
      this.valid = false;
      this.validCheckoutForm = false;
      this.validPaymentMethodForm = false;
    },

    backFromPaymentMethod() {
      this.paymentMethodDialog = false;
      if (this.physicalProducts?.length > 0) {
        this.checkoutDialog = true;
      } else {
        this.cartDialog = true;
      }
    },

    openPaymentMethodDialog() {
      if (this.cartDialog) {
        this.cartDialog = false;
      } else if (this.checkoutDialog) {
        this.checkoutDialog = false;
      }
      this.paymentMethodDialog = true;
    },

    async confirmPaymentMethod() {
      await this.checkout(this.chosenPaymentMethod);
      this.paymentMethodDialog = false;
    },

    async saveNewShippingDetails() {
      await DataStore.save(
        TeacherProfile.copyOf(this.syncedTeacherProfileModel, (updateModel) => {
          updateModel.productsOrderShippingDetails = this.shippingDetails;
        })
      );
    },

    async checkout(paymentMethod) {
      if (!this.initiallyLoaded) return;

      this.checkingOut = true;

      try {
        const newOrderID = this.getFormattedTimestamp();

        await DataStore.save(
          TeacherProductsOrder.copyOf(
            this.pendingTeacherProductsOrder,
            (updateModel) => {
              updateModel.orderProducts =
                this.products.length > 0 ? [...new Set(this.products)] : null;
              updateModel.orderTotal = parseFloat(this.subTotal.toFixed(2));
              updateModel.orderVAT = parseFloat(this.totalVat.toFixed(2));
              updateModel.orderDateTime = new Date().toISOString();
              updateModel.orderID = newOrderID;
              updateModel.coupon = this.validCoupon ? this.validCoupon : null;
              if (this.physicalProducts?.length > 0) {
                updateModel.orderShippingDetails = this.shippingDetails;
              }
              if (typeof paymentMethod === "string") {
                updateModel.orderPaymentMethod = paymentMethod;
                if (paymentMethod === "EFT") {
                  updateModel.orderStatus = "PROCESSING";
                } else if (paymentMethod === "Free") {
                  updateModel.orderStatus = "PAID";
                }
              }
            }
          )
        );

        if (
          this.physicalProducts?.length > 0 &&
          !this.syncedTeacherProfileModel?.productsOrderShippingDetails
        ) {
          await DataStore.save(
            TeacherProfile.copyOf(
              this.syncedTeacherProfileModel,
              (updateModel) => {
                updateModel.productsOrderShippingDetails = this.shippingDetails;
              }
            )
          );
        }

        if (paymentMethod === "Payfast") {
          const amount = parseFloat(this.subTotal.toFixed(2));

          const data = {
            merchant_id: this.merchant_id.trim(),
            merchant_key: this.merchant_key.trim(),
            return_url: this.return_url.trim(),
            cancel_url: this.cancel_url.trim(),
            notify_url: this.notify_url.trim(),
            email_address: this.userEmail.trim(),
            m_payment_id: this.m_payment_id,
            amount: amount,
            item_name: "Miss Anris ClassNetwork TeacherProductsOrder Payment",
            custom_str1: this.pendingTeacherProductsOrder.id,
          };

          // Generate the signature using the same logic as the computed property
          const keyValue =
            "merchant_id=" +
            encodeURIComponent(data.merchant_id) +
            "&merchant_key=" +
            encodeURIComponent(data.merchant_key) +
            "&return_url=" +
            encodeURIComponent(data.return_url) +
            "&cancel_url=" +
            encodeURIComponent(data.cancel_url) +
            "&notify_url=" +
            encodeURIComponent(data.notify_url) +
            "&email_address=" +
            encodeURIComponent(data.email_address) +
            "&m_payment_id=" +
            data.m_payment_id +
            "&amount=" +
            data.amount +
            "&item_name=" +
            encodeURIComponent(data.item_name) +
            "&custom_str1=" +
            encodeURIComponent(data.custom_str1) +
            "&passphrase=" +
            encodeURIComponent(this.passphrase.trim());

          const paymentSignature = MD5(keyValue.replace(/%20/g, "+"));

          data["signature"] = paymentSignature.toString();

          const form = document.createElement("form");
          form.action = "https://www.payfast.co.za/eng/process"; //live
          // form.action = "https://sandbox.payfast.co.za/eng/process"; //sandbox/dev
          form.method = "post";
          form.style.display = "none";

          for (let key in data) {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
          }

          document.body.appendChild(form);
          form.submit();
        } else {
          this.checkingOut = false;

          this.resetForm();
          this.$emit("openCloseCartDialog");
          this.$router.push({ path: `/app/productorders/${newOrderID}` });
        }
      } catch (error) {
        console.log(error);
        this.checkingOut = false;
      }
    },

    getFormattedTimestamp() {
      const now = new Date();
      const year = now.getFullYear().toString();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hour = now.getHours().toString().padStart(2, "0");
      const minute = now.getMinutes().toString().padStart(2, "0");
      const second = now.getSeconds().toString().padStart(2, "0");
      return `${year}${month}${day}-${hour}${minute}${second}`;
    },

    async proceedToCheckout() {
      if (this.initiallyLoaded && this.changedProductsArray) {
        await DataStore.save(
          TeacherProductsOrder.copyOf(
            this.pendingTeacherProductsOrder,
            (updateModel) => {
              updateModel.orderProducts =
                this.products.length > 0 ? [...new Set(this.products)] : null;
            }
          )
        );
      }

      this.cartDialog = false;
      this.checkoutDialog = true;
    },

    backToCart() {
      this.checkoutDialog = false;
      this.cartDialog = true;
    },

    // closePaymentMethodDialog() {
    //       this.paymentMethodDialog = false;
    // },

    closeCheckoutDialog() {
      this.checkoutDialog = false;
      this.cartDialog = true;
      // this.$emit("openCloseCartDialog");
    },

    async closeCartDialog() {
      if (this.initiallyLoaded && this.changedProductsArray) {
        await DataStore.save(
          TeacherProductsOrder.copyOf(
            this.pendingTeacherProductsOrder,
            (updateModel) => {
              updateModel.orderProducts =
                this.products.length > 0 ? [...new Set(this.products)] : null;
            }
          )
        );
      }

      this.resetForm();
      this.$emit("openCloseCartDialog");
    },

    async browseStore() {
      if (this.initiallyLoaded && this.changedProductsArray) {
        await DataStore.save(
          TeacherProductsOrder.copyOf(
            this.pendingTeacherProductsOrder,
            (updateModel) => {
              updateModel.orderProducts =
                this.products.length > 0 ? [...new Set(this.products)] : null;
            }
          )
        );
      }

      this.resetForm();
      this.$emit("openCloseCartDialog");

      if (this.$router.app._route.name !== "AppProducts") {
        this.$router.push({ path: "/app/products" });
      }
    },

    isImageLoaded(productId, thumbnailImages) {
      if (
        !thumbnailImages ||
        !Array.isArray(thumbnailImages) ||
        thumbnailImages.length === 0
      )
        return false;

      if (this.loadedProductThumbnails.includes(productId)) return true;

      if (thumbnailImages.length === 0) return false;
      const img = new Image();
      img.onload = () => {
        this.loadedProductThumbnails.push(productId);
        return true;
      };
      img.onerror = () => {
        return false;
      };
      img.src = thumbnailImages[0];
      return false;
    },

    async removeProductFromCart(product) {
      const newProductsOrderArray = this.products.filter((p) => {
        return p.id !== product.id;
      });

      this.products = newProductsOrderArray;

      this.changedProductsArray = true;

      // if (newProductsOrderArray?.length > 0) {
      //   await DataStore.save(
      //     TeacherProductsOrder.copyOf(
      //       this.pendingTeacherProductsOrder,
      //       (updateModel) => {
      //         updateModel.orderProducts = newProductsOrderArray;
      //       }
      //     )
      //   );
      // } else {
      //   // await DataStore.delete(this.pendingTeacherProductsOrder).then(() => {
      //   //   this.$emit("openCloseCartDialog");
      //   // });
      //   await DataStore.save(
      //     TeacherProductsOrder.copyOf(
      //       this.pendingTeacherProductsOrder,
      //       (updateModel) => {
      //         updateModel.orderProducts = null;
      //       }
      //     )
      //   );
      // }
    },

    async syncProducts() {
      try {
        const currentDateTime = new Date().toISOString();

        this.productSubscription = DataStore.observeQuery(Product, (product) =>
          product.and((f) => [
            f.and((f) => [
              f.eCommerceProduct("eq", true),
              //HIDE-subscription-17Dec2024

              // this.syncedSubscribedCategoryIDArray.map((id) =>
              //   f.categoryId("ne", id)
              // ),
            ]),
            f.thumbnails("gt", []),
            f.publishDate("lt", currentDateTime),
          ])
        ).subscribe(
          async (snapshot) => {
            const { isSynced, items } = snapshot;
            if (isSynced) {
              this.syncedProducts = true;
              this.syncedProductArray = items;

              if (
                !this.queriedProducts &&
                this.syncedTeacherProductsOrderModels?.length > 0
              ) {
                this.queryProducts(this.syncedTeacherProductsOrderModels);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    queryProducts(syncedTeacherProductsOrderModels) {
      try {
        const pendingTeacherProductsOrder = _.find(
          syncedTeacherProductsOrderModels,
          {
            orderStatus: "PENDING",
          }
        );

        if (pendingTeacherProductsOrder) {
          // this.products = pendingTeacherProductsOrder.orderProducts;
          this.pendingTeacherProductsOrder = pendingTeacherProductsOrder;

          if (pendingTeacherProductsOrder.orderProducts?.length > 0) {
            const mutableProducts = _.cloneDeep(
              pendingTeacherProductsOrder.orderProducts
            );

            // const existingProducts = mutableProducts.filter((product) =>
            //   this.syncedProductArray.some((p) => p.id === product.id)
            // );

            // const unavailableProducts = mutableProducts.filter(
            //   (product) => !existingProducts.some((p) => p.id === product.id)
            // );

            mutableProducts.forEach((product) => {
              product.unAvailable = !this.syncedProductArray.some(
                (p) => p.id === product.id
              );
            });

            // console.log("existingProducts", existingProducts);
            // console.log("unavailableProducts", unavailableProducts);

            // Remove products not in mutableProducts
            this.products = this.products.filter((product) =>
              mutableProducts.some(
                (mutableProduct) =>
                  mutableProduct.id === product.id &&
                  mutableProduct.updatedAt === product.updatedAt
              )
            );

            // mutableProducts.forEach(async (newProduct, newProductIndex) => {
            //   if (
            //     newProduct.unAvailable ||
            //     newProduct.thumbnails?.length === 0
            //   ) {
            //     return;
            //   }

            //   // const existingProductIndex = this.products.findIndex(
            //   //   (existingProduct) =>
            //   //     existingProduct &&
            //   //     existingProduct.id === newProduct.id &&
            //   //     existingProduct.updatedAt === newProduct.updatedAt
            //   // );
            //   const existingProduct = this.products.find(
            //     (storedProduct) =>
            //       storedProduct &&
            //       storedProduct.id === newProduct.id &&
            //       storedProduct.updatedAt === newProduct.updatedAt
            //   );

            //   if (
            //     !existingProduct ||
            //     (existingProduct &&
            //       !existingProduct.thumbnailImages?.length > 0)
            //   ) {
            //     newProduct.thumbnailImages = [];
            //     for (
            //       let i = 0;
            //       i < Math.min(newProduct.thumbnails.length, 2);
            //       i++
            //     ) {
            //       const thumbnailKey = newProduct.thumbnails[i];
            //       const thumbnailImgSrc = await Storage.get(thumbnailKey, {
            //         level: "public",
            //       });

            //       // Save Thumbnail images
            //       const img = new Image();
            //       img.onload = function () {
            //         try {
            //           newProduct.thumbnailImages.push(thumbnailImgSrc);
            //         } catch (err) {
            //           // console.log(err);
            //         }
            //       };
            //       img.onerror = function (error) {
            //         console.error("Error loading thumbnail image:", error);
            //       };
            //       img.src = thumbnailImgSrc;
            //     }
            //   } else if (existingProduct.thumbnailImages?.length > 0) {
            //     mutableProducts.splice(newProductIndex, 1, {
            //       ...existingProduct,
            //       unAvailable: newProduct.unAvailable,
            //     });
            //   }
            // });

            this.products = mutableProducts;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (!this.initiallyLoaded) {
          this.initiallyLoaded = true;
        }

        this.queriedProducts = true;
      }
    },
  },

  beforeDestroy() {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  },
};
</script>
<style>
/* Hiding scrollbar for Chrome, Safari and Opera */
#selectedProductThumbnails::-webkit-scrollbar {
  display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
#selectedProductThumbnails {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.productsSearchInputCSS .v-input__slot {
  margin-bottom: 0px !important;
}

.productsSearchInputCSS .v-select__selections {
  min-height: 36px !important;
}

/* Table-specific styling */
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}
</style>
