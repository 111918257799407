<template>
  <v-app>
    <component :is="layoutComponent">
      <router-view />
    </component>
    <!-- <v-btn @click="createEmailTemplate">Template</v-btn> -->
    <CircularLoader v-if="appLoading" />
    <OfflineWarning v-if="offline" />
    <v-dialog persistent v-model="dialogSessionWarning" max-width="505px">
      <v-card>
        <v-card-title class="justify-center" style="word-break: break-word"
          >Juffrou Anri is open in another window. Click "Use Here" to use Miss
          Anri's ClassNetwork in this window.</v-card-title
        >
        <v-card-actions>
          <v-btn
            depressed
            color="secondary"
            class="black--text"
            @click="sessionWarningClose"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="sessionWarningUseHere"
            >Use Here</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import { mapState } from "vuex";
import CircularLoader from "@/components/global-components/CircularLoader.vue";
import OfflineWarning from "@/components/global-components/OfflineWarning.vue";
import NetworkStatus from "@/mixins/networkStatus.js";
import { DataStore, Auth, API } from "aws-amplify";
import { TeacherProfile, Announcement, TeacherProductsOrder } from "@/models";
import AWS from "aws-sdk";
import _ from "lodash";

export default Vue.extend({
  name: "App",
  components: { CircularLoader, OfflineWarning },
  mixins: [NetworkStatus],
  data: () => ({
    initiallySynced: false,
    initiallyLoaded: false,
    createdNewTeacherProfile: false,
    localUserGroups: [],
    fetchedUserGroups: false,
    teacherProfileSubscription: null,
    announcementSubscription: null,
    teacherProductsOrderSubscription: null,
    syncedTeacherProfileModel: null,
    dialogSessionWarning: false,
    newTeacherSessionToken: "",
    dataStoreConfig: "",
  }),

  async beforeMount() {
    try {
      this.getUserGroups();

      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });

      store.commit("storeUserSignedIn", user ? true : false);
      store.commit("storeUserEmail", user ? user.attributes.email : "");

      // console.log("user:", user);

      //  if (!user) {
      //     // console.log("dataStoreConfig = public");
      //     this.dataStoreConfig = "public";
      //     DataStore.configure({
      //       syncExpressions: [
      //         syncExpression(
      //           Product,
      //           () => {
      //             const currentDateTime = new Date().toISOString();

      //             return (product) =>
      //               product.and((product) => [
      //                 product.thumbnails("ne", []),
      //                 product.thumbnails("ne", null),
      //                 product.publishDate("lt", currentDateTime),
      //                 // product.featured("eq", true),
      //               ]);
      //           }
      //           // ,
      //           // {
      //           //   limit: 19999,
      //           // }
      //         ),
      //       ],
      //     });
      //     // DataStore.start();
      //   } else
      if (user && !this.teacherProfileSubscription) {
        this.syncTeacherProfile();
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  watch: {
    // userSignedIn: {
    //   handler: async function (val) {
    //     if (val && this.initiallyLoaded) {
    //       if (this.dataStoreConfig === "public") {
    //         console.log("dataStoreConfig = private");
    //         await DataStore.stop();
    //         this.dataStoreConfig = "private";
    //         DataStore.configure({
    //           syncExpressions: [
    //             syncExpression(Product, (product) => {
    //               return product;
    //             }),
    //           ],
    //         });
    //         await DataStore.start()
    //           .then(() => {
    //             this.syncTeacherProfile();
    //           })
    //           .catch((error) => {
    //             // console.log(error);
    //           });
    //       } else {
    //         this.syncTeacherProfile();
    //       }
    //     }
    //   },
    //   // immediate: true,
    // },

    syncedTeacherProfileModel: {
      handler: async function (val) {
        if (val && this.fetchedUserGroups) {
          // console.log("syncedTeacherProfileModel:", val);
          this.checkTeacherRole();
        }
      },
      deep: true,
    },

    fetchedUserGroups: {
      handler: async function (val) {
        if (val && this.syncedTeacherProfileModel) {
          // console.log("fetchedUserGroups:", val);
          this.checkTeacherRole();
        }
      },
      deep: true,
    },
  },

  computed: {
    layoutComponent() {
      return this.$route && this.$route.meta && this.$route.meta.layout;
    },

    ...mapState({
      offline: (state) => !state.isOnline,
      userGroups: (state) => state.userGroups,
      userSignedIn: (state) => state.userSignedIn,
      userEmail: (state) => state.userEmail,
      appLoading: (state) => state.appLoading,
      teacherProfileSchoolID: (state) => state.teacherProfileSchoolID,
      teacherProfileProvince: (state) => state.teacherProfileProvince,
      teacherProfileID: (state) => state.teacherProfileID,
      teacherProfilePayfastSubscriptionToken: (state) =>
        state.teacherProfilePayfastSubscriptionToken,
      teacherProfilePayfastSubscriptionResponse: (state) =>
        state.teacherProfilePayfastSubscriptionResponse,
      teacherProfileAppSyncVersion: (state) =>
        state.teacherProfileAppSyncVersion,
    }),
  },

  methods: {
    checkTeacherRole() {
      if (!this.localUserGroups.includes("teacher")) {
        console.log("User is not a teacher");
        const cognitoUsername = this.syncedTeacherProfileModel.owner
          .split("::")
          .pop();

        API.post("cognitolambdaapi", "/item/addUserToGroup", {
          body: {
            groupName: "teacher",
            userName: cognitoUsername,
          },
        })
          .then(() => {
            this.localUserGroups.push("teacher");
            // console.log("this.localUserGroups: ", this.localUserGroups);
            store.commit("storeUserGroups", this.localUserGroups);
          })
          .catch((error) => {
            // Log any errors
            console.log("error:", error);
          });
      } else {
        console.log("User is a teacher");
      }
    },

    createEmailTemplate() {
      AWS.config.update({
        region: "af-south-1",
        accessKeyId: "",
        secretAccessKey: "",
      });
      var ses = new AWS.SES();
      //       var params = {
      //         Template: {
      //           TemplateName: "ProductsOrderPaidEFTTemplate",
      //           SubjectPart:
      //             "Juffrou Anri Se Klaskamer: Your files are ready for download",
      //           TextPart: `Your Order is Ready for Download

      // Hello {{customer_name}},

      // Your order {{order_number}} on {{order_date}} has been paid. The following products are available for download:

      // {{product_links_text}}

      // {{#if shippingDetails}}
      // Shipping Details:
      // Address: {{shippingDetails.address1}}
      // {{#if shippingDetails.address2 }}
      // Address 2: {{shippingDetails.address2}}
      // {{/if}}
      // City: {{shippingDetails.city}}
      // Province: {{shippingDetails.province}}
      // Postal Code: {{shippingDetails.postalCode}}
      // Country: {{shippingDetails.country}}
      // Phone Number: {{shippingDetails.phoneNumber}}
      // {{/if}}

      // Thanks for shopping with us!

      // You can check the status of your orders at any time at {{base_url}}/app/productorders.

      // Have questions? We’re here for you. Contact us at {{contact_us_email}} or call {{contact_us_phone}}.

      // Sincerely,
      // Juffrou Anri Se Klaskamer`,
      //           HtmlPart: `<!DOCTYPE html>
      // <html lang="en">
      // <head>
      //     <meta charset="UTF-8">
      //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
      //     <title>Your Order is Ready for Download</title>
      //     <style>
      //         body {
      //             font-family: Arial, sans-serif;
      //             line-height: 1.6;
      //             margin: 0;
      //             padding: 20px;
      //             background-color: #f9f9f9;
      //         }
      //         .container {
      //             max-width: 600px;
      //             margin: auto;
      //             background: #fff;
      //             padding: 20px;
      //             border-radius: 8px;
      //             box-shadow: 0 2px 10px rgba(0,0,0,0.1);
      //         }
      //         h1 {
      //             color: #333;
      //         }
      //         h2 {
      //             color: #333;
      //         }
      //         p {
      //             color: #555;
      //         }
      //         a {
      //             color: #007BFF;
      //             text-decoration: none;
      //         }
      //         .footer {
      //             margin-top: 20px;
      //             font-size: 0.9em;
      //             color: #777;
      //         }
      //         .product-link {
      //             margin: 5px 0;
      //         }
      //         .shipping-info {
      //             margin-top: 20px;
      //             padding: 10px;
      //             border: 1px solid #ddd;
      //             border-radius: 5px;
      //             background-color: #f7f7f7;
      //         }
      //     </style>
      // </head>
      // <body>
      //     <div class="container">
      //         <h1>Files Ready for Download</h1>
      //         <p>Hello {{customer_name}},</p>
      //         <p>Your order <strong>{{order_number}}</strong> on <strong>{{order_date}}</strong> has been paid. The following products are available for download:</p>
      //         <div>
      //             {{product_links_html}}
      //         </div>

      // {{#if shippingDetails}}
      //         <div class="shipping-info">
      //             <h2>Shipping Details:</h2>
      //             <p>Address: {{shippingDetails.address1}}<br>
      // {{#if shippingDetails.address2}}
      //             Address 2: {{shippingDetails.address2}}<br>
      // {{/if}}
      //             City: {{shippingDetails.city}}<br>
      //         Province: {{shippingDetails.province}}<br>
      //         Postal Code: {{shippingDetails.postalCode}}<br>
      //         Country: {{shippingDetails.country}}<br>
      //         Phone Number: {{shippingDetails.phoneNumber}}</p>
      //         </div>
      // {{/if}}

      //         <p>Thanks for shopping with us!</p>
      //         <p>You can check the status of your orders at any time in <a href="{{base_url}}/app/productorders">Orders history</a>.</p>
      //         <p>Have questions? We’re here for you. Contact us at <a href="mailto:{{contact_us_email}}">{{contact_us_email}}</a> or call <a href="tel:{{contact_us_phone}}">{{contact_us_phone}}</a>.</p>
      //         <div class="footer">
      //             <p>Sincerely,<br><a href="{{base_url}}">Juffrou Anri Se Klaskamer</a></p>
      //             <p><a href="{{facebook_link}}">Facebook</a></p>
      //         </div>
      //     </div>
      // </body>
      // </html>`,
      //         },
      //       };
      var params = {
        Template: {
          TemplateName: "ProductsOrderProcessingEFTCouponTemplate",
          SubjectPart:
            "Order {{order_number}} Confirmation — Juffrou Anri Se Klaskamer",
          TextPart: `Order Confirmation

          Hello {{customer_name}},

          Thank you for your order with Juffrou Anri Se Klaskamer! We have received your order dated {{order_date}} and will process it shortly.
          Order {{order_number}}
          {{#if coupon}}
          Coupon Code: {{coupon.couponCode}}
          {{/if}}
          Payment status: {{payment_status}}

          Bank details are as follows:
          {{bank_details_text}}

          Reference: order number

          PLEASE REMEMBER THAT PAYMENTS TAKE LONGER OVER THE WEEKEND TO REFLECT. PLEASE SEND IMMEDIATE PAYMENT OR PAY WITH A CREDIT CARD TO AVOID DISAPPOINTMENT!

          All orders will be sent via email once payment reflects. Refer to the email "files ready for download" and click on the download links.

          View your order here: {{order_link}}

          {{#if shippingDetails}}
          Shipping Details:
          Address: {{shippingDetails.address1}}
          {{#if shippingDetails.address2 }}
          Address 2: {{shippingDetails.address2}}
          {{/if}}
          City: {{shippingDetails.city}}
          Province: {{shippingDetails.province}}
          Postal Code: {{shippingDetails.postalCode}}
          Country: {{shippingDetails.country}}
          Phone Number: {{shippingDetails.phoneNumber}}
          {{/if}}

          Thanks for shopping with us!

          You can check the status of your orders at any time at {{base_url}}/app/productorders.

          We welcome you to our store anytime. If you need assistance or have any questions, please email us at {{contact_us_email}} or call {{contact_us_phone}}. We are happy to help!

          Sincerely,
          Juffrou Anri Se Klaskamer`,
          HtmlPart: `<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Order Confirmation</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      line-height: 1.6;
                      margin: 0;
                      padding: 20px;
                      background-color: #f9f9f9;
                  }
                  .container {
                      max-width: 600px;
                      margin: auto;
                      background: #fff;
                      padding: 20px;
                      border-radius: 8px;
                      box-shadow: 0 2px 10px rgba(0,0,0,0.1);
                  }
                  h1 {
                      color: #333;
                  }
                  h2 {
                      color: #333;
                  }
                  p, strong {
                      color: #555;
                  }
                  a {
                      color: #007BFF;
                      text-decoration: none;
                  }
                  .footer {
                      margin-top: 20px;
                      font-size: 0.9em;
                      color: #777;
                  }
                  .bank-details {
                      margin: 10px 0;
                      font-size: 0.9em;
                  }
                  .shipping-info {
                      margin-top: 20px;
                      padding: 10px;
                      border: 1px solid #ddd;
                      border-radius: 5px;
                      background-color: #f7f7f7;
                  }
              </style>
          </head>
          <body>
              <div class="container">
                  <h1>Order Confirmation</h1>
                  <p>Hello {{customer_name}},</p>
                  <p>Thank you for your order with Juffrou Anri Se Klaskamer! We have received your order dated {{order_date}} and will process it shortly.</p>
                  <p><strong>Order {{order_number}}</strong></p>
                  {{#if coupon}}
                  <p><strong>Coupon Code:</strong> {{coupon.couponCode}}</p>
                  {{/if}}
                  <p><strong>Payment status:</strong> {{payment_status}}</p>

                  <div class="bank-details">
                      <strong>Bank details are as follows:</strong><br>
                      {{bank_details_html}}
                      Reference: order number
                  </div>

                  <p><strong>IMPORTANT:</strong> PLEASE REMEMBER THAT PAYMENTS TAKE LONGER OVER THE WEEKEND TO REFLECT. PLEASE SEND IMMEDIATE PAYMENT OR PAY WITH A CREDIT CARD TO AVOID DISAPPOINTMENT!</p>

                  <p>All orders will be sent via email once payment reflects. Refer to the email "files ready for download" and click on the download links.</p>

                  <h3><a href="{{order_link}}">View your order here</a></h3>

                  {{#if shippingDetails}}
                          <div class="shipping-info">
                              <h2>Shipping Details:</h2>
                              <p>Address: {{shippingDetails.address1}}<br>
                  {{#if shippingDetails.address2}}
                              Address 2: {{shippingDetails.address2}}<br>
                  {{/if}}
                          City: {{shippingDetails.city}}<br>
                          Province: {{shippingDetails.province}}<br>
                          Postal Code: {{shippingDetails.postalCode}}<br>
                          Country: {{shippingDetails.country}}<br>
                          Phone Number: {{shippingDetails.phoneNumber}}</p>
                          </div>
                  {{/if}}

                  <p>Thanks for shopping with us!</p>
                  <p>You can check the status of your orders at any time on our <a href="{{base_url}}/app/productorders">Orders History Page</a>.</p>

                  <p>We welcome you to our store anytime. If you need assistance or have any questions, please email us at <a href="mailto:{{contact_us_email}}">{{contact_us_email}}</a> or call <a href="tel:{{contact_us_phone}}">{{contact_us_phone}}</a>. We are happy to help!</p>

                  <div class="footer">
                      <p>Sincerely,<br><a href="{{base_url}}">Juffrou Anri Se Klaskamer</a></p>
                      <p><a href="{{facebook_link}}">Facebook</a></p>
                  </div>
              </div>
          </body>
          </html>`,
        },
      };

      //       For security reasons, this temporary password is valid for a limited time. If you did not request this password reset, please contact us immediately at {{contact_us_email}}.

      // If you need further assistance, feel free to reach out to us. We are here to help!

      /* <p><strong>Important:</strong> This temporary password is only valid for a limited time. If you did not request this reset, please contact us immediately at <a href="mailto:{{contact_us_email}}">{{contact_us_email}}</a>.</p>

    <p>If you need further assistance, feel free to reach out to us at <a href="mailto:{{contact_us_email}}">{{contact_us_email}}</a> or call <a href="tel:{{contact_us_phone}}">{{contact_us_phone}}</a>.</p> */

      //       var params = {
      //         Template: {
      //           TemplateName: "TemporaryPasswordEmailTemplate",
      //           SubjectPart: "Your Temporary Password for {{app_name}}",
      //           TextPart: `Hello {{user_name}},

      // We have received your request to retrieve your password for {{app_name}}.

      // Here is your temporary password: {{temporary_password}}

      // Alternatively you can use the temporary password URL to login: {{temporary_password_url}}

      // Please use this password to log in to your account. Once logged in, you will be able to change your password to something more secure.

      // We welcome you to our store anytime. If you need assistance or have any questions, please email us at {{contact_us_email}} or call {{contact_us_phone}}. We are happy to help!

      // Sincerely,
      // Juffrou Anri Se Klaskamer`,

      //           HtmlPart: `<!DOCTYPE html>
      // <html lang="en">
      // <head>
      //     <meta charset="UTF-8">
      //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
      //     <title>Your Temporary Password</title>
      //     <style>
      //         body {
      //             font-family: Arial, sans-serif;
      //             line-height: 1.6;
      //             margin: 0;
      //             padding: 20px;
      //             background-color: #f9f9f9;
      //         }
      //         .container {
      //             max-width: 600px;
      //             margin: auto;
      //             background: #fff;
      //             padding: 20px;
      //             border-radius: 8px;
      //             box-shadow: 0 2px 10px rgba(0,0,0,0.1);
      //         }
      //         h1 {
      //             color: #333;
      //         }
      //         p, strong {
      //             color: #555;
      //         }
      //         a {
      //             color: #007BFF;
      //             text-decoration: none;
      //         }
      //         .footer {
      //             margin-top: 20px;
      //             font-size: 0.9em;
      //             color: #777;
      //         }
      //     </style>
      // </head>
      // <body>
      //     <div class="container">
      //         <h1>Your Temporary Password</h1>
      //         <p>Hello {{user_name}},</p>

      //         <p>We have received your request to retrieve your password for {{app_name}}. Below is your temporary password:</p>

      //         <p><strong>Temporary Password:</strong> {{temporary_password}}</p>

      //         <p>Alternatively you can use the <strong>Temporary Password URL</strong> to login: <a href="{{temporary_password_url}}">{{temporary_password_url}}</a></p>

      //         <p>Please use this password to log in to your account. Once logged in, you will be able to change your password to something more secure.</p>

      //         <p>We welcome you to our store anytime. If you need assistance or have any questions, please email us at <a href="mailto:{{contact_us_email}}">{{contact_us_email}}</a> or call <a href="tel:{{contact_us_phone}}">{{contact_us_phone}}</a>. We are happy to help!</p>

      //         <div class="footer">
      //             <p>Sincerely,<br><a href="{{base_url}}">Juffrou Anri Se Klaskamer</a></p>
      //             <p><a href="{{facebook_link}}">Facebook</a></p>
      //         </div>
      //     </div>
      // </body>
      // </html>`,
      //         },
      //       };
      ses.createTemplate(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else console.log(data);
      });
    },

    deleteEmailTemplate() {
      AWS.config.update({
        region: "af-south-1",
        accessKeyId: "",
        secretAccessKey: "",
      });
      var ses = new AWS.SES();
      var params = {
        TemplateName: "ProductsOrderProcessingEFTCouponTemplate", // ProductsOrderPaidEFTTemplate
      };
      ses.deleteTemplate(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else console.log(data);
      });
    },

    async getUserGroups() {
      this.fetchedUserGroups = false;

      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });

      if (user) {
        await API.post("cognitolambdaapi", "/item/listUserGroups", {
          body: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
        })
          .then(async (cognitoListUserGroupsResponse) => {
            if (cognitoListUserGroupsResponse.list) {
              // console.log(
              //   "cognitoListUserGroupsResponse:",
              //   cognitoListUserGroupsResponse
              // );
              this.localUserGroups = JSON.parse(
                cognitoListUserGroupsResponse.list
              );
              store.commit("storeUserGroups", this.localUserGroups);
            }

            this.fetchedUserGroups = true;
          })
          .catch((error) => {
            // Log any errors
            console.log("error:", error);

            this.fetchedUserGroups = true;
          });
      }
    },

    sessionWarningClose() {
      store.dispatch("signOut");
      this.dialogSessionWarning = false;
    },

    async sessionWarningUseHere() {
      this.newTeacherSessionToken = Date.now().toString();
      // console.log("this.newTeacherSessionToken:", this.newTeacherSessionToken);
      localStorage.setItem("teacherSessionToken", this.newTeacherSessionToken);

      await DataStore.save(
        TeacherProfile.copyOf(this.syncedTeacherProfileModel, (updateModel) => {
          updateModel.sessionToken = this.newTeacherSessionToken;
        })
      );

      this.dialogSessionWarning = false;
    },

    async checkPayfastSubscription() {
      // console.log(
      //   "App.checkPayfastSubscription",
      //   this.teacherProfilePayfastSubscriptionToken
      // );
      if (!this.teacherProfilePayfastSubscriptionToken) {
        return;
      }

      let subscriptionStillValid = false;

      await API.get(
        "payfastsubscriptionapi",
        `/payfast/status/${this.teacherProfilePayfastSubscriptionToken}`
      )
        .then(async (payfastStatusResponse) => {
          // console.log("payfastStatusResponse:", payfastStatusResponse);
          if (
            this.teacherProfilePayfastSubscriptionResponse !==
            payfastStatusResponse.success.data.response
          ) {
            await DataStore.save(
              TeacherProfile.copyOf(
                this.syncedTeacherProfileModel,
                (updateModel) => {
                  updateModel.payfastSubscriptionResponse =
                    typeof payfastStatusResponse.success.data.response ===
                    "object"
                      ? payfastStatusResponse.success.data.response
                      : // {
                        //     ...payfastStatusResponse.success.data.response,
                        //     run_date: "2024-07-21T00:00:00+02:00",
                        //   }
                        null;
                }
              )
            );

            // store.commit(
            //   "storeTeacherProfilePayfastSubscriptionResponse",
            //   payfastStatusResponse.success.data.response
            // );
          }

          const user = await Auth.currentAuthenticatedUser().catch(() => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
          });

          if (user) {
            if (payfastStatusResponse.success.data.response.run_date) {
              const runDate = new Date(
                payfastStatusResponse.success.data.response.run_date
              );
              const currentDate = new Date();
              subscriptionStillValid = runDate > currentDate;
              // Compare the dates
              // console.log(
              //   "runDate:",
              //   runDate,
              //   "currentDate:",
              //   currentDate,
              //   "subscriptionStillValid:",
              //   subscriptionStillValid
              // );
              // console.log(
              //   "payfastStatusResponse.success.data:",
              //   payfastStatusResponse.success.data
              // );
            }

            if (
              payfastStatusResponse.success.data.response?.status_text ===
                "ACTIVE" ||
              subscriptionStillValid
            ) {
              if (!this.localUserGroups.includes("teacher")) {
                // User is not part of the 'teacher' group
                // console.log('User is not a teacher');

                await API.post("cognitolambdaapi", "/item/addUserToGroup", {
                  body: {
                    groupName: "teacher",
                    userPoolId: user.pool.userPoolId,
                    userName: user.username,
                  },
                })
                  .then(() => {
                    this.localUserGroups.push("teacher");
                    store.commit("storeUserGroups", this.localUserGroups);
                  })
                  .catch((error) => {
                    // Log any errors
                    console.log("error:", error);
                  });
              }
            }

            // REMOVE teacher role if subscription is not active

            // else if (this.localUserGroups.includes("teacher")) {
            //   await API.post("cognitolambdaapi", "/item/removeUserFromGroup", {
            //     body: {
            //       groupName: "teacher",
            //       userPoolId: user.pool.userPoolId,
            //       userName: user.username,
            //     },
            //   })
            //     .then(() => {
            //       let index = this.localUserGroups.indexOf("teacher");

            //       if (index !== -1) {
            //         this.localUserGroups.splice(index, 1);
            //       }

            //       // console.log("this.localUserGroups: ", this.localUserGroups);
            //       store.commit("storeUserGroups", this.localUserGroups);
            //     })
            //     .catch((error) => {
            //       // Log any errors
            //       console.log("error:", error);
            //     });
            // }
          }

          // console.log(payfastStatusResponse);
          store.commit("storeTeacherProfilePayfastSubscriptionResponse", {
            ...payfastStatusResponse.success.data.response,
            // run_date: "2024-07-21T00:00:00+02:00",
            subscriptionStillValid,
          });
        })
        .catch(async (error) => {
          store.commit(
            "storeTeacherProfilePayfastSubscriptionResponse",
            this.syncedTeacherProfileModel
              ? {
                  ...this.syncedTeacherProfileModel.payfastSubscriptionResponse,
                  subscriptionStillValid,
                }
              : null
          );

          console.error(error.message);
        });
    },

    async syncTeacherProfile() {
      try {
        // console.log("syncTeacherProfile");
        this.teacherProfileSubscription = DataStore.observeQuery(
          TeacherProfile
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              JSON.stringify(items[0]) !==
                JSON.stringify(this.syncedTeacherProfileModel)
            ) {
              const newSyncedTPM = items[0];
              this.syncedTeacherProfileModel = newSyncedTPM;

              // console.log("newSyncedTPM:", newSyncedTPM);

              //HIDE-subscription-17Dec2024
              // if (!this.localUserGroups.includes("teacher")) {
              //   console.log("User is not a teacher");
              //   const cognitoUsername = newSyncedTPM.owner.split("::").pop();

              //   API.post("cognitolambdaapi", "/item/addUserToGroup", {
              //     body: {
              //       groupName: "teacher",
              //       userName: cognitoUsername,
              //     },
              //   })
              //     .then(() => {
              //       this.localUserGroups.push("teacher");
              //       console.log("this.localUserGroups: ", this.localUserGroups);
              //       store.commit("storeUserGroups", this.localUserGroups);
              //     })
              //     .catch((error) => {
              //       // Log any errors
              //       console.log("error:", error);
              //     });
              // } else {
              //   console.log("User is a teacher");
              // }

              store.commit("storeTeacherProfileModel", newSyncedTPM);

              if (newSyncedTPM.translateI18n > "") {
                const newLang = newSyncedTPM.translateI18n;
                // newSyncedTPM.language === "Afrikaans" ? "af" : "en";
                this.$vuetify.lang.current = newLang;
                this.$i18n.locale = newLang;
              }

              if (
                !this.initiallySynced ||
                this.teacherProfilePayfastSubscriptionToken !==
                  newSyncedTPM.payfastSubscriptionToken
              ) {
                store.commit(
                  "storeTeacherProfilePayfastSubscriptionToken",
                  newSyncedTPM.payfastSubscriptionToken
                );
                this.checkPayfastSubscription();

                this.initiallySynced = true;
              }

              if (this.teacherProfileID !== newSyncedTPM.id) {
                this.syncTeacherProductsOrders(newSyncedTPM.id);
                store.commit("storeTeacherProfileID", items[0].id);
              }

              if (typeof newSyncedTPM._version === "string") {
                if (
                  this.teacherProfileAppSyncVersion !== newSyncedTPM._version
                ) {
                  store.commit(
                    "storeTeacherProfileAppSyncVersion",
                    newSyncedTPM._version
                  );
                }
              } else if (newSyncedTPM._version) {
                if (
                  this.teacherProfileAppSyncVersion !==
                  newSyncedTPM._version.toString()
                ) {
                  store.commit(
                    "storeTeacherProfileAppSyncVersion",
                    newSyncedTPM._version.toString()
                  );
                }
              }

              if (this.teacherProfileProvince !== newSyncedTPM.province) {
                store.commit(
                  "storeTeacherProfileProvince",
                  newSyncedTPM.province
                );
              }

              if (
                this.teacherProfileSchoolID !==
                newSyncedTPM.teacherProfileSchoolId
              ) {
                store.commit(
                  "storeTeacherProfileSchoolID",
                  newSyncedTPM.teacherProfileSchoolId
                );
              }

              const currentSessionTokenGet = JSON.parse(
                localStorage.getItem("teacherSessionToken")
              );
              const currentSessionToken = currentSessionTokenGet
                ? currentSessionTokenGet.toString()
                : "";

              if (typeof newSyncedTPM.sessionToken === "string") {
                if (currentSessionToken === "") {
                  this.newTeacherSessionToken = Date.now().toString();

                  localStorage.setItem(
                    "teacherSessionToken",
                    this.newTeacherSessionToken
                  );

                  await DataStore.save(
                    TeacherProfile.copyOf(newSyncedTPM, (updateModel) => {
                      updateModel.sessionToken = this.newTeacherSessionToken;
                    })
                  );
                } else if (newSyncedTPM.sessionToken !== currentSessionToken) {
                  this.dialogSessionWarning = true;
                }
              } else if (this.newTeacherSessionToken === "") {
                this.newTeacherSessionToken = Date.now().toString();

                localStorage.setItem(
                  "teacherSessionToken",
                  this.newTeacherSessionToken
                );

                await DataStore.save(
                  TeacherProfile.copyOf(newSyncedTPM, (updateModel) => {
                    updateModel.sessionToken = this.newTeacherSessionToken;
                  })
                );
              }

              if (
                newSyncedTPM.payfastSubscriptionPackage
                  ?.proRataPaymentSuccessful === true &&
                newSyncedTPM.sessionToken === currentSessionToken
              ) {
                const newPackage = _.cloneDeep(
                  newSyncedTPM.payfastSubscriptionPackage
                );
                delete newPackage.proRataPaymentSuccessful;

                const recurring_amount = (
                  (newPackage.grades.length > 1
                    ? newPackage.perGrade * (newPackage.grades.length - 1) +
                      newPackage.amount
                    : newPackage.amount) * 100
                ).toFixed(0);

                const bodyToUpdate = {
                  teacherProfileID: newSyncedTPM.id,
                  package: newPackage,
                  payfastSubscriptionToken:
                    newSyncedTPM.payfastSubscriptionToken,
                  recurring_amount: recurring_amount,
                };

                await this.updatePayfastSubscription(bodyToUpdate);
              }

              this.syncAnnouncements(newSyncedTPM.id);
              // if (!this.teacherProductsOrderSubscription) {
              //   this.syncTeacherProductsOrders(newSyncedTPM.id);
              // }

              store.commit("storeSyncedTeacherProfile", true);
            } else if (isSynced && items?.length === 0 && this.userSignedIn) {
              if (!this.createdNewTeacherProfile) {
                this.createdNewTeacherProfile = true;
                await DataStore.save(
                  new TeacherProfile({
                    title: "",
                    name: "",
                    surname: "",
                    language: "",
                    province: "",
                    daysPerWeek: 0,
                    status: "Active",
                    TeacherSchedules: [],
                    TeacherClassSubjects: [],
                    type: "TeacherProfile",
                  })
                );
              }
              store.commit("storeSyncedTeacherProfile", true);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherProductsOrders(teacherProfileIDProp) {
      try {
        this.teacherProductsOrderSubscription = DataStore.observeQuery(
          TeacherProductsOrder,
          (tpo) => tpo.teacherprofileID("eq", teacherProfileIDProp)
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items) {
              // if (items.length > 0) {
              // console.log("storeTeacherProductsOrderModels:", items);

              const pendingTeacherProductsOrder = items.find(
                (product) => product.orderStatus === "PENDING"
              );

              const pendingOrderProducts =
                pendingTeacherProductsOrder?.orderProducts;

              if (pendingOrderProducts?.length > 0) {
                const uniqueOrderProducts = [
                  ...new Map(
                    pendingOrderProducts.map((product) => [product.id, product])
                  ).values(),
                ];

                await DataStore.save(
                  TeacherProductsOrder.copyOf(
                    pendingTeacherProductsOrder,
                    (updateModel) => {
                      updateModel.orderProducts = uniqueOrderProducts;
                    }
                  )
                );
              }

              store.commit("storeTeacherProductsOrderModels", items);
              // } else {
              //   store.commit("storeTeacherProductsOrderModels", null);
              // }
              store.commit("storeSyncedTeacherProductsOrders", true);
            } else if (isSynced && items?.length === 0 && this.userSignedIn) {
              store.commit("storeSyncedTeacherProductsOrders", true);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncAnnouncements(teacherProfileID) {
      try {
        this.announcementSubscription = DataStore.observeQuery(
          Announcement,
          (a) =>
            a.and((a) => [
              a.or((a) => [
                a.sendTo("contains", teacherProfileID),
                a.sendTo("contains", "ALL"),
              ]),
              a.readBy("notContains", teacherProfileID),
            ])
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items) {
              if (items.length > 0) {
                store.commit("storeNotReadAnnouncements", items);
              } else {
                store.commit("storeNotReadAnnouncements", null);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updatePayfastSubscription(bodyToUpdate) {
      try {
        await API.post("payfastsubscriptionapi", "/payfast/update", {
          body: bodyToUpdate,
        })
          .catch((error) => {
            // Log any errors
            console.log("error:", error);

            // DataStore.save(
            //   TeacherProfile.copyOf(
            //     this.syncedTeacherProfileModel,
            //     (updateModel) => {
            //       updateModel.payfastSubscriptionPackage =
            //         typeof this.syncedTeacherProfileModel
            //           .payfastSubscriptionPackage === "object"
            //           ? {
            //               ...this.syncedTeacherProfileModel
            //                 .payfastSubscriptionPackage,
            //               pendingPackage: bodyToUpdate.package,
            //             }
            //           : { pendingPackage: bodyToUpdate.package };
            //     }
            //   )
            // );
          })
          .then(async (response) => {
            // console.log("response:", response);
            // console.log(
            //   "response.success:",
            //   response.success ? "true" : "false"
            // );

            if (
              response?.success
              //  && (!amountToPay || amountToPay <= 0)
            ) {
              DataStore.save(
                TeacherProfile.copyOf(
                  this.syncedTeacherProfileModel,
                  (updateModel) => {
                    updateModel.payfastSubscriptionPackage =
                      bodyToUpdate.package;
                  }
                )
              );
            }
            //  else {
            //   DataStore.save(
            //     TeacherProfile.copyOf(
            //       this.syncedTeacherProfileModel,
            //       (updateModel) => {
            //         updateModel.payfastSubscriptionPackage =
            //           typeof this.syncedTeacherProfileModel
            //             .payfastSubscriptionPackage === "object"
            //             ? {
            //                 ...this.syncedTeacherProfileModel
            //                   .payfastSubscriptionPackage,
            //                 pendingPackage: bodyToUpdate.package,
            //               }
            //             : { pendingPackage: bodyToUpdate.package };
            //       }
            //     )
            //   );
            // }

            this.checkPayfastSubscription();
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
  },
});
</script>
