<template>
  <div id="adminlanding" class="fill-height">
    <section class="fill-height">
      <v-container class="text-center fill-height pa-0" fluid>
        <div class="videoWrapper">
          <iframe
            :src="iframeSrc"
            scrolling="no"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "AdminReportingView",

  computed: {
    iframeSrc() {
      // console.log("Current route path:", this.$route.path);
      // Use this.$route.path to match the full path
      switch (this.$route.path) {
        case "/admin/reporting/revenue":
          return "https://redash.missanrisclassnetwork.co.za/public/dashboards/xEKlKF46iI72hnhOcKnAYK9T3gUlvFsbWjYCVJXp?org_slug=default";

        case "/admin/reporting/teacherprofiles":
          return "https://redash.missanrisclassnetwork.co.za/public/dashboards/68EHHp7mivqAQ1emZELWM9PxAQPHJwavNv5Rt2Ol?org_slug=default";

        case "/admin/reporting/subscriptions":
          return "https://redash.missanrisclassnetwork.co.za/public/dashboards/pxGXPgG0IH8oYsSgvniCNr8ErQeSpZlsKpoqzwa5?org_slug=default";

        default:
          return "https://redash.missanrisclassnetwork.co.za/public/dashboards/xEKlKF46iI72hnhOcKnAYK9T3gUlvFsbWjYCVJXp?org_slug=default";
      }
    },
  },
};
</script>

<style scoped>
#adminlanding,
section,
.v-container {
  height: 100vh;
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
