const en = {
  headings: {
    home: "Home",
    about: "About",
    contactUs: "Contact us",
    login: "Log in",
    register: "Register",
    signIn: "Sign in",
    signOut: "Sign out",
    profile: "Profile",
    howItWorks: "How it works",
    pricing: "Pricing",
    prices: "Prices",
    meetTheTeam: "Meet the team",
    subscription: "Subscription",
    package: "Package",
    subscribe: "Subscribe",
    subscribed: "Subscribed",
    schedule: "Schedule",
    classes: "Classes",
    class: "Class",
    teach: "Teach",
    recheck: "Recheck",
    validUntil: "Valid Until",
    grades: "Grades",
    grade: "Grade",
    downloadQuota: "Download Quota",
    day: "Day",
    subject: "Subject",
    resource: "Resource",
    library: "Library",
    resourceLibrary: "Resource Library",
    calendar: "Calendar",
    classroom: "Classroom",
    lessons: "Lessons",
    lesson: "Lesson",
    assessments: "Assessments",
    assessment: "Assessment",
    support: "Support",
    print: "Print",
    category: "Category",
    categories: "Categories",
    featured: "Featured",
    product: "Product",
    products: "Products",
    order: "Order",
    orders: "Orders",
    content: "Content",
    planner: "Planner",
    headings: "Headings",
    defaults: "Defaults",
    template: "Template",
    preview: "Preview",
    memorandum: "Memorandum",
    questions: "Questions",
    question: "Question",
    section: "Section",
    body: "Body",
    logo: "Logo",
    activity: "Activity",
    cart: "Cart",
    shopping: "Shopping",
    store: "Store",
    bought: "Purchased",
    shipping: "Shipping",
    shippingInfo: "Shipping Information",
    shippingFee: "Shipping fee",
    videos: "Videos",
  },
  labels: {
    title: "Title",
    name: "Name",
    surname: "Surname",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm password",
    language: "Language",
    province: "Province",
    school: "School",
    schoolDaysPerWeek: "School days per week",
    periods: "Periods",
    periodDuration: "Period Duration",
    breaks: "Breaks",
    enterNumPressEnter: "Enter a number and press enter",
    startTime: "Start Time",
    endTime: "End Time",
    actions: "Actions",
    subject: "Subject",
    message: "Message",
    search: "Search",
    tags: "Tags",
    more: "More",
    details: "Details",
    files: "Files",
    urls: "URL's",
    complexity: "Complexity",
    time: "Time",
    minutes: "Minutes",
    instructions: "Instructions",
    taxonomy: "Taxonomy",
    number: "Number",
    shortNumber: "No.",
    marks: "Marks",
    fields: "Fields",
    field: "Field",
    level: "Level",
    size: "Size",
    label: "Label",
    type: "Type",
    repeat: "Repeat",
    total: "Total",
    subTotal: "Subtotal",
    vat: "VAT",
    vatIncluded: "incl. VAT",
    vatExcluded: "excl. VAT",
    address: "Address",
    city: "City",
    country: "Country",
    postalCode: "Postal Code",
    phone: "Phone",
    status: "Status",
    free: "Free",
    couponCode: "Coupon Code",
    coupon: "Coupon",
    downloadable: "Downloadable",
  },
  404: {
    title: "SUBSCRIBERS ONLY!",
    message:
      "Join today and gain exclusive access to these resources – packed with motivation, educational tips, and mental health content designed just for teachers and parents. Don’t miss out!",
    button: "Subscribe Now",
  },
  lambda: {
    existingUserFound:
      "Existing profile found, please login to continue to <strong><a href='/app/profile'>your profile</a></strong> and use your existing username: ",
    profileMatched:
      "Profile matched, further instructions was emailed. please check your email.",
    errorNoProfile:
      "No profile matched the given email, please try another email or create a new profile <strong><a href='/app/profile'>here</a></strong>",
  },
  products: {
    noShippingDetailsFound: "No shipping details found",
    paymentMethod: "Payment Method",
    saveShipping: "Save new shipping as default?",
    shoppingCart: "Shopping Cart",
    addToCart: "Add to Cart",
    addToMyLibrary: "Add to My Library",
    subToGradeToDownload: "Subscribe to Grade to download",
    subToDownload: "Subscribe to Download",
    currentMonthLimit: "Limit Reached For This Grade For ",
    noContent: "This Product has no content.",
    buyNow: "Buy Now Once-off",
  },
  calendar: {
    repeatFor: "Repeat for",
    weeksMax: "Week(s) (Max 12)",
  },
  lessons: {
    createFrom: "Create a new Lesson from",
    pickLesson: "Pick an existing lesson template",
  },
  templates: {
    restricted:
      "The templates below are restricted to the Grades that you are subscribed to and the Subjects you have added to your Classes.",
    restrictedGrandFathered:
      "The templates below are restricted to the Subjects you have added to your Classes.",
  },
  assessments: {
    createFrom: "Create a new Assessment from",
    pickAssessment: "Pick an existing assessment template",
    newSection: "New Section?",
    notASection: "Not a Section",
  },
  actions: {
    save: "Save",
    saved: "Saved",
    submit: "Submit",
    cancel: "Cancel",
    close: "Close",
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    update: "Update",
    view: "View",
    retry: "Retry",
    ok: "OK",
    yes: "Yes",
    show: "Show",
    create: "Create",
    remove: "Remove",
    checkout: "Checkout",
    checkoutForFree: "Checkout for Free",
    proceed: "Proceed",
    confirm: "Confirm",
    verify: "Verify",
  },
  status: {
    cancelled: "Cancelled",
    active: "Active",
    activateMySub: "Activate my subscription",
    cancelMySub: "Cancel my subscription",
    noActiveSub: "No active subscription",
    processingSub: "We are processing your subscription",
    failedPayment: "Failed payment",
  },
  payfastPackages: {
    perMonth: "per Month",
    every3Months: "every 3 Months",
    every6Months: "every 6 Months",
    perYear: "per Year",
    perAdditionalGrade: "per Additional Grade",
    downloadLimit: "Download limit",
    perMonthPerGrade: "per Month per Grade",
    downloadsUnlimited: "Downloads Unlimited",
    titleMonthly: "Monthly",
    title3Monthly: "3 Monthly",
    title6Monthly: "6 Monthly",
    titleYearly: "Yearly",
    min1GradeRequired: "Minimum 1 grade is required",
    newSubSummary: "New Subscription Summary",
    currentSubSummary: "Current Subscription Summary",
    immediatePayment: "Immediate Payment",
    nextPayment: "Next Payment",
    amount: "Amount",
    updatePackage: "Update Package",
  },
  days: {
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  otherText: {
    createdByMe: "Created by me",
    thankYou: "Thank You",
    frequency: "Frequency",
    new: "New",
    mine: "Mine",
    my: "My",
    change: "Change",
    deleteScheduleDay: "Are you sure you want to delete this day?",
    deleteSubject: "Are you sure you want to delete this subject?",
    deleteTemplate: "Are you sure you want to delete this template?",
    deleteAssessment: "Are you sure you want to delete this assessment?",
    deleteActivity: "Are you sure you want to delete this activity?",
    your: "Your",
    family: "Family",
    in: "in",
    or: "or",
    to: "to",
    blank: "Blank",
    cognitive: "Cognitive",
    from: "from",
    browse: "Browse",
    shopCartIsEmpty: "Your shopping cart is empty",
    id: "ID",
  },
  movement: {
    back: "Back",
    goBack: "Go back",
    forward: "Forward",
    next: "Next",
    previous: "Previous",
    up: "Up",
    down: "Down",
    left: "Left",
    right: "Right",
  },
  typography: {
    font: "Font",
  },
  helperHints: {
    contactsupport: {
      title: "CONTACT SUPPORT:",
      body: "<p>Send support a message after filling in the fields and selecting the relevant category, our support team will contact you shortly.</p>",
    },
    auth: {
      title: "PASSWORD:",
      body: "<p>Password requirements:</p><ul><li>Minimum length 8 character(s)</li><li>Contains at least 1 number</li><li>Contains at least 1 special character</li><li>Contains at least 1 uppercase letter</li><li>Contains at least 1 lowercase letter</li></ul>",
    },
    subscription: {
      title: "SUBSCRIPTION:",
      body: "<p>View, renew, or upgrade your subscription package. Choose what fits your needs best!</p>",
    },
    profile: {
      title: "PROFILE:",
      body: "<p>Update your personal information here, ensuring it's accurate and up-to-date for a better user experience.</p>",
    },
    schedule: {
      title: "SCHEDULE:",
      body: `<p>Streamline your upcoming week! Access and tailor your school days to better structure your academic journey. Days added here will automatically reflect on your calendar. Note: Failing to input all school days will limit your calendar editing capabilities for those dates.</p>`,
    },
    scheduleedit: {
      title: "Adjust the details of your existing schedule.",
      body: "<p></p>",
    },
    classes: {
      title: "CLASSES:",
      body: "<p>In this section, you can select and add classes directly from a comprehensive list spanning from Grade R to Grade 7 based on the South African school curriculum. Easily pick the appropriate grades and subjects and name your classes.</p>",
    },
    classesedit: {
      title: "Adjust the details of your existing classes.",
      body: "<p></p>",
    },
    calendar: {
      title: "CALENDAR:",
      body: "<p>Stay on track with your educational journey! Here, you can view all your scheduled school days and add activities. Ensure every critical activity is added to keep your preparations timely and effective. Remember, any scheduled days will be pulled from your input in the 'Schedule' section. Stay organized and never miss an important date again!</p>",
    },
    calendaractivity: {
      title: "Here, you can edit all your existing activities.",
      body: "<p></p>",
    },
    lessons: {
      title: "LESSONS:",
      body: "<p>Explore our curated lesson plan templates tailored to various subjects and grades. Once you've found the perfect fit, easily schedule it on your calendar to keep your teaching organized and on track. Ensure each day has the right content for your learners.</p>",
    },
    lessonedit: {
      title: "CREATE / EDIT LESSON:",
      body: "<p><h3>CREATE</h3> Dive into curriculum planning with ease. Start from scratch. This section lets you design and tailor lessons to fit your teaching style and students' needs. Set the stage for engaging and practical learning experiences.</p>",
    },
    assessments: {
      title: "ASSESSMENTS:",
      body: "<p>Craft personalized assessments or select from our extensive library of templates. Feel free to modify any template to suit your learners’ needs, ensuring a comprehensive assessment of their progress.</p>",
    },
    assessmentedit: {
      title: "EDIT ASSESSMENT:",
      body: "<p>Adjust the details of your existing assessments. Here, you can modify content to align with specific learning outcomes based on the CAPS curriculum. Ensure your evaluations remain relevant and practical.</p>",
    },
    assessmentcreate: {
      title: "CREATE ASSESSMENT:",
      body: "<p>This section allows you to create meaningful assessments that align with your curriculum objectives. Ensure your students are tested fairly and comprehensively.</p>",
    },
  },
  $vuetify: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items...",
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}",
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year",
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} more",
    },
    input: {
      clear: "Clear {0}",
      prependAction: "{0} prepended action",
      appendAction: "{0} appended action",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
    loading: "Loading...",
  },
};

export default en;
