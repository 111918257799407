<template>
  <div id="teacherProfiles">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Teachers </v-card-title>
        <v-spacer />
        <!--  <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Teacher Profile
        </v-btn> -->
        <v-btn
          class="mr-3"
          @click="createOrUpdateReportingTeacherEmails"
          :disabled="offline"
          >Create Reporting Teachers
        </v-btn>
        <v-btn
          class="mr-3"
          @click="ecwidImport"
          :disabled="
            ecwidImporting || !initiallyLoaded || !secretSubscriptionSynced
          "
          :loading="ecwidImporting || !secretSubscriptionSynced"
          >Ecwid Import</v-btn
        >
        <v-btn
          class="mr-3"
          @click="bulkEcwidUpdate"
          :disabled="bulkEcwidUpdating || !initiallyLoaded || true"
          :loading="bulkEcwidUpdating"
          >Bulk Ecwid Update</v-btn
        >
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="teacherProfiles"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :loading="
            !teacherProfileSubscriptionSynced && teacherProfiles?.length === 0
          "
          loading-text="Loading Teacher Profiles..."
          no-data-text="No Teacher Profiles found"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDateTime(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDateTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.payfastSubscriptionResponse`]="{ item }">
            {{
              item.payfastSubscriptionResponse
                ? getPayFastStatus(item.payfastSubscriptionResponse)
                : ""
            }}
          </template>
          <template v-slot:[`item.productSubscriptions`]="{ item }">
            {{ item.productSubscriptions?.length?.toString() || "0" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="ecwidUpdate(item)"
              :disabled="offline"
            >
              mdi-api
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                    :rules="formRules.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <!-- <v-switch
                    v-model="editedItem.isGeneric"
                    label="Is this a generic school? Templates linked to this school will be avalable to all teachers."
                  ></v-switch> -->
                </v-col>
                <v-col cols="12">
                  <!-- <v-select
                    v-model="editedItem.provinces"
                    :items="provinces"
                    label="Select Provinces"
                    multiple
                    chips
                    hint="What are the linked provinces for this school?"
                    persistent-hint
                  ></v-select> -->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this school?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { Secret, TeacherProfile, ReportingTeacher } from "@/models";
import AWS from "aws-sdk";
import _ from "lodash";

export default {
  name: "AdminTeacherProfiles",
  data: () => ({
    bulkEcwidUpdating: false,
    ecwidImporting: false,
    initiallyLoaded: false,
    teacherProfileSubscription: null,
    teacherProfileSubscriptionSynced: false,
    syncedTeacherProfileArray: [],
    secretSubscription: null,
    secretSubscriptionSynced: false,
    syncedSecretArray: [],
    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Surname", value: "surname" },
      { text: "Title", value: "title" },
      { text: "Province", value: "province" },
      { text: "Language", value: "language" },
      { text: "Created", value: "createdAt" },
      { text: "Updated", value: "updatedAt" },
      {
        text: "Subscription Status",
        value: "payfastSubscriptionResponse",
      },
      { text: "Products", value: "productSubscriptions" },
      { text: "Owner", value: "owner" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    teacherProfiles: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      provinces: [],
      isGeneric: false,
    },
    defaultItem: {
      name: "",
      provinces: [],
      isGeneric: false,
    },
    formRules: {
      name: [(v) => !!v || "Name is required"],
    },
    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],
    languages: {
      af: "Afrikaans",
      en: "English",
    },
  }),

  async mounted() {
    try {
      // Sync the TeacherProfiles from the datastore
      await this.syncTeacherProfiles();
      await this.syncSecrets();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "New TeacherProfile"
        : "Edit TeacherProfile";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toISOString().split("T")[0];
    },
    formatDateTime(date) {
      if (!date) return "";
      return new Date(date).toISOString().replace("T", " ").split(".")[0];
    },
    convertDateTimeToAWSDateTime(dateTimeStr) {
      const [datePart, timePart] = dateTimeStr.split(" ");

      const [year, month, day] = datePart.split("-");
      const [hours, minutes] = timePart.split(":");

      const awsDateTime = `${year}-${month}-${day}T${hours.padStart(
        2,
        "0"
      )}:${minutes.padStart(2, "0")}:00.000Z`;

      return awsDateTime;
    },
    getPayFastStatus(payFastResponse) {
      const responseObj = payFastResponse;
      return responseObj?.status_text ? responseObj.status_text : "";
    },

    customSortPayfastStatus(a, b) {
      const statusA = this.getPayFastStatus(a);
      const statusB = this.getPayFastStatus(b);

      if (!this.sortDesc) {
        return statusA.localeCompare(statusB);
      }

      return statusB.localeCompare(statusA);
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    async bulkEcwidUpdate() {
      this.bulkEcwidUpdating = true;

      try {
        for (const [index, item] of this.teacherProfiles.entries()) {
          await this.ecwidUpdate(item);

          // Add delay for rate limiting (10 updates per second = 100ms per update)
          if ((index + 1) % 10 === 0) {
            console.log("Pausing for rate limiting...");
            await new Promise((resolve) => setTimeout(resolve, 1500)); // 1 second pause every 10 items
            console.log("Continue...");
          }
        }
      } catch (error) {
        console.error("Error during bulk Ecwid update:", error);
      } finally {
        this.bulkEcwidUpdating = false;
      }
    },

    async ecwidImport() {
      this.ecwidImporting = true;
      let newlyAddedUserEmails = [];

      try {
        // AWS Access Key ID and Secret Access Key------------------
        const accessKeyId = this.syncedSecretArray.find(
          (obj) => obj.type === "IAM"
        )?.value?.accessKeyId;

        const secretAccessKey = this.syncedSecretArray.find(
          (obj) => obj.type === "IAM"
        )?.value?.secretAccessKey;
        // ---------------------------------------------------------

        // Ecwid store ID and access token--------------------------
        const storeId = this.syncedSecretArray.find(
          (obj) => obj.type === "EcwidStoreToken"
        )?.value?.storeId;

        const accessToken = this.syncedSecretArray.find(
          (obj) => obj.type === "EcwidStoreToken"
        )?.value?.accessToken;
        // ---------------------------------------------------------

        // console.log("IAM:", accessKeyId, secretAccessKey);
        // console.log("Ecwid:", storeId, accessToken);

        AWS.config.update({
          region: "eu-west-2",
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
        });

        // Create an instance of the CognitoIdentityServiceProvider
        const CognitoIdentityServiceProvider =
          new AWS.CognitoIdentityServiceProvider();

        // Encode the createdFrom to ensure it's URI-safe
        const createdFrom = encodeURIComponent("2024-12-14 00:00:00");
        const limit = 100;
        let offset = 0;
        let customers = [];
        let fetchMore = true;

        // let newlyAddedUserEmails = [];

        while (fetchMore) {
          // Construct the Ecwid API URL for searching customers
          const url = `https://app.ecwid.com/api/v3/${storeId}/customers?limit=${limit}&offset=${offset}&createdFrom=${createdFrom}`;

          // Fetch customer data from Ecwid
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          // Check if the response is successful
          if (!response.ok) {
            throw new Error(
              `Error fetching customer data: ${response.statusText}`
            );
          }

          // Parse the JSON response
          const data = await response.json();

          // Add customers to the list
          customers = data.items;

          console.log("Ecwid customer data:", customers);

          // loop customers and save to TeacherProfile
          for (const customer of customers) {
            if (
              [
                "amandavsf4@gmail.com",
                "camtech8@gmail.com",
                "carike123@gmail.com",
                "cbassingthwaighte369@gmail.con",
                "gloudinelourens@gmail.com",
                "lindequejuanita@gmail.com",
                "liezlforsman9@gmail.com",
                "dlai@dunveganprimary.co.za",
                "jenatitus20@gmail.com",
                "liz.brazer@gmail.con",
                "lizettemartins1@gmail.com",
                "malan.bronwyn@gmail.com",
                "leratorasetanka18@gmail.com",
                "meganjustus.uni@gmail.com",
                "pulabred@gmail.com",
                "vanheerdenr25@gmail.com",
                "elmariesmit@breede.co.za",
                "annalieger@gmail.com",
                "missanri@go-app.co.za",
                "carmennvanzyl@gmail.com",
                "estelle143els@gmail.com",
                "tutordanielle1@gmail.com",
                "lewisgezell0@gmail.com",
                "joudenerautenbach@yahoo.com",
                "loots@absamail.co.za",
                "juanita@stormaccounting.co.za",
                "letitiareyneke3@gmail.com",
                "marlise0909@gmail.com",
                "playjoymm@gmail.com",
                "liezel.vanrooyen.@yahoo.com",
                "psteenkampmuscle@gmail.com",
              ].includes(customer.email)
            ) {
              console.log("Skipping customer with email:", customer);
              continue;
            }
            //check if teacher profile already exists
            const existingTeacherProfile = _.find(
              this.syncedTeacherProfileArray,
              {
                owner: customer.email,
              }
            );

            if (!existingTeacherProfile) {
              //create cognito user
              // const cognitoUser = await Auth.signUp({
              //   username: customer.email,
              //   password: `tempPass${Math.floor(1000 + Math.random() * 9000)}!`,
              //   attributes: {
              //     email: customer.email,
              //     phone_number: "+270000000000",
              //   },
              // });
              // console.log("Cognito User:", cognitoUser);

              // console.log("Create TeacherProfile:", customer);
              // console.log(
              //   "Create TeacherProfile:",
              //   customer.email,
              //   "+270000000000",
              //   `tempPass${Math.floor(1000 + Math.random() * 9000)}!`
              // );

              const tempPass = `tempPass${Math.floor(
                1000 + Math.random() * 9000
              )}!`;

              const cognitoUserParams = {
                UserPoolId: "eu-west-2_TMc4HcU2w",
                Username: customer.name
                  ? customer.name.replaceAll(" ", "")
                  : customer.email, // Ensure username is unique for each user
                // "johandupreez.dev@gmail.com",
                UserAttributes: [
                  {
                    Name: "email",
                    Value: customer.email, // User's email
                    // "johandupreez.dev@gmail.com",
                  },
                  {
                    Name: "phone_number",
                    Value: "+270000000000", // User's phone number
                  },
                ],
                // DesiredDeliveryMediums: ["EMAIL"], // Use EMAIL to send the verification message
                TemporaryPassword: tempPass, // Set a temporary password if needed
              };

              const cognitoResponse =
                await CognitoIdentityServiceProvider.adminCreateUser(
                  cognitoUserParams
                ).promise();

              console.log("cognitoResponse:", cognitoResponse);

              const cognitoUser = cognitoResponse?.User;
              console.log("cognitoUser:", cognitoUser);

              if (!cognitoUser) {
                console.log("No user created for email: ", customer.email);
                continue;
              }

              const subAttribute = cognitoUser.Attributes?.find(
                (attr) => attr.Name === "sub"
              );

              if (!subAttribute) {
                console.log("No sub attribute found for user: ", cognitoUser);
                continue;
              }

              newlyAddedUserEmails.push({
                email: customer.email, // User's email
                // "johandupreez.dev@gmail.com",
                temporaryPassword: tempPass,
              });

              const owner = `${subAttribute.Value}::${cognitoUser.Username}`;
              console.log("Owner: ", owner);

              await DataStore.save(
                new TeacherProfile({
                  title: "",
                  name: customer.billingPerson?.firstName || "",
                  surname: customer.billingPerson?.lastName || "",
                  language: this.languages[customer.lang] || "English",
                  province:
                    customer.shippingAddresses[0]?.stateOrProvinceName || "",
                  daysPerWeek: 0,
                  status: "Active",
                  TeacherSchedules: [],
                  TeacherClassSubjects: [],
                  type: "TeacherProfile",
                  owner: owner,
                  createdAt: customer.registered
                    ? this.convertDateTimeToAWSDateTime(customer.registered)
                    : new Date().toISOString(),
                })
              );
            }
          }

          // Increment the offset for the next batch
          offset += limit;

          // Stop the loop if no more items are returned
          if (data.items.length === 0) {
            fetchMore = false;
          } else {
            //console.log("Ecwid customer data:", customers);
          }
        }

        console.log("Newly added user emails: ", newlyAddedUserEmails);
      } catch (error) {
        console.error("Error during Ecwid import:", error);
      } finally {
        console.log("Finally: Newly added user emails: ", newlyAddedUserEmails);
        this.ecwidImporting = false;
      }
    },

    //   async createCognitoUser(){
    // // Configure the AWS region of the Cognito User Pool
    // AWS.config.update({ region: "eu-west-2",
    //           accessKeyId: "",
    //           secretAccessKey: "", });

    // // Create an instance of the CognitoIdentityServiceProvider
    // const CognitoIdentityServiceProvider =
    //   new AWS.CognitoIdentityServiceProvider();

    // // Loop through the array of users provided in the request body
    // const users = req.body.users; // Expecting an array of user objects

    // if (!Array.isArray(users) || users.length === 0) {
    //   return { error: "Invalid user data" };
    // }

    // const createUserPromises = users.map((user) => {
    //   const params = {
    //     UserPoolId: userPoolID,
    //     Username: user.name, // Ensure username is unique for each user
    //     UserAttributes: [
    //       {
    //         Name: "email",
    //         Value: user.email, // User's email
    //       },
    //       {
    //         Name: "phone_number",
    //         Value: user.phoneNumber, // User's phone number
    //       },
    //     ],
    //     // DesiredDeliveryMediums: ["EMAIL"], // Use EMAIL to send the verification message
    //     TemporaryPassword: user.temporaryPassword, // Set a temporary password if needed
    //   };

    //   return CognitoIdentityServiceProvider.adminCreateUser(params).promise();
    // });

    // try {
    //   const createResponses = await Promise.all(createUserPromises);
    //   console.log("createResponses", createResponses);
    //   return {
    //     success: "Users created successfully!",
    //     cognitoUsers: createResponses,
    //   };
    // } catch (err) {
    //   console.error(err);
    //   return { error: "Error creating users", details: err };
    // }
    //   },

    async ecwidUpdate(item) {
      if (item.name || item.payfastSubscriptionResponse) {
        console.log("Skipping Ecwid update for item:", item);

        return;
      }

      this.editedIndex = this.teacherProfiles.indexOf(item);
      this.editedItem = { ...item };
      // console.log("Ecwid Update", item);

      try {
        // Define your Ecwid store ID and access token
        const storeId = "49523006";
        const accessToken = "secret_9Z1HGrngWamuz5ZLHD1Gzvvjk4CTCtB3";

        // Encode the email to ensure it's URI-safe
        const email = encodeURIComponent(this.editedItem.owner);

        // Construct the Ecwid API URL for searching customers by email
        const url = `https://app.ecwid.com/api/v3/${storeId}/customers?email=${email}`;

        // Fetch customer data from Ecwid
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Check if the response is successful
        if (!response.ok) {
          throw new Error(
            `Error fetching customer data: ${response.statusText}`
          );
        }

        // Parse the JSON response
        const data = await response.json();

        // Check if any customers were found
        if (data.total === 0) {
          throw new Error("No customer found with the provided email.");
        }

        // Assuming the first customer in the list is the one you're interested in
        const customer = data.items[0];

        console.log("Ecwid customer data:", customer);

        // Update the TeacherProfile model with the fetched customer data
        const tP = await DataStore.save(
          TeacherProfile.copyOf(
            _.find(this.syncedTeacherProfileArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.name =
                customer.billingPerson?.firstName || this.editedItem.name),
                (updateModel.surname =
                  customer.billingPerson?.lastName || this.editedItem.surname),
                (updateModel.createdAt =
                  this.convertDateTimeToAWSDateTime(customer.registered) ||
                  this.editedItem.createdAt),
                (updateModel.province =
                  customer.shippingAddresses[0]?.stateOrProvinceName ||
                  this.editedItem.province),
                (updateModel.language =
                  this.languages[customer.lang] || this.editedItem.language);

              // Update other fields as needed
              // For example:
              // updateModel.email = customer.email;
              // updateModel.totalOrderCount = customer.totalOrderCount;
              // Add more fields based on your TeacherProfile model structure
            }
          )
        );
        console.log("Ecwid update successful:", tP);
      } catch (error) {
        console.error("Error during Ecwid update:", error);
      }
    },

    async createOrUpdateReportingTeacherEmails() {
      this.creatingReportingData = true;
      for (const teacherProfile of this.teacherProfiles) {
        console.log("teacherProfile", teacherProfile);

        try {
          // Retrieve the email from cognitoAttributes
          // const email = this.getCognitoAttributeValue(
          //   teacherProfile.cognitoAttributes,
          //   "email"
          // );

          // Check if a ReportingTeacher record already exists for this teacherprofileID
          const existingTeacher = await DataStore.query(ReportingTeacher, (c) =>
            c.teacherprofileID("eq", teacherProfile.id)
          );

          if (existingTeacher.length > 0) {
            // If record exists and email is different, update it
            const teacherRecord = existingTeacher[0];
            console.log("Found teacherRecord", teacherRecord);
            // if (teacherRecord.email !== email) {
            //   await DataStore.save(
            //     ReportingTeacher.copyOf(teacherRecord, (updated) => {
            //       updated.email = email;
            //     })
            //   );
            //   console.log(
            //     `Updated ReportingTeacher for teacherprofileID: ${teacherProfile.id}`
            //   );
            // } else {
            //   console.log(
            //     `No update needed for teacherprofileID: ${teacherProfile.id}`
            //   );
            // }
          } else {
            // If record doesn't exist, create a new ReportingTeacher record
            // await DataStore.save(
            //   new ReportingTeacher({
            //     teacherprofileID: teacherProfile.id,
            //     email: email,
            //   })
            // );
            console.log(
              `Created new ReportingTeacher for teacherprofileID: ${teacherProfile.id}`
            );
          }
          this.creatingReportingData = false;
        } catch (error) {
          console.log(error);
          this.creatingReportingData = false;
        }
      }
    },

    editItem(item) {
      this.editedIndex = this.teacherProfiles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.teacherProfiles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.holidays.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedTeacherProfileArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update TeacherProfile
        await this.updateTeacherProfile();
      } else {
        // Create new TeacherProfile
        await this.createTeacherProfile();
      }
      this.close();
    },

    async syncTeacherProfiles() {
      try {
        this.teacherProfileSubscription = DataStore.observeQuery(
          TeacherProfile
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTeacherProfileArray !== items) {
              this.syncedTeacherProfileArray = items;

              this.teacherProfiles = _.cloneDeep(items);

              this.teacherProfileSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.teacherProfileSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async createTeacherProfile() {
      try {
        await DataStore.save(
          new TeacherProfile({
            name: this.editedItem.name.trim(),
            provinces: this.editedItem.provinces,
            isGeneric: this.editedItem.isGeneric,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateTeacherProfile() {
      try {
        await DataStore.save(
          TeacherProfile.copyOf(
            _.find(this.syncedTeacherProfileArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.name = this.editedItem.name.trim()),
                (updateModel.provinces = this.editedItem.provinces),
                (updateModel.isGeneric = this.editedItem.isGeneric);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSecrets() {
      try {
        this.secretSubscription = DataStore.observeQuery(Secret).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSecretArray !== items) {
              this.syncedSecretArray = items;

              console.log("this.syncedSecretArray", this.syncedSecretArray);

              this.secretSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.secretSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
    if (this.secretSubscription) {
      this.secretSubscription.unsubscribe();
    }
  },
};
</script>
