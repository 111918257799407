<template>
  <div id="coupons">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Coupons </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline || !initiallyLoaded"
        >
          New Coupon
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="syncedCouponArray"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :loading="
            (!couponSubscriptionSynced && syncedCouponArray?.length === 0) ||
            (!reportingTeacherSubscriptionSynced &&
              syncedReportingTeacherArray?.length === 0) ||
            !initiallyLoaded
          "
          loading-text="Loading Coupons..."
          no-data-text="No Coupons found"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            {{ item.startDate ? formatDate(item.startDate) : "" }}
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            {{ item.endDate ? formatDate(item.endDate) : "" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline || !initiallyLoaded"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
              :disabled="offline || !initiallyLoaded"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.couponCode"
                    label="Coupon Code"
                    :rules="formRules.couponCode"
                    required
                    @input="onCouponCodeInput"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="editedItem.enabled"
                    label="Enabled"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col cols="12">
                  <v-select
                    v-model="editedItem.sendTo"
                    :items="['ALL', ...teachersWithFullName]"
                    item-text="fullName"
                    item-value="id"
                    label="Send To"
                    multiple
                  ></v-select>
                </v-col> -->
                <!-- <v-col cols="12">
                  <v-select
                    v-model="editedItem.readBy"
                    :items="teachersWithFullName"
                    item-text="fullName"
                    item-value="id"
                    label="Read By"
                    multiple
                    readonly
                    append-icon=""
                  ></v-select>
                </v-col> -->

                <v-col cols="6">
                  <v-select
                    v-model="editedItem.discount.type"
                    :items="['PERCENTAGE', 'AMOUNT (R)']"
                    label="Discount Type"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.discount.value"
                    label="Discount Value"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-dialog
                    ref="dialogStartDate"
                    v-model="datePickerStartDateModel"
                    :return-value.sync="editedItem.startDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.startDate"
                        label="Start Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.startDate"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="datePickerStartDateModel"
                      v-model="editedItem.startDate"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="datePickerStartDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialogStartDate.save(editedItem.startDate)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="dialogEndDate"
                    v-model="datePickerEndDateModel"
                    :return-value.sync="editedItem.endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.endDate"
                        label="End Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.endDate"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="datePickerEndDateModel"
                      v-model="editedItem.endDate"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="datePickerEndDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogEndDate.save(editedItem.endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="editedItem.restrictTo.type"
                    :items="['Teachers', 'ALL']"
                    label="Restrict to"
                    @change="editedItem.restrictTo.value = null"
                  ></v-select>
                </v-col>
                <v-col cols="6" v-show="editedItem.restrictTo.type !== 'ALL'">
                  <v-autocomplete
                    v-if="editedItem.restrictTo.type === 'Teachers'"
                    v-model="editedItem.restrictTo.value"
                    :items="teachersWithFullName"
                    item-text="fullName"
                    item-value="id"
                    label="Restrict to"
                    multiple
                    :filter="customFilter"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="text-wrap py-1"
                        style="height: fit-content"
                      >
                        <div>
                          <div>{{ data.item.fullName }}</div>
                          <small class="text-muted">{{
                            data.item.email
                          }}</small>
                        </div>
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <!-- <v-checkbox :value="true"></v-checkbox>
                      <div>
                        <div>{{ data.item.fullName }}</div>
                        <small class="text-muted">{{ data.item.email }}</small>
                      </div> -->
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.fullName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          data.item.email
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this coupon?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  DataStore,
  // API, Auth,
  SortDirection,
  Predicates,
} from "aws-amplify";
import {
  Coupon,
  // , TeacherProfile
  ReportingTeacher,
} from "@/models";
import _ from "lodash";

export default {
  name: "AdminCoupons",
  data: () => ({
    initiallyLoaded: false,

    couponSubscription: null,
    couponSubscriptionSynced: false,
    syncedCouponArray: [],

    reportingTeacherSubscription: null,
    syncedReportingTeacherArray: [],
    reportingTeacherSubscriptionSynced: false,

    // teacherProfileSubscription: null,
    // syncedTeacherProfileArray: [],

    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "couponCode",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Code", value: "couponCode" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    // coupons: [],

    datePickerStartDateModel: null,
    datePickerEndDateModel: null,

    editedIndex: -1,
    editedItem: {
      restrictTo: {
        type: "",
        value: null,
      },
      startDate: "",
      endDate: "",
      discount: {
        type: "",
        value: null,
      },
      enabled: false,
      couponCode: "",
    },

    defaultItem: {
      restrictTo: {
        type: "",
        value: null,
      },
      startDate: "",
      endDate: "",
      discount: {
        type: "",
        value: null,
      },
      enabled: false,
      couponCode: "",
    },

    formRules: {
      couponCode: [
        (v) => !!v || "Coupon code is required",
        (v) =>
          /^[A-Z0-9]*$/.test(v) ||
          "Only uppercase alphanumeric characters are allowed",
      ],
      startDate: [(v) => !!v || "A Start Date is required"],
      endDate: [(v) => !!v || "An End Date is required"],
    },
  }),

  async mounted() {
    try {
      // Sync the Coupons from the datastore
      this.syncCoupons();

      this.formRules.couponCode.push(
        (v) =>
          !this.syncedCouponArray.some(
            (item) =>
              item.couponCode === v &&
              item.couponCode !== this.editedItem.couponCode
          ) || "Coupon code already exists"
      );

      // await this.getTeacherProfiles();
      await this.syncReportingTeachers();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      appLoading: (state) => !state.appLoading,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Coupon" : "Edit Coupon";
    },
    teachersWithFullName() {
      return this.syncedReportingTeacherArray.map((item) => {
        if (!item.teacherprofileID || !item.roles?.includes("teacher"))
          return null;

        let fullName = "";
        const cognitoUsername = item.owner?.split("::").pop();

        if (item.name) {
          fullName +=
            (item.title
              ? item.title + " " + item.name + " "
              : item.name + " ") +
            (item.surname ? item.surname : "") +
            (cognitoUsername ? " - " + cognitoUsername : "");
        } else if (cognitoUsername) {
          fullName += cognitoUsername;
        }

        return {
          id: item.teacherprofileID,
          email: item.email,
          fullName: fullName,
        };
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    customFilter(item, queryText, itemText) {
      if (!queryText) return true;

      const fullName = item.fullName
        .toLowerCase()
        .includes(queryText.toLowerCase());
      const emailMatch = item.email
        .toLowerCase()
        .includes(queryText.toLowerCase());
      const idMatch = item.id
        .toString()
        .toLowerCase()
        .includes(queryText.toLowerCase());

      return fullName || idMatch || emailMatch;
    },

    formatDate(date) {
      if (!date) return "";
      return new Date(date).toISOString().split("T")[0];
    },

    onCouponCodeInput(value) {
      // Convert to uppercase and remove non-alphanumeric characters
      this.editedItem.couponCode = value
        ? value.toUpperCase().replace(/[^A-Z0-9]/g, "")
        : "";
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.syncedCouponArray.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.syncedCouponArray.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.coupons.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedCouponArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    convertToAwsDateFormat(dateString) {
      if (!dateString) return null; // Return null when date is null
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month =
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // month is 0-indexed
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year}-${month}-${day}`;
    },

    async save() {
      this.editedItem.startDate = this.convertToAwsDateFormat(
        this.editedItem.startDate
      );
      this.editedItem.endDate = this.convertToAwsDateFormat(
        this.editedItem.endDate
      );

      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateCoupon();
      } else {
        // Create new schedule
        await this.createCoupon();
      }
      this.close();
    },

    async createCoupon() {
      try {
        const restrictTo = {
          type: this.editedItem.restrictTo.type,
          value:
            this.editedItem.restrictTo.type === "ALL"
              ? null
              : this.editedItem.restrictTo.value,
        };

        await DataStore.save(
          new Coupon({
            couponCode: this.editedItem.couponCode.trim(),
            enabled: this.editedItem.enabled,
            startDate: this.editedItem.startDate,
            endDate: this.editedItem.endDate,
            discount: {
              ...this.editedItem.discount,
              value: parseFloat(this.editedItem.discount.value),
            },
            restrictTo: restrictTo,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateCoupon() {
      try {
        const restrictTo = {
          type: this.editedItem.restrictTo.type,
          value:
            this.editedItem.restrictTo.type === "ALL"
              ? null
              : this.editedItem.restrictTo.value,
        };

        await DataStore.save(
          Coupon.copyOf(
            _.find(this.syncedCouponArray, { id: this.editedItem.id }),
            (updateModel) => {
              updateModel.couponCode = this.editedItem.couponCode.trim();
              updateModel.enabled = this.editedItem.enabled;
              updateModel.startDate = this.editedItem.startDate;
              updateModel.endDate = this.editedItem.endDate;
              updateModel.discount = {
                ...this.editedItem.discount,
                value: parseFloat(this.editedItem.discount.value),
              };
              updateModel.restrictTo = restrictTo;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncCoupons() {
      try {
        this.couponSubscription = DataStore.observeQuery(Coupon).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedCouponArray !== items) {
              this.syncedCouponArray = items;

              this.couponSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.couponSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncReportingTeachers() {
      try {
        this.reportingTeacherSubscription = DataStore.observeQuery(
          ReportingTeacher,
          Predicates.ALL,
          {
            sort: (s) =>
              s
                .name(SortDirection.ASCENDING)
                .surname(SortDirection.ASCENDING)
                .email(SortDirection.ASCENDING)
                .title(SortDirection.ASCENDING),
          }
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedReportingTeacherArray !== items
            ) {
              this.syncedReportingTeacherArray = items;
              this.reportingTeacherSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.reportingTeacherSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    // async getTeacherProfiles() {
    //   try {
    //     // this.teacherProfileSubscription = DataStore.observeQuery(
    //     //   TeacherProfile
    //     // ).subscribe(
    //     //   async (snapshot) => {
    //     //     const { items, isSynced } = snapshot;
    //     //     if (isSynced && items && this.syncedTeacherProfileArray !== items) {
    //     //       this.syncedTeacherProfileArray = items;
    //     //     }
    //     //   },
    //     //   (error) => {
    //     //     console.log(error);
    //     //   }
    //     // );

    //     const user = await Auth.currentAuthenticatedUser().catch(() => {
    //       // eslint-disable-next-line @typescript-eslint/no-empty-function
    //     });

    //     if (!user) return;

    //     await API.get("adminlambdaapi", "/item/queryTeacherProfile", {
    //       queryStringParameters: {
    //         userPoolId: user.pool.userPoolId,
    //         userName: user.username,
    //       },
    //     })
    //       .then(async (response) => {
    //         if (response.teacherProfiles)
    //           this.syncedTeacherProfileArray = response.teacherProfiles;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },

  beforeDestroy() {
    if (this.couponSubscription) {
      this.couponSubscription.unsubscribe();
    }
    if (this.teacherProfileSubscription) {
      this.teacherProfileSubscription.unsubscribe();
    }
  },
};
</script>
