import Vue from "vue";
import App from "./App.vue";

import VTiptap from "@peepi/vuetify-tiptap";

import {
  // TeacherProfile,
  // Announcement,
  TeacherProductsOrder,
  // Product,
  // Communication,
  // ProductCategory,
  // ProductTag,
  // Taxonomy,
  // ProductContent,
  // TeacherAssessmentTemplate,
  // AssessmentTemplate,
  // TeacherSubjectTemplate,
  // SubjectTemplate,
  // School,
  // TeacherActivity,
  // Term,
  // Holiday,
  // TeacherClassSubject,
  // Subject,
  // TeacherSchedule,
  ReportingTeacher,
  ArchivedTeacherProductsOrder,
} from "@/models";

import "@aws-amplify/ui-vue";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import {
  Amplify,
  AuthModeStrategyType,
  DataStore,
  syncExpression,
  // Predicates,
} from "aws-amplify";
import awsconfig from "./aws-exports";
import { ConsoleLogger } from "@aws-amplify/core";

import router from "./router";
import store from "./store";
import "./registerServiceWorker";

import { vuetify, i18n } from "./plugins/vuetify/vuetify.js";
import "@/sass/main.scss";

import { VueReCaptcha } from "vue-recaptcha-v3";

import VueMasonry from "vue-masonry-css";

ConsoleLogger.LOG_LEVEL = "OFF"; // 'OFF'/'ERROR'/'WARN'/'INFO'

// await DataStore.stop();

//one month ago
const now = new Date();
now.setMonth(now.getMonth() - 1);

const oneMonthAgo = now.toISOString();

// console.log("oneMonthAgo", oneMonthAgo);

DataStore.configure({
  maxRecordsToSync: 100000,
  syncPageSize: 200,
  syncExpressions: [
    syncExpression(TeacherProductsOrder, () => {
      return (model) => model.updatedAt("gt", oneMonthAgo);
    }),
    syncExpression(ReportingTeacher, () => {
      return (model) =>
        model.or((model) => [
          model.roles("contains", "admin"),
          model.roles("contains", "teacher"),
        ]);
    }),
    syncExpression(ArchivedTeacherProductsOrder, () => {
      return (model) => model.id("eq", "");
    }),
  ],
});

Amplify.configure({
  ...awsconfig,
  // Auth: {
  //   signUpConfig: {
  //     hiddenDefaults: ["phone_number"],
  //   },
  // },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    region: "eu-west-2",
  },
  Storage: {
    AWSS3: {
      bucket: "missanriscn-storage-c4dd76e0", //REQUIRED -  Amazon S3 bucket name
      region: "eu-west-2", //OPTIONAL -  Amazon service region
    },
  },
});

Vue.use(VueMasonry);

Vue.use(VTiptap);

Vue.use(VueReCaptcha, {
  siteKey: "6LdMEJMmAAAAAAR969iuiDARS96O0GOtzOqM7QRV",
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

Vue.config.productionTip = false;

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
