<template>
  <div id="Products">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Products </v-card-title>
        <v-spacer />
        <div
          class="d-flex flex-row px-4 flex-wrap align-center"
          style="gap: 24px"
        >
          <span
            v-if="productsContentCheckProgress !== null"
            class="primary--text"
          >
            {{ checkingProductsProgressText }}
          </span>

          <v-btn
            color="secondary"
            class="black--text"
            @click="checkIfProductContentExists"
            :loading="loading"
            :disabled="offline || loading"
          >
            Check Content
          </v-btn>
          <v-btn
            color="secondary"
            class="black--text"
            @click="manageProductCategories"
            :disabled="offline || savingSelectedProducts"
          >
            Manage Categories
          </v-btn>
          <v-btn
            color="secondary"
            class="black--text"
            @click="manageProductTags"
            :disabled="offline || savingSelectedProducts"
          >
            Manage Tags
          </v-btn>
          <v-btn
            color="primary"
            class=""
            @click="createItem"
            :disabled="offline || savingSelectedProducts"
          >
            New Product
          </v-btn>
        </div>
      </v-row>

      <v-row class="px-4">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="productsDataTableSearch"
            append-icon="mdi-magnify"
            label="Search"
            @input="queryProducts"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="4">
          <v-combobox
            class="productsSearchInputCSS pt-2"
            v-model="productsSearchTags"
            label="Tags"
            multiple
            chips
            dense
            @change="queryProducts"
            min-height="36px"
            deletable-chips
            clearable
            :items="syncedProductTagArray"
            item-text="name"
            :return-object="false"
            item-value="id"
          ></v-combobox>
        </v-col>
        <v-col cols="6" sm="4">
          <v-menu
            v-model="searchProductCategoriesMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :nudge-bottom="-12"
            :rounded="false"
          >
            <template v-slot:activator="{ on }">
              <div @click="toggleMenu">
                <v-combobox
                  class="productsSearchInputCSS pt-2"
                  :value="computedProductsSearchCategories"
                  label="Categories"
                  multiple
                  chips
                  dense
                  min-height="36px"
                  clearable
                  v-on="on"
                  readonly
                  @click:clear="productsDataTableSearchCategories = []"
                ></v-combobox>
              </div>
            </template>
            <v-card>
              <v-card-text>
                <v-treeview
                  :items="categoryTree"
                  color="primary"
                  selected-color="primary"
                  selectable
                  item-key="id"
                  open-on-click
                  selection-type="independent"
                  v-model="productsDataTableSearchCategories"
                  @input="queryProducts"
                ></v-treeview>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="6" sm="4" md="3">
          <v-checkbox
            v-model="searchPhysicalProduct"
            label="Physical Product"
            @change="changePhysEcommSubSearch('physical')"
            class="mt-0"
          ></v-checkbox
        ></v-col>
        <v-col cols="6" sm="4" md="3">
          <v-checkbox
            v-model="searchSubscriptionProduct"
            label="Subscription Product"
            @change="changePhysEcommSubSearch('sub')"
            class="mt-0"
          ></v-checkbox
        ></v-col>
        <v-col cols="6" sm="4" md="3">
          <v-checkbox
            v-model="searchECommerceProduct"
            label="E-Commerce Product"
            @change="changePhysEcommSubSearch('ecomm')"
            class="mt-0"
          ></v-checkbox
        ></v-col>
        <v-col cols="6" sm="4" md="3" v-if="failedProductsContent.length > 0">
          <v-checkbox
            v-model="searchMissingProductContent"
            @change="queryProducts"
            label="Missing Content"
            class="mt-0"
          ></v-checkbox
        ></v-col>
      </v-row>

      <div v-if="selectedProducts.length > 0">
        <v-card-title class="text-h5 pb-4 pt-0"> Bulk Edit </v-card-title>

        <div class="px-3 pb-3" style="max-width: 470px">
          <!-- <v-col class="pt-0" cols="12" sm="6" md="4"> -->
          <v-expansion-panels
            class="mb-3"
            style="justify-content: left"
            v-model="expBulkEditPanels"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-1"> mdi-star </v-icon>
                  <div>
                    <span> Feature</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="expPanelContent"
                color="grey lighten-3"
              >
                <v-row class="pa-3 d-flex" style="gap: 12px">
                  <v-btn
                    color="primary"
                    @click="featureProducts"
                    :disabled="offline || savingSelectedProducts"
                  >
                    <v-icon left> mdi-star </v-icon>Feature Products
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="unFeatureProducts"
                    :disabled="offline || savingSelectedProducts"
                  >
                    <v-icon left> mdi-star-outline </v-icon>Unfeature Products
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="pa-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-1">mdi-calendar</v-icon>
                  <div>
                    <span> Schedule</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="expPanelContent"
                color="grey lighten-3"
              >
                <v-row class="pa-3 d-flex align-center" style="gap: 12px">
                  <v-dialog
                    v-model="bulkEditDateTimeDialog"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedBulkEditPublishDate"
                        readonly
                        label="Publish Date"
                        v-bind="{ ...attrs, ...$attrs }"
                        v-on="on"
                        clearable
                        @click:clear="updateBulkEditPublishDateTime('clear')"
                      ></v-text-field>
                    </template>
                    <v-card class="t-datetime-picker white">
                      <v-toolbar height="36" color="primary" dark flat>
                        <v-tabs v-model="bulkEditDateTimeTab" grow height="36">
                          <v-tabs-slider color="white"></v-tabs-slider>
                          <v-tab href="#date">
                            <v-icon>mdi-calendar</v-icon>
                          </v-tab>
                          <v-tab href="#time">
                            <v-icon>mdi-clock</v-icon>
                          </v-tab>
                          <v-btn
                            depressed
                            text
                            class="rounded-0"
                            @click="closeBulkEditDateTimeDialog"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-tabs>
                      </v-toolbar>

                      <v-tabs-items v-model="bulkEditDateTimeTab">
                        <v-tab-item value="date">
                          <v-date-picker
                            v-model="bulkEditPublishDate"
                            @change="updateBulkEditPublishDateTime"
                            class="rounded-0"
                            @input="bulkEditDateTimeTab = 'time'"
                            full-width
                          >
                          </v-date-picker>
                        </v-tab-item>
                        <v-tab-item value="time">
                          <v-time-picker
                            :key="bulkEditDateTimeTab"
                            v-model="bulkEditPublishTime"
                            format="24hr"
                            class="rounded-0"
                            full-width
                            @change="updateBulkEditPublishDateTime"
                          >
                          </v-time-picker>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    color="primary"
                    @click="scheduleProducts"
                    :disabled="
                      offline ||
                      savingSelectedProducts ||
                      !bulkEditPublishDateTime
                    "
                  >
                    Schedule
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="unScheduleProducts"
                    :disabled="offline || savingSelectedProducts"
                  >
                    Un-Publish
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="pa-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-1">mdi-currency-usd</v-icon>
                  <div>
                    <span> Change Price</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="expPanelContent"
                color="grey lighten-3"
              >
                <v-row
                  class="pa-3 d-flex align-center justify-end"
                  style="gap: 12px"
                >
                  <v-text-field
                    v-model="bulkEditPriceChangeValue"
                    label="Price Change Value"
                    type="number"
                    prepend-icon="mdi-alpha-r"
                    @input="bulkEditPriceChangePercent = null"
                  ></v-text-field>

                  <v-text-field
                    v-model="bulkEditPriceChangePercent"
                    label="Price Change Percentage"
                    type="number"
                    append-icon="mdi-percent"
                    @input="bulkEditPriceChangeValue = null"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    @click="changeProductsPrice('increase')"
                    :disabled="
                      offline ||
                      savingSelectedProducts ||
                      ((!bulkEditPriceChangePercent ||
                        bulkEditPriceChangePercent === '') &&
                        (!bulkEditPriceChangeValue ||
                          bulkEditPriceChangeValue === ''))
                    "
                  >
                    Increase
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="changeProductsPrice('decrease')"
                    :disabled="
                      offline ||
                      savingSelectedProducts ||
                      ((!bulkEditPriceChangePercent ||
                        bulkEditPriceChangePercent === '') &&
                        (!bulkEditPriceChangeValue ||
                          bulkEditPriceChangeValue === ''))
                    "
                  >
                    Decrease
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="pa-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-1">mdi-sale</v-icon>
                  <div>
                    <span>Discount</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="expPanelContent"
                color="grey lighten-3"
              >
                <v-row
                  class="pa-3 d-flex align-center justify-end"
                  style="gap: 12px"
                >
                  <v-text-field
                    v-model="bulkEditDiscountPercent"
                    label="Discount Percentage"
                    type="number"
                    append-icon="mdi-percent"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    @click="discountProducts"
                    :disabled="
                      offline ||
                      savingSelectedProducts ||
                      !bulkEditDiscountPercent ||
                      bulkEditDiscountPercent === ''
                    "
                  >
                    Discount
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="pa-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-1">mdi-swap-horizontal</v-icon>
                  <div>
                    <span>Type</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="expPanelContent"
                color="grey lighten-3"
              >
                <v-row class="pa-3 d-flex align-center" style="gap: 12px">
                  <v-checkbox
                    v-model="bulkEditPhysicalProduct"
                    label="Physical Product"
                    @change="changeBulkPhysEcommSub('physical')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="bulkEditSubscriptionProduct"
                    label="Subscription Product"
                    @change="changeBulkPhysEcommSub('sub')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="bulkEditECommerceProduct"
                    label="E-Commerce Product"
                    @change="changeBulkPhysEcommSub('ecomm')"
                  >
                  </v-checkbox>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="changeProductsType"
                    :disabled="
                      offline ||
                      savingSelectedProducts ||
                      (!bulkEditPhysicalProduct &&
                        !bulkEditSubscriptionProduct &&
                        !bulkEditECommerceProduct)
                    "
                  >
                    Change Type
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          id="productsDataTable"
          :headers="headers"
          :items="products"
          :sort-desc.sync="sortDesc"
          :sort-by.sync="sortBy"
          :items-per-page.sync="productsDataTableRowsPerPage"
          @update:items-per-page="queryProducts"
          :page.sync="productsDataTablePageCount"
          @update:page="productsDataTablePageCountUpdate"
          :server-items-length="productsDataTableServerItemsLength"
          must-sort
          :loading="!productSubscriptionSynced && products?.length === 0"
          loading-text="Loading Products..."
          no-data-text="No Products found"
          show-select
          v-model="selectedProducts"
        >
          <template v-slot:[`item.featured`]="{ item }">
            <v-icon v-if="item.featured">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            {{ formatPrice(item.price) }}
          </template>
          <template v-slot:[`item.discountPercentage`]="{ item }">
            {{ formatPercentage(item.discountPercentage) }}
          </template>
          <template v-slot:[`item.categoryId`]="{ item }">
            {{ formatCategoryId(item.categoryId) }}
          </template>
          <template v-slot:[`item.publishDate`]="{ item }">
            {{ formatDateTime(item.publishDate) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDateTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.physical`]="{ item }">
            <v-icon v-if="item.physical">mdi-check</v-icon>
          </template>
          <template v-slot:[`item.eCommerceProduct`]="{ item }">
            <v-icon v-if="item.eCommerceProduct">mdi-check</v-icon>
          </template>
          <template v-slot:[`item.subscriptionProduct`]="{ item }">
            <v-icon v-if="item.subscriptionProduct">mdi-check</v-icon>
          </template>

          <template v-slot:[`item.missingFiles`]="{ item }">
            {{ getMissingFilesCount(item.id) }}
          </template>

          <template v-slot:[`item.files`]="{ item }">
            {{ getProductFileCount(item.id) }}
          </template>
          <template v-slot:[`item.urls`]="{ item }">
            {{ getProductURLCount(item.id) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              :disabled="offline || loading || savingSelectedProducts"
              @click="duplicateItem(item)"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline || loading || savingSelectedProducts"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
              :disabled="offline || loading || savingSelectedProducts"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-if="dialog" v-model="dialog" max-width="800px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.title"
                    label="Title"
                    :rules="formRules.title"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-checkbox
                    v-model="editedItem.featured"
                    label="Featured"
                  ></v-checkbox
                ></v-col>
                <v-col cols="6" sm="3">
                  <v-checkbox
                    v-model="editedItem.physical"
                    label="Physical Product"
                    @change="changePhysEcommSub('physical')"
                  ></v-checkbox
                ></v-col>
                <v-col cols="6" sm="3">
                  <v-text-field
                    v-model="editedItem.price"
                    label="Price"
                    type="number"
                    prefix="R"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field
                    v-model="editedItem.discountPercentage"
                    label="Discount"
                    type="number"
                    suffix="%"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field
                    :value="productVat"
                    label="Vat (15%)"
                    type="number"
                    prefix="R"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-dialog v-model="datetimeDialog" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedProductPublishDate"
                        readonly
                        label="Publish Date"
                        v-bind="{ ...attrs, ...$attrs }"
                        v-on="on"
                        clearable
                        @click:clear="updateProductPublishDateTime('clear')"
                      ></v-text-field>
                    </template>
                    <v-card class="t-datetime-picker white">
                      <v-toolbar height="36" color="primary" dark flat>
                        <v-tabs v-model="dateTimeTab" grow height="36">
                          <v-tabs-slider color="white"></v-tabs-slider>
                          <v-tab href="#date">
                            <v-icon>mdi-calendar</v-icon>
                          </v-tab>
                          <v-tab href="#time">
                            <v-icon>mdi-clock</v-icon>
                          </v-tab>
                          <v-btn
                            depressed
                            text
                            class="rounded-0"
                            @click="closeDateTimeDialog"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-tabs>
                      </v-toolbar>

                      <v-tabs-items v-model="dateTimeTab">
                        <v-tab-item value="date">
                          <v-date-picker
                            v-model="productPublishDate"
                            @change="updateProductPublishDateTime"
                            class="rounded-0"
                            @input="dateTimeTab = 'time'"
                            full-width
                          >
                          </v-date-picker>
                        </v-tab-item>
                        <v-tab-item value="time">
                          <v-time-picker
                            :key="dateTimeTab"
                            v-model="productPublishTime"
                            format="24hr"
                            class="rounded-0"
                            full-width
                            @change="updateProductPublishDateTime"
                          >
                          </v-time-picker>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-checkbox
                    v-model="editedItem.subscriptionProduct"
                    label="Subscription Product"
                    @change="changePhysEcommSub('sub')"
                  ></v-checkbox
                ></v-col>
                <v-col cols="6" sm="3">
                  <v-checkbox
                    v-model="editedItem.eCommerceProduct"
                    label="E-Commerce Product"
                    @change="changePhysEcommSub('ecomm')"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="editedItemComputedTags"
                    label="Tags"
                    multiple
                    chips
                    append-icon=""
                    :items="productTagNameArray"
                    deletable-chips
                    clearable
                  ></v-combobox>
                </v-col>
                <v-col cols="12">
                  <!-- <v-combobox
                    v-model="editedItemComputedCategory"
                    label="Category"
                    append-icon=""
                    :items="computedProductCategoryArray"
                    clearable
                  ></v-combobox> -->

                  <v-menu
                    v-model="editProductCategoriesMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :nudge-bottom="-12"
                    :rounded="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div @click="toggleEditProductMenu">
                        <v-combobox
                          class="productsSearchInputCSS pt-2"
                          :value="computedEditProductsCategoriesValue"
                          label="Categories"
                          dense
                          min-height="36px"
                          clearable
                          v-on="on"
                          readonly
                          @click:clear="editedItem.categoryId = ''"
                        ></v-combobox>
                      </div>
                    </template>
                    <v-card max-height="200px" class="overflow-y-auto">
                      <v-card-text>
                        <v-treeview
                          :items="categoryTree"
                          color="primary"
                          activatable
                          item-key="id"
                          @update:active="updateProductCategory"
                          :active="[editedItem.categoryId]"
                        ></v-treeview>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <span class="text-h6">Description</span>
                  <RichTextEditor
                    v-model="editedItem.description"
                    :datamodelId="editedItem.id"
                    s3Folder="Products"
                  >
                  </RichTextEditor>
                </v-col>
                <v-col cols="12">
                  <v-card class="px-3 pt-3">
                    <v-row
                      no-gutters
                      justify="space-between"
                      style="gap: 12px"
                      class="pb-3"
                    >
                      <span class="text-h6">Product Images</span>
                      <v-btn
                        color="primary"
                        @click="uploadThumbnailImage"
                        :disabled="offline || editedIndex === -1 || loading"
                      >
                        {{
                          editedIndex === -1
                            ? "Save product to add images"
                            : "Add Image"
                        }}
                      </v-btn>
                    </v-row>
                    <v-row
                      v-if="thumbnailImagesSrc?.length > 0"
                      no-gutters
                      class="thumbnailvrow"
                      style="margin-top: -12px"
                    >
                      <draggable
                        v-model="editedItem.thumbnails"
                        style="width: 100%"
                        class="d-flex flex-wrap"
                      >
                        <v-col
                          v-for="(
                            thumbnailKey, thumbnailIndex
                          ) in editedItem.thumbnails"
                          :key="thumbnailIndex"
                          cols="12"
                          sm="6"
                          md="4"
                          class="pa-3"
                        >
                          <div
                            @mouseenter="mouseOver(thumbnailIndex)"
                            @mouseleave="mouseOut"
                            class="d-flex justify-center align-center"
                            style="
                              position: relative;
                              border: thin solid;
                              border-radius: 4px;
                              height: 100%;
                              width: 100%;
                            "
                            :style="
                              productImgHoveringIndex === thumbnailIndex
                                ? 'border-color: #ffa9b8;'
                                : ''
                            "
                          >
                            <v-img
                              max-height="100%"
                              max-width="100%"
                              :src="getThumbnailImageSrc(thumbnailKey)"
                              cover
                              style="border-radius: 4px"
                            ></v-img>
                            <!-- <img
                              style="
                                max-width: 100%;
                                max-height: 100%;
                                object-fit: cover;
                                border-radius: 8px;
                              "
                              :src="getThumbnailImageSrc(thumbnailKey)"
                            /> -->

                            <div
                              v-if="
                                productImgHoveringIndex === thumbnailIndex &&
                                editedIndex !== -1
                              "
                              style="position: absolute; top: 0; right: 0"
                            >
                              <v-menu offset-x v-model="isProductImgMenuOpen">
                                <template
                                  v-slot:activator="{ on: menu, attrs }"
                                >
                                  <v-btn v-bind="attrs" icon v-on="{ ...menu }">
                                    <v-icon>mdi-cog</v-icon>
                                  </v-btn>
                                </template>
                                <div
                                  class="d-flex flex-column"
                                  style="background-color: white"
                                  @mouseleave="mouseOutMenu"
                                >
                                  <v-btn
                                    text
                                    color="red"
                                    @click="
                                      deleteProductThumbnail(thumbnailKey)
                                    "
                                  >
                                    Remove
                                  </v-btn>
                                </div>
                              </v-menu>
                            </div>
                          </div>
                        </v-col>
                      </draggable>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="px-3 pt-3">
                    <v-row
                      no-gutters
                      justify="space-between"
                      style="gap: 12px"
                      class="pb-3"
                    >
                      <span class="text-h6">Product Content</span>

                      <div
                        class="d-flex flex-row align-center"
                        style="gap: 12px"
                      >
                        <span
                          v-if="uploadingFiles?.length > 0"
                          class="primary--text"
                        >
                          {{ uploadingFilesText }}
                        </span>

                        <v-btn
                          color="primary"
                          @click="uploadProductContent"
                          :disabled="offline || loading || editedIndex === -1"
                        >
                          {{
                            editedIndex === -1
                              ? "Save product to add files"
                              : "Add Files"
                          }}
                        </v-btn>
                      </div>
                    </v-row>
                    <v-row
                      v-if="editedIndex > -1"
                      class="thumbnailvrow"
                      style="margin-top: -12px"
                    >
                      <v-col
                        cols="12"
                        v-if="productContentFileKeys?.length > 0"
                      >
                        <v-combobox
                          v-model="productContentFileKeys"
                          multiple
                          chips
                          label="Files"
                          appendIcon=""
                          deletable-chips
                          @change="changeEditedItemProductContent"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              class="text-wrap py-1"
                              :color="isFileKeyMissing(item) ? 'red' : ''"
                              :dark="isFileKeyMissing(item)"
                              style="height: fit-content"
                              close
                              @click:close="removeProductContentFileKey(index)"
                              :disabled="offline || loading"
                              close-icon="mdi-delete"
                              >{{
                                (isFileKeyMissing(item) ? "(MISSING) " : "") +
                                item
                              }}</v-chip
                            >
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="productContentUrls"
                          label="Videos"
                          hint='Eg. Vimeo: "1031522389", YouTube: "c_GorHGJ9Qg"'
                          multiple
                          chips
                          appendIcon=""
                          :rules="formRules.validateVideoId"
                          deletable-chips
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" v-if="editedItem.physical">
                  <v-card class="px-3 pt-3">
                    <v-row
                      no-gutters
                      justify="space-between"
                      style="gap: 12px"
                      class="pb-3"
                    >
                      <span class="text-h6">Questions</span>
                      <v-btn
                        color="primary"
                        @click="addQuestion"
                        :disabled="offline || loading"
                      >
                        Add Question
                      </v-btn>
                    </v-row>
                    <v-row
                      v-if="editedItem.questions?.length > 0"
                      no-gutters
                      class="plannerPanelTopBorder"
                    >
                      <v-expansion-panels
                        class="mb-3"
                        style="justify-content: left"
                        v-model="expQuestionPanels"
                      >
                        <draggable
                          v-model="editedItem.questions"
                          style="width: 100%"
                        >
                          <v-expansion-panel
                            v-for="(question, index) in editedItem.questions"
                            :key="index"
                          >
                            <v-expansion-panel-header class="pa-2">
                              <div class="d-flex align-center">
                                <v-icon class="mr-1">
                                  mdi-drag-vertical
                                </v-icon>
                                <div style="text-wrap: nowrap">
                                  <span
                                    >Question{{
                                      question.label && question.label !== ""
                                        ? ":&nbsp;"
                                        : ""
                                    }}</span
                                  >
                                </div>
                                <div>
                                  <span>
                                    {{
                                      question.label && question.label !== ""
                                        ? question.label
                                        : ""
                                    }}</span
                                  >
                                </div>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                              id="expPanelContent"
                              color="grey lighten-3"
                            >
                              <v-row class="pt-3">
                                <v-col cols="12" sm="6" md="4" class="py-0">
                                  <v-text-field
                                    v-model="question.label"
                                    label="Label"
                                    :rules="formRules.label"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" class="py-0">
                                  <v-select
                                    v-model="question.type"
                                    label="Type"
                                    :rules="formRules.type"
                                    required
                                    :items="formFieldTypes"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" class="py-0">
                                  <v-text-field
                                    v-model="question.placeholder"
                                    label="Placeholder"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" class="py-0">
                                  <v-text-field
                                    v-model="question.hint"
                                    label="Hint"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" class="py-0">
                                  <v-checkbox
                                    v-model="question.required"
                                    label="Required"
                                  ></v-checkbox>
                                </v-col>
                                <v-col
                                  v-if="
                                    question.type === 'Select' ||
                                    question.type === 'Multi Select' ||
                                    question.type === 'Radio' ||
                                    question.type === 'Checkbox'
                                  "
                                  cols="12"
                                  class="py-0"
                                >
                                  <v-combobox
                                    v-model="question.items"
                                    :label="
                                      question.type === 'Select' ||
                                      question.type === 'Multi Select'
                                        ? 'Select Values'
                                        : question.type === 'Radio'
                                        ? 'Radio Values'
                                        : question.type === 'Checkbox'
                                        ? 'Checkbox Values'
                                        : ''
                                    "
                                    :error-messages="
                                      comboboxErrors(
                                        question.items,
                                        question.type
                                      )
                                    "
                                    required
                                    multiple
                                    chips
                                    deletable-chips
                                    clearable
                                    lazy-validation
                                  ></v-combobox>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-spacer />
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="red"
                                      fab
                                      small
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="removeQuestion(index)"
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete Field</span>
                                </v-tooltip>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </draggable>
                      </v-expansion-panels>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="editedIndex === -1 || productSaved"
              @click="cancelWarning"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close" :disabled="loading">
              Close
            </v-btn>
            <v-btn
              :disabled="!valid || productSaved || offline || loading"
              :loading="loading"
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogCancel" max-width="500px">
      <v-card>
        <v-card-title class="justify-center" style="word-break: break-word"
          >Are you sure you want to discard the changes made to this
          product?</v-card-title
        >
        <v-card-actions>
          <v-btn
            color="secondary"
            class="black--text"
            :disabled="cancelingChanges"
            @click="closeCancel"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="cancelingChanges" @click="cancel"
            >YES</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this product?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="deletingItem" @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :loading="deletingItem"
            :disabled="deletingItem"
            @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogProductContentDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this content?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="loading"
            @click="closeProductContentDelete"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="deleteProductContent"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogManageProductTags" max-width="800px">
      <v-card>
        <v-row no-gutters class="align-center">
          <v-card-title class="text-h5"> Manage Tags </v-card-title>
          <v-spacer />
          <v-btn
            color="primary"
            class="mx-4"
            @click="createProductTag"
            :disabled="offline"
          >
            New Tag
          </v-btn>
        </v-row>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :footer-props="{
                    showFirstLastPage: true,
                  }"
                  id="tagsDataTable"
                  :headers="tagHeaders"
                  :items="syncedProductTagArray"
                  :sort-desc.sync="tagSortDesc"
                  :sort-by.sync="tagSortBy"
                  must-sort
                  loading-text="Loading Tags..."
                  no-data-text="No Tags found"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editProductTag(item)"
                      :disabled="offline"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteProductTag(item)"
                      :disabled="offline"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeManageProductTags">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogEditProductTag" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="saveProductTag">
          <v-card-title>
            <span class="text-h5">{{ tagFormTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedProductTagItem.name"
                    label="Tag Name"
                    :rules="formRules.tagName"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              class="black--text"
              @click="closeEditProductTag"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveProductTag">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogDeleteProductTag" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this tag?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="deletingItem"
            @click="closeDeleteProductTag"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :loading="deletingItem"
            :disabled="deletingItem"
            @click="deleteProductTagConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialogManageProductCategories"
      max-width="800px"
    >
      <v-card>
        <v-row no-gutters class="align-center">
          <v-card-title class="text-h5"> Manage Categories </v-card-title>
          <v-spacer />
          <v-btn
            color="primary"
            class="mx-4"
            @click="createProductCategory"
            :disabled="offline"
          >
            New Category
          </v-btn>
        </v-row>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :footer-props="{
                    showFirstLastPage: true,
                  }"
                  id="categoriesDataTable"
                  :headers="categoryHeaders"
                  :items="syncedProductCategoryArray"
                  :sort-desc.sync="categorySortDesc"
                  :sort-by.sync="categorySortBy"
                  must-sort
                  loading-text="Loading Categories..."
                  no-data-text="No Categories found"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editProductCategory(item)"
                      :disabled="offline"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteProductCategory(item)"
                      :disabled="offline"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeManageProductCategories"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-if="dialogEditProductCategory"
      v-model="dialogEditProductCategory"
      max-width="500px"
    >
      <v-card>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="saveProductCategory"
        >
          <v-card-title>
            <span class="text-h5">{{ categoryFormTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedProductCategoryItem.name"
                    label="Category Name"
                    :rules="formRules.categoryName"
                    required
                    @input="generateSlug"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedProductCategoryItem.slug"
                    label="Category Slug"
                    readonly
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedProductCategoryItem.grade"
                    :items="gradeArray"
                    label="Grade"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <!-- <v-combobox
                    v-model="editedProductCategoryItemComputedParent"
                    label="Parent Category"
                    append-icon=""
                    :items="computedProductCategoryParentArray"
                    clearable
                  ></v-combobox> -->

                  <v-menu
                    v-model="editCategoryParentMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :nudge-bottom="-12"
                    :rounded="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div @click="toggleEditCategoryParentMenu">
                        <v-combobox
                          class="productsSearchInputCSS pt-2"
                          :value="computedEditCategoryParentValue"
                          label="Parent Category"
                          dense
                          min-height="36px"
                          clearable
                          v-on="on"
                          readonly
                          @click:clear="editedProductCategoryItem.parentId = ''"
                        ></v-combobox>
                      </div>
                    </template>
                    <v-card max-height="200px" class="overflow-y-auto">
                      <v-card-text>
                        <v-treeview
                          :items="categoryParentTree"
                          color="primary"
                          activatable
                          item-key="id"
                          @update:active="updateCategoryParent"
                          :active="[editedProductCategoryItem.parentId]"
                        ></v-treeview>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              class="black--text"
              @click="closeEditProductCategory"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveProductCategory">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialogDeleteProductCategory"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this category?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="deletingItem"
            @click="closeDeleteProductCategory"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :loading="deletingItem"
            :disabled="deletingItem"
            @click="deleteProductCategoryConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProductContentVerify" max-width="fit-content">
      <v-card width="fit-content">
        <v-card-title
          v-if="failedProductsContent.length === 0"
          class="justify-center"
          >All files exist
          <v-icon color="success" class="slide-x-transition">
            mdi-check-circle
          </v-icon></v-card-title
        >
        <div v-else>
          <v-card-title class="justify-center"
            >The following products are missing files</v-card-title
          >

          <ul class="mx-3">
            <li
              v-for="(product, index) in failedProductsContent"
              :key="'product-' + index"
            >
              <v-card-text class="pa-0">{{ product.title }}</v-card-text>
            </li>
          </ul>
          <!-- <div
            v-for="(product, index) in failedProductsContent"
            :key="'product-' + index"
          >
            <v-card-text>{{ product.title }}</v-card-text>

            <v-card-text
              v-for="(file, fileIndex) in product.fileKeys"
              :key="'product-' + index + '-file-' + fileIndex"
            >
              {{ file }}
            </v-card-text>
          </div> -->
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeProductContentVerify"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  DataStore,
  Storage,
  SortDirection,
  // , Predicates
} from "aws-amplify";
import { Product, ProductContent, ProductTag, ProductCategory } from "@/models";
import _ from "lodash";
import draggable from "vuedraggable";
import RichTextEditor from "@/components/global-components/rich-text-editor/RichTextEditor.vue";
import axios from "axios";
// import { getUrl } from "aws-amplify/storage";

export default {
  name: "AdminHolidays",
  components: {
    draggable,
    RichTextEditor,
  },
  data: () => ({
    initiallyLoaded: false,

    isProductImgMenuOpen: false,
    productImgHoveringIndex: -1,

    expQuestionPanels: 0,

    productSubscription: null,
    productSubscriptionSynced: false,
    syncedProductArray: [],

    selectedProducts: [],
    savingSelectedProducts: false,

    //Bulk Edit ------------------
    expBulkEditPanels: 0,

    bulkEditPublishDate: "",
    bulkEditPublishTime: "",
    bulkEditPublishDateTime: null,
    bulkEditDateTimeDialog: false,
    bulkEditDateTimeTab: "date",

    bulkEditPriceChangePercent: null,
    bulkEditPriceChangeValue: null,

    bulkEditDiscountPercent: null,

    bulkEditSubscriptionProduct: false,
    bulkEditECommerceProduct: false,
    bulkEditPhysicalProduct: false,
    //Bulk Edit-END ------------------

    productContentProductId: "",
    productContentSubscription: null,
    syncedProductContentArray: [],

    uploadingFiles: [],

    productContentDatamodel: null,
    productContentFileKeys: [],
    productContentUrls: [],
    loading: false,

    valid: false,

    datetimeDialog: false,
    dateTimeTab: "date",
    productPublishDate: "",
    productPublishTime: "",

    dialog: false,
    dialogDelete: false,
    dialogCancel: false,
    dialogProductContentDelete: false,
    dialogProductContentVerify: false,

    failedProductsContent: [],
    productsContentCheckProgress: null,

    //Tags ------------------
    dialogManageProductTags: false,
    dialogEditProductTag: false,
    dialogDeleteProductTag: false,

    syncedProductTagArray: [],
    productTagNameArray: [],
    tagSubscription: null,

    editedProductTagIndex: -1,
    editedProductTagItem: {
      name: "",
    },
    defaultProductTagItem: {
      name: "",
    },

    tagHeaders: [
      { text: "Name", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    tagSortBy: "name",
    tagSortDesc: true,
    //Tags-END ------------------

    //Categories ------------------
    dialogManageProductCategories: false,
    dialogEditProductCategory: false,
    dialogDeleteProductCategory: false,

    syncedProductCategoryArray: [],
    // productCategoryNameArray: [],
    categorySubscription: null,

    gradeArray: [
      "R",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],

    editedProductCategoryIndex: -1,
    editedProductCategoryItem: {
      id: "",
      name: "",
      slug: "",
      grade: "",
      parentId: "",
    },
    defaultProductCategoryItem: {
      id: "",
      name: "",
      slug: "",
      grade: "",
      parentId: "",
    },

    categoryHeaders: [
      { text: "Name", value: "name" },
      { text: "Grade", value: "grade" },
      { text: "Slug", value: "slug" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    categorySortBy: "name",
    categorySortDesc: true,
    //Categories-END ------------------

    deletingItem: false,
    cancelingChanges: false,

    sortBy: "title",
    sortDesc: true,
    productsDataTableSearch: "",
    productsDataTableSearchTags: [],
    productsDataTableSearchCategories: [],
    productsDataTablePageCount: 1,
    productsDataTableRowsPerPage: 10,
    productsDataTableServerItemsLength: 0,
    searchProductCategoriesMenu: false,
    editProductCategoriesMenu: false,
    editCategoryParentMenu: false,

    searchPhysicalProduct: false,
    searchECommerceProduct: false,
    searchSubscriptionProduct: false,
    searchMissingProductContent: false,

    headers: [
      { text: "Title", value: "title" },
      { text: "Price", value: "price" },
      { text: "Featured", value: "featured" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    products: [],
    thumbnailImagesSrc: [],

    editedIndex: -1,

    unEditedProduct: {},

    editedItem: {
      title: "",
      description: "",
      price: null,
      discountPercentage: null,
      thumbnails: [],
      tags: [],
      questions: [],
      publishDate: null,
      featured: false,
      physical: false,
      subscriptionProduct: false,
      eCommerceProduct: false,
      searchField: "",
      categoryId: "",
    },
    defaultItem: {
      title: "",
      description: "",
      price: null,
      discountPercentage: null,
      thumbnails: [],
      tags: [],
      questions: [],
      publishDate: null,
      featured: false,
      physical: false,
      subscriptionProduct: false,
      eCommerceProduct: false,
      searchField: "",
      categoryId: "",
    },

    formRules: {
      title: [(v) => !!v || "Title is required"],
      tagName: [(v) => !!v || "Tag name is required"],
      categoryName: [(v) => !!v || "Category name is required"],
      hint: [(v) => !!v || "Hint is required"],
      placeholder: [(v) => !!v || "Placeholder is required"],
      type: [(v) => !!v || "Type is required"],
      label: [(v) => !!v || "Label is required"],
      validateVideoId: [
        // (v) =>
        //   (Array.isArray(v) && v.length > 0) ||
        //   "At least one video ID is required", // Ensure array is not empty
        (v) =>
          v?.every((id) => {
            const youtubeRegex = /^[a-zA-Z0-9_-]{11}$/; // YouTube ID regex
            const vimeoRegex = /^[0-9]+$/; // Vimeo ID regex
            return youtubeRegex.test(id) || vimeoRegex.test(id);
          }) || "One or more video IDs are invalid. Use YouTube or Vimeo IDs.",
      ],
      // categorySlug: [
      //   (v) => !!v || "Category slug is required",
      //   (v) => /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(v) || "Invalid slug format",
      // ],
    },

    formFieldTypes: [
      "Text",
      "Textarea",
      "Select",
      "Multi Select",
      "Radio",
      "Checkbox",
    ],
    productSearchExcludeFields: [
      "id",
      "fileKeys",
      "thumbnails",
      "searchField",
      "categoryId",
      "createdAt",
      "featured",
      "physical",
      "subscriptionProduct",
      "eCommerceProduct",
      "publishDate",
      "updatedAt",
      "_version",
      "_lastChangedAt",
      "_deleted",
    ],
  }),

  async mounted() {
    try {
      this.headers = [
        {
          text: "Title",
          value: "title",
        },

        { text: "Featured", value: "featured" },
        {
          text: "Price",
          value: "price",
        },
        {
          text: "Discount",
          value: "discountPercentage",
        },
        {
          text: "Category",
          value: "categoryId",
          sortable: false,
        },
        {
          text: "Publish Date",
          value: "publishDate",
        },
        {
          text: "Modified Date",
          value: "updatedAt",
        },
        {
          text: "Physical",
          value: "physical",
        },
        {
          text: "E-Com",
          value: "eCommerceProduct",
        },
        {
          text: "Subscription",
          value: "subscriptionProduct",
        },
        {
          text: "Files",
          value: "files",
          sortable: false,
        },
        {
          text: "Videos",
          value: "urls",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ];

      this.tagHeaders = [
        {
          text: "Tag Name",
          value: "name",
          // sort: (a, b) => this.customSortProductTagName(a, b),
        },

        { text: "Actions", value: "actions", sortable: false },
      ];

      this.categoryHeaders = [
        {
          text: "Category Name",
          value: "name",
          // sort: (a, b) => this.customSortProductCategoryString(a, b),
        },
        {
          text: "Grade",
          value: "grade",
          sortable: false,
          // sort: (a, b) => this.customSortProductCategoryString(a, b),
        },
        {
          text: "Slug",
          value: "slug",
          // sort: (a, b) => this.customSortProductCategoryString(a, b),
        },

        { text: "Actions", value: "actions", sortable: false },
      ];

      this.productContentSubscription = DataStore.observeQuery(
        ProductContent
        // (c) => c.productId("eq", this.productContentProductId)
      ).subscribe((snapshot) => {
        const { isSynced, items } = snapshot;
        if (
          isSynced &&
          items.length > 0 &&
          !_.isEqual(items, this.syncedProductContentArray)
        ) {
          this.syncedProductContentArray = items;

          if (this.productContentProductId !== "") {
            const newProductContentObj = items.find(
              (content) => content.productId === this.productContentProductId
            );
            if (newProductContentObj)
              this.getProductContent(newProductContentObj);
          }
        }
      });

      this.productSubscription = DataStore.observeQuery(Product).subscribe(
        async (snapshot) => {
          const { isSynced, items } = snapshot;
          if (isSynced) {
            this.syncedProductArray = items;

            const search = this.productsDataTableSearch.split(" ");

            const filterObj = {
              page: this.productsDataTablePageCount - 1,
              limit: this.productsDataTableRowsPerPage,
              sort: (s) =>
                s[this.sortBy](
                  this.sortDesc
                    ? SortDirection.DESCENDING
                    : SortDirection.ASCENDING
                ),
            };

            if (this.productsDataTableRowsPerPage === -1) {
              delete filterObj.page;
              delete filterObj.limit;
            }

            const filteredItems = await DataStore.query(
              Product,
              (product) =>
                product.and((f) => [
                  this.productsDataTableSearchTags.every((tag) =>
                    f.tags("contains", tag)
                  ),
                  f.or((f) =>
                    this.productsDataTableSearchCategories.reduce(
                      (c, id) => c.categoryId("eq", id),
                      f
                    )
                  ),
                  search
                    .map((str) => str.toLowerCase())
                    .every((string) => f.searchField("contains", string)),

                  this.searchECommerceProduct
                    ? f.eCommerceProduct("eq", true)
                    : f,
                  this.searchPhysicalProduct ? f.physical("eq", true) : f,
                  this.searchSubscriptionProduct
                    ? f.subscriptionProduct("eq", true)
                    : f,
                ]),
              filterObj
            );

            // Deep comparison of filteredItems with productArray
            if (!_.isEqual(filteredItems, this.products)) {
              this.queryProducts();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );

      this.tagSubscription = DataStore.observeQuery(ProductTag).subscribe(
        async (snapshot) => {
          const { isSynced, items } = snapshot;
          if (isSynced && !_.isEqual(items, this.syncedProductTagArray)) {
            this.syncedProductTagArray = items;
            this.productTagNameArray = items.map((tag) => {
              return tag.name;
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );

      this.categorySubscription = DataStore.observeQuery(
        ProductCategory
      ).subscribe(
        async (snapshot) => {
          const { isSynced, items } = snapshot;
          if (isSynced && !_.isEqual(items, this.syncedProductCategoryArray)) {
            this.syncedProductCategoryArray = items;
            // this.productCategoryNameArray = items.map((category) => {
            //   return category.name;
            // });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),

    checkingProductsProgressText() {
      const progress = this.productsContentCheckProgress;

      if (progress.total > 0) {
        if (progress.current >= progress.total) {
          return `Checked products ${progress.total} of ${progress.total} - DONE`;
        } else {
          return `Checking products ${progress.current} of ${progress.total} - ${progress.percentage}%`;
        }
      } else {
        return "";
      }
    },

    uploadingFilesText() {
      //like 'Uploading files i of .length - percentage%' one at a time untill 'Uploading files .length of .length - 100%'

      if (this.uploadingFiles.length > 0) {
        const firstFileStillUploadingIndex = this.uploadingFiles.findIndex(
          (file) => file.percentageUploaded < 100
        );

        if (firstFileStillUploadingIndex === -1) {
          return `Uploaded files ${this.uploadingFiles.length} of ${
            this.uploadingFiles.length
          } - ${this.loading ? "Completing" : "DONE"}`;
        } else {
          return `Uploading files ${firstFileStillUploadingIndex + 1} of ${
            this.uploadingFiles.length
          } - ${
            this.uploadingFiles[firstFileStillUploadingIndex].percentageUploaded
          }%`;
        }
      } else {
        return "";
      }
    },

    categoryParentTree() {
      // Assuming syncedProductCategoryArray contains flat array of categories
      // with parentId indicating hierarchy, transform it into a tree-like structure
      return this.buildCategoryTree(
        this.syncedProductCategoryArray,
        this.editedProductCategoryItem.id
          ? this.editedProductCategoryItem.id
          : ""
      );
    },

    categoryTree() {
      // Assuming syncedProductCategoryArray contains flat array of categories
      // with parentId indicating hierarchy, transform it into a tree-like structure
      return this.buildCategoryTree(this.syncedProductCategoryArray);
    },

    computedProductCategoryArray: {
      get: function () {
        return this.syncedProductCategoryArray.map((category) => {
          let parentCategories = [];

          if (category.parentId) {
            let currentCategory = category;
            while (currentCategory.parentId) {
              const parentCategory = this.syncedProductCategoryArray.find(
                (category) => category.id === currentCategory.parentId
              );
              if (parentCategory) {
                parentCategories.unshift(parentCategory.name);
                currentCategory = parentCategory;
              } else {
                break;
              }
            }
          }

          return parentCategories.length > 0
            ? parentCategories.join("/") + "/" + category.name
            : category.name;
        });
      },
    },

    computedEditProductsCategoriesValue: {
      get: function () {
        const category = this.syncedProductCategoryArray.find(
          (category) => category.id === this.editedItem.categoryId
        );

        if (category) {
          let parentCategories = [];

          if (category.parentId) {
            let currentCategory = category;
            while (currentCategory.parentId) {
              const parentCategory = this.syncedProductCategoryArray.find(
                (category) => category.id === currentCategory.parentId
              );
              if (parentCategory) {
                parentCategories.unshift(parentCategory.name);
                currentCategory = parentCategory;
              } else {
                break;
              }
            }
          }

          return parentCategories.length > 0
            ? parentCategories.join("/") + "/" + category.name
            : category.name;
        } else return "";
      },
    },

    editedItemComputedCategory: {
      get: function () {
        return [this.editedItem.categoryId];
      },
    },

    // editedItemComputedCategory: {
    //   get: function () {
    //     const category = this.syncedProductCategoryArray.find(
    //       (category) => category.id === this.editedItem.categoryId
    //     );

    //     if (category) {
    //       let parentCategories = [];

    //       if (category.parentId) {
    //         let currentCategory = category;
    //         while (currentCategory.parentId) {
    //           const parentCategory = this.syncedProductCategoryArray.find(
    //             (category) => category.id === currentCategory.parentId
    //           );
    //           if (parentCategory) {
    //             parentCategories.unshift(parentCategory.name);
    //             currentCategory = parentCategory;
    //           } else {
    //             break;
    //           }
    //         }
    //       }

    //       return parentCategories.length > 0
    //         ? parentCategories.join("/") + "/" + category.name
    //         : category.name;
    //     } else return "";
    //   },
    //   set: function (newValue) {
    //     if (newValue) {
    //       let category = null;
    //       let newValueParts = newValue.split("/");
    //       for (let i = newValueParts.length - 1; i >= 0; i--) {
    //         const newCategory = newValueParts.slice(i).join("/");
    //         category = this.syncedProductCategoryArray.find(
    //           (category) => category.name === newCategory
    //         );
    //         if (category) {
    //           this.editedItem.categoryId = category.id;
    //           break;
    //         }
    //       }
    //       // If no category found, set categoryId to empty
    //       if (!category) {
    //         this.editedItem.categoryId = "";
    //       }
    //     } else {
    //       this.editedItem.categoryId = "";
    //     }
    //   },
    // },

    computedProductCategoryParentArray: {
      get: function () {
        const categories = this.syncedProductCategoryArray.filter(
          (category) => {
            if (this.editedProductCategoryItem.id) {
              // If editedProductCategoryItem has an ID, filter out categories with the same ID
              if (category.id === this.editedProductCategoryItem.id) {
                return false;
              }

              // Check if any parent category's ID matches the current category's ID
              let parentCategoryId = category.parentId;
              while (parentCategoryId) {
                const parentCategory = this.syncedProductCategoryArray.find(
                  (cat) => cat.id === parentCategoryId
                );
                if (parentCategory) {
                  if (parentCategory.id === this.editedProductCategoryItem.id) {
                    return false;
                  }
                  parentCategoryId = parentCategory.parentId;
                } else {
                  break;
                }
              }

              return true;
            } else {
              // If editedProductCategoryItem has no ID, return all categories
              return true;
            }
          }
        );

        return categories.map((category) => {
          let parentCategories = [];

          if (category.parentId) {
            let currentCategory = category;
            while (currentCategory.parentId) {
              const parentCategory = this.syncedProductCategoryArray.find(
                (category) => category.id === currentCategory.parentId
              );
              if (parentCategory) {
                parentCategories.unshift(parentCategory.name);
                currentCategory = parentCategory;
              } else {
                break;
              }
            }
          }

          return parentCategories.length > 0
            ? parentCategories.join("/") + "/" + category.name
            : category.name;
        });
      },
    },

    computedEditCategoryParentValue: {
      get: function () {
        const category = this.syncedProductCategoryArray.find(
          (category) => category.id === this.editedProductCategoryItem.parentId
        );

        if (category) {
          let parentCategories = [];

          if (category.parentId) {
            let currentCategory = category;
            while (currentCategory.parentId) {
              const parentCategory = this.syncedProductCategoryArray.find(
                (category) => category.id === currentCategory.parentId
              );
              if (parentCategory) {
                parentCategories.unshift(parentCategory.name);
                currentCategory = parentCategory;
              } else {
                break;
              }
            }
          }

          return parentCategories.length > 0
            ? parentCategories.join("/") + "/" + category.name
            : category.name;
        } else return "";
      },
      // set: function (newValue) {
      //   if (newValue) {
      //     let parentCategory = null;
      //     let newValueParts = newValue.split("/");
      //     for (let i = newValueParts.length - 1; i >= 0; i--) {
      //       const newCategory = newValueParts.slice(i).join("/");
      //       parentCategory = this.syncedProductCategoryArray.find(
      //         (category) => category.name === newCategory
      //       );
      //       if (parentCategory) {
      //         this.editedProductCategoryItem.parentId = parentCategory.id;
      //         break;
      //       }
      //     }
      //     // If no parent category found, set parent id to empty
      //     if (!parentCategory) {
      //       this.editedProductCategoryItem.parentId = "";
      //     }
      //   } else {
      //     this.editedProductCategoryItem.parentId = "";
      //   }
      // },
    },

    editedItemComputedTags: {
      get: function () {
        const editedItemTags = this.editedItem.tags
          ? this.editedItem.tags
              .map((tagId) => {
                const productTag = this.syncedProductTagArray.find(
                  (tag) => tag.id === tagId
                );

                if (productTag) return productTag.name;
              })
              .filter((tag) => tag !== undefined)
          : [];
        return editedItemTags;
      },
      set: async function (newValue) {
        const updatedTags = [];
        for (const tag of newValue) {
          if (typeof tag === "string") {
            // If tag is a string, it's a new tag
            const existingTag = this.syncedProductTagArray.find(
              (t) => t.name === tag
            );
            if (existingTag) {
              // If tag already exists, push its ID
              updatedTags.push(existingTag.id);
            } else {
              // If tag doesn't exist, create a new ProductTag model
              try {
                const newTag = await DataStore.save(
                  new ProductTag({ name: tag })
                );
                this.productTagNameArray.push(newTag.name);
                updatedTags.push(newTag.id);
              } catch (error) {
                console.error("Error saving new tag:", error);
              }
            }
          }
        }

        this.editedItem.tags = updatedTags;
      },
    },

    productsSearchTags: {
      get: function () {
        let tags = [];
        _.forEach(this.productsDataTableSearchTags, (tagId) => {
          const productTag = _.find(this.syncedProductTagArray, { id: tagId });
          if (productTag) {
            tags.push(productTag);
          }
        });
        return tags;
      },
      set: function (newValue) {
        this.productsDataTableSearchTags = newValue;
      },
    },

    computedProductsSearchCategories: {
      get: function () {
        let categories = [];
        _.forEach(this.productsDataTableSearchCategories, (categoryId) => {
          const category = _.find(this.syncedProductCategoryArray, {
            id: categoryId,
          });
          if (category) {
            let parentCategories = [];

            if (category.parentId) {
              let currentCategory = category;
              while (currentCategory.parentId) {
                const parentCategory = this.syncedProductCategoryArray.find(
                  (category) => category.id === currentCategory.parentId
                );
                if (parentCategory) {
                  parentCategories.unshift(parentCategory.name);
                  currentCategory = parentCategory;
                } else {
                  break;
                }
              }
            }

            categories.push(
              parentCategories.length > 0
                ? parentCategories.join("/") + "/" + category.name
                : category.name
            );
          }
        });
        return categories;
      },
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },

    tagFormTitle() {
      return this.editedProductTagIndex === -1 ? "New Tag" : "Edit Tag";
    },

    categoryFormTitle() {
      return this.editedProductCategoryIndex === -1
        ? "New Category"
        : "Edit Category";
    },

    formattedProductPublishDate() {
      return this.editedItem.publishDate
        ? new Date(this.editedItem.publishDate).toLocaleString()
        : "";
    },

    formattedBulkEditPublishDate() {
      return this.bulkEditPublishDateTime
        ? new Date(this.bulkEditPublishDateTime).toLocaleString()
        : "";
    },

    productSaved() {
      let urlsEqual = true;
      if (this.productContentDatamodel?.urls?.length > 0) {
        urlsEqual = _.isEqual(
          this.productContentUrls,
          this.productContentDatamodel.urls
        );
      } else if (this.productContentUrls.length > 0) {
        urlsEqual = false;
      }
      const equal = _.isEqual(this.editedItem, this.unEditedProduct);
      return equal && urlsEqual;
    },

    productVat() {
      return this.editedItem.price
        ? Number.parseFloat(
            (this.editedItem.price / (1 + 0.15) - this.editedItem.price) * -1
          ).toFixed(2)
        : 0;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogManageProductTags(val) {
      val || this.closeManageProductTags();
    },

    dialogEditProductTag(val) {
      val || this.closeEditProductTag();
    },

    dialogDeleteProductTag(val) {
      val || this.closeDeleteProductTag();
    },

    dialogManageProductCategories(val) {
      val || this.closeManageProductCategories();
    },

    dialogEditProductCategory(val) {
      val || this.closeEditProductCategory();
    },

    dialogDeleteProductCategory(val) {
      val || this.closeDeleteProductCategory();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogProductContentVerify(val) {
      val || this.closeProductContentVerify();
    },

    sortDesc() {
      this.queryProducts();
    },

    sortBy() {
      this.queryProducts();
    },
  },

  methods: {
    // Helper function to recursively collect category IDs
    collectCategoryIds(categoryId) {
      let ids = [];

      // Helper function to traverse categories
      function traverseCategories(currentId) {
        // Add the current category ID to the list
        ids.push(currentId);
        // Find all children whose parentId matches currentId
        const children = this.syncedProductCategoryArray.filter(
          (cat) => cat.parentId === currentId
        );
        // Recursively traverse each child
        children.forEach((child) => traverseCategories.call(this, child.id));
      }

      traverseCategories.call(this, categoryId);

      return ids;
    },

    removeProductContentFileKey(index) {
      this.productContentFileKeys.splice(index, 1);

      this.changeEditedItemProductContent();
    },

    getMissingFilesCount(productId) {
      const failedProduct = this.failedProductsContent.find(
        (item) => item.id === productId
      );
      return failedProduct ? failedProduct.fileKeys.length : 0; // Return 0 if no missing files
    },

    // Method to check if a file key should be colored red
    isFileKeyMissing(fileKey) {
      // console.log("this.editedItem.id", this.editedItem.id);
      const failedProduct = this.failedProductsContent.find(
        (product) => product.id === this.editedItem.id
      );
      // console.log("failedProduct", failedProduct);
      return failedProduct && failedProduct.fileKeys.includes(fileKey);
    },

    async checkIfProductContentExists() {
      this.loading = true;
      this.failedProductsContent = [];

      const currentDateTime = new Date().toISOString();

      // const mainCategoryId = this.productsDataTableSearchCategories[0];
      // const selectedCategoryIds = this.collectCategoryIds(mainCategoryId);

      const productsToCheck = this.syncedProductArray;
      // .filter((product) =>
      //   selectedCategoryIds.includes(product.categoryId)
      // );

      // console.log("productsToCheck", productsToCheck);

      this.productsContentCheckProgress = {
        total: productsToCheck.length - 1,
        current: 0,
        percentage: 0,
      };

      const batchSize = 50;

      const updateProgress = (increment = 1) => {
        this.productsContentCheckProgress.current += increment;
        this.productsContentCheckProgress.percentage = Math.round(
          (this.productsContentCheckProgress.current /
            this.productsContentCheckProgress.total) *
            100
        );
      };

      for (
        let batchStart = 0;
        batchStart < productsToCheck.length;
        batchStart += batchSize
      ) {
        const batch = productsToCheck.slice(batchStart, batchStart + batchSize);
        const fileKeysToCheck = [];

        batch.forEach((product) => {
          const productContent = this.syncedProductContentArray.find(
            (item) => item.productId === product.id
          );

          if (productContent && productContent.fileKeys?.length > 0) {
            productContent.fileKeys.forEach((fileKey) => {
              fileKeysToCheck.push({ fileKey, product });
            });
          } else {
            if (product.publishDate) {
              // console.log(
              //   `Product ${product.id} - No product content found or no file keys`
              // );
              this.failedProductsContent.push({
                id: product.id,
                title: product.title,
                fileKeys: [],
              });
            }
            updateProgress();
          }
        });

        const chunkSize = 10;
        for (
          let chunkStart = 0;
          chunkStart < fileKeysToCheck.length;
          chunkStart += chunkSize
        ) {
          const chunk = fileKeysToCheck.slice(
            chunkStart,
            chunkStart + chunkSize
          );

          const checkFilePromises = chunk.map(({ fileKey, product }) =>
            this.checkFileExistence(fileKey)
              .then((fileExists) => {
                return { fileKey, product, fileExists };
              })
              .catch(() => {
                return { fileKey, product, fileExists: false };
              })
          );

          const checkFileResults = await Promise.all(checkFilePromises);

          checkFileResults.forEach(({ fileKey, product, fileExists }) => {
            // console.log(
            //   `Product ${product.id} - File ${fileKey} exists: ${fileExists}`
            // );
            if (!fileExists) {
              const existingFailedProduct = this.failedProductsContent.find(
                (item) => item.id === product.id
              );

              if (existingFailedProduct) {
                existingFailedProduct.fileKeys.push(fileKey);
              } else {
                this.failedProductsContent.push({
                  id: product.id,
                  title: product.title,
                  fileKeys: [fileKey],
                });
              }
            }

            updateProgress();
          });
        }
      }

      // console.log("failedProductsContent", this.failedProductsContent);

      if (this.failedProductsContent.length > 0) {
        let position = this.headers.length - 2;
        this.headers.splice(position, 0, {
          text: "Missing files",
          value: "missingFiles",
          sortable: false,
        });

        this.products.sort((a, b) => {
          const missingFilesA = this.getMissingFilesCount(a.id) || 0; // default to 0 if undefined
          const missingFilesB = this.getMissingFilesCount(b.id) || 0; // default to 0 if undefined

          // Sorting by descending order (most missing files first)
          if (missingFilesA === missingFilesB) return 0;
          return missingFilesB - missingFilesA; // reverse the subtraction to sort most missing files first
        });
      }

      this.dialogProductContentVerify = true;
      this.searchMissingProductContent = true;
      this.queryProducts();
      this.loading = false;
    },

    async checkFileExistence(fileKey) {
      try {
        const url = await this.getFileUrl(fileKey);
        // console.log("url", url);
        const response = await axios
          .get(url, {
            headers: { Range: "bytes=0-0" },
          })
          .catch(() => {
            // console.error("Error checking file existence:", error);
            return false;
          });
        // console.log("response", response);
        return response?.status === 206 || false; // Partial Content response indicates file exists
      } catch (error) {
        // console.error("Error checking file existence:", error);
        if (error.response && error.response.status === 404) {
          return false; // File does not exist
        } else {
          // console.error("Error checking file existence:", error);
          return false; // Treat any other error as file not existing
        }
      }
    },

    async getFileUrl(fileKey) {
      try {
        const url = await Storage.get(fileKey, {
          level: "public",
        });
        return url;
      } catch (error) {
        console.error("Error getting file URL:", error);
      }
    },

    formatDateTime(date) {
      if (!date) return "";
      return new Date(date).toISOString().replace("T", " ").split("Z")[0];
    },

    async validateForm() {
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.validate();
      }, 0);
    },

    async addQuestion() {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        if (!mutableObj?.questions) mutableObj.questions = [];

        mutableObj.questions.push({
          type: "",
          label: "",
          value: "",
          hint: "",
          placeholder: "",
          items: [],
          required: false,
        });

        this.editedItem = _.cloneDeep(mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeQuestion(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions.splice(questionIndex, 1);

        this.editedItem = _.cloneDeep(mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    comboboxErrors(itemArray, questionType) {
      if (
        (questionType === "Select" || questionType === "Multi Select") &&
        itemArray.length < 1
      ) {
        return "Select values are required";
      } else if (questionType === "Radio" && itemArray.length < 1) {
        return "Radio values are required";
      } else if (questionType === "Checkbox" && itemArray.length < 1) {
        return "Checkbox values are required";
      } else return "";
    },

    changePhysEcommSubSearch(value) {
      if (value === "physical") {
        this.searchSubscriptionProduct = false;
      } else if (value === "sub") {
        this.searchPhysicalProduct = false;
      }

      this.queryProducts();
    },

    changePhysEcommSub(value) {
      if (value === "physical") {
        this.editedItem.subscriptionProduct = false;
      } else if (value === "sub") {
        this.editedItem.physical = false;
      }
    },

    changeBulkPhysEcommSub(value) {
      if (value === "physical") {
        this.bulkEditSubscriptionProduct = false;
      } else if (value === "sub") {
        this.bulkEditPhysicalProduct = false;
      }
    },

    async scheduleProducts() {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });
        if (immutableProduct) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.publishDate = this.bulkEditPublishDateTime;
            })
          );
        }

        if (i + 1 === this.selectedProducts.length) {
          this.savingSelectedProducts = false;
          this.bulkEditPublishDateTime = null;
          this.bulkEditPublishDate = "";
          this.bulkEditPublishTime = "";
          return;
        }
      });
    },

    async unScheduleProducts() {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });
        if (immutableProduct) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.publishDate = null;
            })
          );
        }

        if (i + 1 === this.selectedProducts.length) {
          this.savingSelectedProducts = false;
          return;
        }
      });
    },

    async changeProductsPrice(incOrDec) {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });

        const basePrice = immutableProduct.price || 0;

        const updatedPrice = parseFloat(
          (this.bulkEditPriceChangeValue
            ? incOrDec === "increase"
              ? basePrice + parseFloat(this.bulkEditPriceChangeValue)
              : basePrice - parseFloat(this.bulkEditPriceChangeValue)
            : incOrDec === "increase"
            ? basePrice +
              (basePrice * parseFloat(this.bulkEditPriceChangePercent)) / 100
            : basePrice -
              (basePrice * parseFloat(this.bulkEditPriceChangePercent)) / 100
          ).toFixed(2)
        );

        const newPrice =
          updatedPrice <= 0 && !immutableProduct.price
            ? null
            : updatedPrice <= 0
            ? 0
            : updatedPrice;

        // console.log("newPrice", newPrice);

        if (immutableProduct) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.price = newPrice;
            })
          );
        }

        if (i + 1 === this.selectedProducts.length) {
          this.bulkEditPriceChangePercent = null;
          this.bulkEditPriceChangeValue = null;
          this.savingSelectedProducts = false;
          return;
        }
      });
    },

    async changeProductsType() {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });

        if (immutableProduct) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.physical = this.bulkEditPhysicalProduct;
              updated.eCommerceProduct = this.bulkEditECommerceProduct;
              updated.subscriptionProduct = this.bulkEditSubscriptionProduct;
            })
          );
        }

        if (i + 1 === this.selectedProducts.length) {
          this.bulkEditPhysicalProduct = false;
          this.bulkEditECommerceProduct = false;
          this.bulkEditSubscriptionProduct = false;
          this.savingSelectedProducts = false;
          return;
        }
      });
    },

    async discountProducts() {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });

        const basePrice = immutableProduct.price || 0;

        const discount =
          basePrice === 0 || this.bulkEditDiscountPercent === "0"
            ? null
            : parseFloat(this.bulkEditDiscountPercent);

        console.log("discount", discount);

        if (immutableProduct) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.discountPercentage = discount;
            })
          );
        }

        if (i + 1 === this.selectedProducts.length) {
          this.bulkEditDiscountPercent = null;
          this.savingSelectedProducts = false;
          return;
        }
      });
    },

    async featureProducts() {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });
        if (immutableProduct && immutableProduct.featured !== true) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.featured = true;
            })
          );
        } else if (i + 1 === this.selectedProducts.length) {
          this.savingSelectedProducts = false;
          return;
        }

        if (i + 1 === this.selectedProducts.length) {
          this.savingSelectedProducts = false;
        }
      });
    },

    async unFeatureProducts() {
      this.savingSelectedProducts = true;

      await _.forEach(this.selectedProducts, async (selectedProduct, i) => {
        const immutableProduct = _.find(this.syncedProductArray, {
          id: selectedProduct.id,
        });
        if (immutableProduct && immutableProduct.featured !== false) {
          await DataStore.save(
            Product.copyOf(immutableProduct, (updated) => {
              updated.featured = false;
            })
          );
        } else if (i + 1 === this.selectedProducts.length) {
          this.savingSelectedProducts = false;
          return;
        }

        if (i + 1 === this.selectedProducts.length) {
          this.savingSelectedProducts = false;
        }
      });
    },
    getProductFileCount(productId) {
      const productContent = this.syncedProductContentArray.find(
        (content) => content.productId === productId
      );
      return productContent?.fileKeys?.length > 0
        ? productContent.fileKeys.length
        : 0;
    },

    getProductURLCount(productId) {
      const productContent = this.syncedProductContentArray.find(
        (content) => content.productId === productId
      );
      return productContent?.urls?.length > 0 ? productContent.urls.length : 0;
    },

    updateCategoryParent(val) {
      this.editedProductCategoryItem.parentId = val.length > 0 ? val[0] : "";
    },

    updateProductCategory(val) {
      this.editedItem.categoryId = val.length > 0 ? val[0] : "";
    },

    generateSlug() {
      // Function to generate slug from the name
      const slug = this.editedProductCategoryItem.name
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, "-") // Replace non-alphanumeric characters with -
        .replace(/-{2,}/g, "-") // Replace multiple - with single -
        .replace(/^-+|-+$/g, ""); // Trim - from start and end
      this.editedProductCategoryItem.slug = slug;
    },

    toggleMenu() {
      this.searchProductCategoriesMenu = !this.searchProductCategoriesMenu;
    },

    toggleEditProductMenu() {
      this.editProductCategoriesMenu = !this.editProductCategoriesMenu;
    },

    toggleEditCategoryParentMenu() {
      this.editCategoryParentMenu = !this.editCategoryParentMenu;
    },

    buildCategoryTree(categories, propCategoryId) {
      let tree = [];

      // Create a map of categories by their ID for quicker access
      const categoryMap = {};
      categories.forEach((category) => {
        categoryMap[category.id] = { ...category, children: [] };
      });

      // Function to recursively build the tree
      const buildTree = (categoryId) => {
        const category = categoryMap[categoryId];
        if (!category) return null; // Return null if category not found

        // Check if the category meets the condition to be added to the tree
        if (propCategoryId > "" && category.id === propCategoryId) {
          // Skip adding this category and its children
          return;
        }

        // Recursively build children
        if (category.parentId) {
          const parentCategory = categoryMap[category.parentId];
          if (parentCategory) {
            parentCategory.children.push(category);
          }
        } else {
          tree.push(category); // Add to top-level tree if no parent
        }
      };

      // Build tree for each category
      categories.forEach((category) => {
        buildTree(category.id);
      });

      return tree;
    },

    closeProductContentDelete() {
      this.productContentFileKeys = _.cloneDeep(
        this.productContentDatamodel.fileKeys
      );

      this.dialogProductContentDelete = false;
    },

    async deleteProductContent() {
      this.uploadingFiles = [];
      this.loading = true;

      for (const fileKey of this.productContentDatamodel.fileKeys) {
        if (!this.productContentFileKeys.includes(fileKey)) {
          await Storage.remove(fileKey, {
            level: "public",
          }).catch((err) => console.log(err));
        }
      }

      await DataStore.save(
        ProductContent.copyOf(this.productContentDatamodel, (content) => {
          content.fileKeys = this.productContentFileKeys;
        })
      ).catch((err) => console.log(err));

      this.dialogProductContentDelete = false;
      this.loading = false;
    },

    changeEditedItemProductContent() {
      if (
        this.productContentFileKeys.length <
          this.productContentDatamodel.fileKeys.length &&
        !this.loading
      ) {
        this.dialogProductContentDelete = true;
      }
    },

    updateProductPublishDateTime(action) {
      if (action === "clear") {
        this.productPublishDate = null;
        this.productPublishTime = null;
        this.editedItem.publishDate = null;
      } else if (this.productPublishDate && this.productPublishTime) {
        this.editedItem.publishDate = new Date(
          new Date(
            this.productPublishDate + "T" + this.productPublishTime + ":00Z"
          ).getTime() -
            2 * 60 * 60 * 1000
        ).toISOString();
      } else {
        this.editedItem.publishDate = null;
      }
    },

    updateBulkEditPublishDateTime(action) {
      if (action === "clear") {
        this.bulkEditPublishDate = null;
        this.bulkEditPublishTime = null;
        this.bulkEditPublishDateTime = null;
      } else if (this.bulkEditPublishDate && this.bulkEditPublishTime) {
        this.bulkEditPublishDateTime = new Date(
          new Date(
            this.bulkEditPublishDate + "T" + this.bulkEditPublishTime + ":00Z"
          ).getTime() -
            2 * 60 * 60 * 1000
        ).toISOString();
      } else {
        this.bulkEditPublishDateTime = null;
      }
    },

    closeDateTimeDialog() {
      this.datetimeDialog = false;
      this.dateTimeTab = "date";
    },

    closeBulkEditDateTimeDialog() {
      this.bulkEditDateTimeDialog = false;
      this.bulkEditDateTimeTab = "date";
    },

    cancelWarning() {
      this.dialogCancel = true;
    },

    customSortPrice(a, b) {
      const priceA = parseFloat(a);
      const priceB = parseFloat(b);
      if (this.sortDesc) {
        return priceA - priceB;
      }
      return priceB - priceA;
    },

    customSortProductString(a, b) {
      if (!this.sortDesc) {
        return a.localeCompare(b);
      }
      return b.localeCompare(a);
    },

    customSortProductTagName(a, b) {
      if (!this.tagSortDesc) {
        return a.localeCompare(b);
      }
      return b.localeCompare(a);
    },

    // customSortProductCategoryString(a, b) {
    //   const valueA = a ? a : "";
    //   const valueB = b ? b : "";
    //   console.log("valueA:", valueA);
    //   console.log("valueB:", valueB);
    //   if (!this.categorySortDesc) {
    //     return valueA.localeCompare(valueB);
    //   }
    //   return valueB.localeCompare(valueA);
    // },

    productsDataTablePageCountUpdate(val) {
      if (val !== 0) {
        this.productsDataTablePageCount = val; // Convert to 0-based index
        this.queryProducts();
      }
    },

    formatPercentage(percent) {
      // Ensure the price is a valid number
      if (percent === null || percent === undefined || isNaN(percent)) {
        return "";
      }

      return `${percent}%`;
    },

    formatPrice(price) {
      // Ensure the price is a valid number
      if (price === null || price === undefined || isNaN(price)) {
        return "";
      }

      // Convert the price to a fixed-point notation with 2 decimals
      const formattedPrice = parseFloat(price).toFixed(2);

      return `R ${formattedPrice}`;
    },

    formatCategoryId(categoryId) {
      const category = this.syncedProductCategoryArray.find(
        (category) => category.id === categoryId
      );

      if (category) {
        let parentCategories = [];

        if (category.parentId) {
          let currentCategory = category;
          while (currentCategory.parentId) {
            const parentCategory = this.syncedProductCategoryArray.find(
              (category) => category.id === currentCategory.parentId
            );
            if (parentCategory) {
              parentCategories.unshift(parentCategory.name);
              currentCategory = parentCategory;
            } else {
              break;
            }
          }
        }

        return parentCategories.length > 0
          ? parentCategories.join("/") + "/" + category.name
          : category.name;
      } else return "";
    },

    async deleteProductThumbnail(thumbnailKey) {
      // console.log("deleteProductThumbnail:", thumbnailKey);
      await Storage.remove(thumbnailKey, {
        level: "public",
      })
        .then(() => {
          const mutableThumbnails = _.cloneDeep(this.editedItem.thumbnails);
          _.remove(
            mutableThumbnails,
            (thumbnail) => thumbnail === thumbnailKey
          );
          this.editedItem.thumbnails = mutableThumbnails;

          _.remove(
            this.thumbnailImagesSrc,
            (thumbnail) => thumbnail.key === thumbnailKey
          );

          this.editedItem.searchField = JSON.stringify(
            _.values(_.omit(this.editedItem, this.productSearchExcludeFields))
          ).toLowerCase();

          DataStore.save(
            Product.copyOf(
              _.find(this.syncedProductArray, { id: this.editedItem.id }),
              (product) => {
                product.thumbnails = this.editedItem.thumbnails;
                product.searchField = this.editedItem.searchField;
              }
            )
          );
        })
        .catch((err) => console.log(err));
    },

    mouseOver(index) {
      if (!this.isProductImgMenuOpen) {
        this.productImgHoveringIndex = index;
      }
    },

    mouseOut() {
      if (!this.isProductImgMenuOpen) {
        this.productImgHoveringIndex = -1;
      }
    },

    mouseOutMenu() {
      this.isProductImgMenuOpen = false;
      this.productImgHoveringIndex = -1;
    },

    getThumbnailImageSrc(thumbnailKey) {
      const thumbnailImgSrc = _.find(this.thumbnailImagesSrc, {
        key: thumbnailKey,
      });
      return thumbnailImgSrc ? thumbnailImgSrc.src : "";
    },

    async getThumbnailImage(productThumbnailkey) {
      const thumbnailImgSrc = await Storage.get(productThumbnailkey, {
        level: "public",
      });
      return thumbnailImgSrc;
    },

    async uploadThumbnailImage() {
      try {
        const inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "image/*";
        // inputElement.multiple = true; // Allow multiple files to be selected

        inputElement.addEventListener("change", async (event) => {
          if (
            event.target instanceof HTMLInputElement &&
            event.target.files &&
            event.target.files.length > 0
          ) {
            this.loading = true;
            const file = event.target.files[0];
            const maxSize = 800; // Define your maximum size here

            // Create a function to resize the image
            const resizeImage = (image) => {
              return new Promise((resolve) => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const img = new Image();
                img.onload = () => {
                  let width = img.width;
                  let height = img.height;

                  if (width > height) {
                    if (width > maxSize) {
                      height *= maxSize / width;
                      width = maxSize;
                    }
                  } else {
                    if (height > maxSize) {
                      width *= maxSize / height;
                      height = maxSize;
                    }
                  }

                  canvas.width = width;
                  canvas.height = height;

                  ctx.drawImage(img, 0, 0, width, height);

                  canvas.toBlob(
                    (blob) => {
                      resolve(blob);
                    },
                    "image/jpeg",
                    0.9
                  ); // You can change the format and quality here
                };

                img.src = URL.createObjectURL(image);
              });
            };

            const resizedBlob = await resizeImage(file);

            // Upload the resized file to S3
            const newThumbnailImgPut = await Storage.put(
              "Products/" +
                this.editedItem.id +
                "/" +
                Date.now() +
                "-" +
                file.name,
              resizedBlob
            ).catch((err) => console.log(err));

            if (!this.editedItem.thumbnails) {
              this.editedItem.thumbnails = [];
            }

            if (!this.editedItem.thumbnails.includes(newThumbnailImgPut.key)) {
              const mutableThumbnails = _.cloneDeep(this.editedItem.thumbnails);

              mutableThumbnails.push(newThumbnailImgPut.key);
              this.editedItem.thumbnails = mutableThumbnails;

              // Fetch the image source for the newly added thumbnail
              const thumbnailImgSrc = await this.getThumbnailImage(
                newThumbnailImgPut.key
              );

              // Update the thumbnailImagesSrc array with the new source
              this.thumbnailImagesSrc.push({
                key: newThumbnailImgPut.key,
                src: thumbnailImgSrc,
              });

              this.editedItem.searchField = JSON.stringify(
                _.values(
                  _.omit(this.editedItem, this.productSearchExcludeFields)
                )
              ).toLowerCase();

              await DataStore.save(
                Product.copyOf(
                  _.find(this.syncedProductArray, { id: this.editedItem.id }),
                  (product) => {
                    product.thumbnails = this.editedItem.thumbnails;
                    product.searchField = this.editedItem.searchField;
                  }
                )
              )
                .catch((err) => {
                  console.log(err);
                })
                .then(() => {
                  this.unEditedProduct.thumbnails = _.cloneDeep(
                    this.editedItem.thumbnails
                  );
                  this.unEditedProduct.searchField =
                    this.editedItem.searchField;
                });
            }

            this.loading = false;
          }
        });

        document.body.appendChild(inputElement);
        inputElement.click();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async uploadProductContent() {
      try {
        if (!this.productContentDatamodel) {
          // Create ProductContent if it doesn't exist
          const newProductContent = await DataStore.save(
            new ProductContent({
              productId: this.editedItem.id,
            })
          );
          this.productContentDatamodel = newProductContent;

          //// CHECK
          this.productContentProductId = this.editedItem.id;
        }

        const inputElement = document.createElement("input");
        inputElement.type = "file";
        // inputElement.accept = "image/*";
        inputElement.multiple = true; // Allow multiple files to be selected

        inputElement.addEventListener("change", async (event) => {
          if (
            event.target instanceof HTMLInputElement &&
            event.target.files &&
            event.target.files.length > 0
          ) {
            this.loading = true;
            // const files = event.target.files;
            const files = Array.from(event.target.files); // Convert FileList to array

            // console.log("files", files);

            // return objects of file's name and percentageDownloaded = 0
            this.uploadingFiles = files.map((file) => ({
              name: file.name,
              percentageUploaded: 0,
            }));

            // console.log("this.uploadingFiles", this.uploadingFiles);

            let newProductContentKeyArray = [];

            for (let i = 0; i < files.length; i++) {
              const file = files[i];

              const newKey =
                "ProductsContent/" +
                this.productContentDatamodel.id +
                "/" +
                Date.now() +
                "-" +
                file.name;

              // console.log("newKey", newKey);

              // newProductContentKeyArray.push(newKey);

              await Storage.put(newKey, file, {
                progressCallback: (progress) => {
                  // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);

                  // Update the percentageUploaded for the current file
                  const percentageUploaded = Math.round(
                    (progress.loaded / progress.total) * 100
                  );
                  this.$set(this.uploadingFiles, i, {
                    ...this.uploadingFiles[i],
                    percentageUploaded,
                  });
                },
              })
                .catch((err) => console.log(err))
                .then(() =>
                  // response
                  {
                    // console.log(response);
                    newProductContentKeyArray.push(newKey);
                  }
                );
            }

            if (newProductContentKeyArray.length > 0) {
              const newFileKeys = [
                ...new Set([
                  ...(this.productContentFileKeys || []),
                  ...newProductContentKeyArray,
                ]),
              ];

              // Update ProductContent fileKeys
              await DataStore.save(
                ProductContent.copyOf(
                  this.productContentDatamodel,
                  (productContent) => {
                    productContent.fileKeys = newFileKeys;
                  }
                )
              ).catch((err) => console.log(err));
            }

            this.loading = false;
          }
        });

        document.body.appendChild(inputElement);
        inputElement.click();
      } catch (error) {
        console.log(error);

        this.loading = false;
      } finally {
        this.getProductContent();
      }
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.valid = false;
    },

    manageProductTags() {
      this.dialogManageProductTags = true;
    },

    createProductTag() {
      this.valid = false;
      this.dialogEditProductTag = true;
    },

    editProductTag(item, index) {
      this.editedProductTagIndex = index;
      this.editedProductTagItem = _.cloneDeep(item);
      this.dialogEditProductTag = true;
    },

    async saveProductTag() {
      this.loading = true;
      try {
        if (this.editedProductTagIndex === -1) {
          await DataStore.save(
            new ProductTag({
              name: this.editedProductTagItem.name.trim(),
            })
          );
        } else {
          await DataStore.save(
            ProductTag.copyOf(
              _.find(this.syncedProductTagArray, {
                id: this.editedProductTagItem.id,
              }),
              (updateModel) => {
                updateModel.name = this.editedProductTagItem.name.trim();
              }
            )
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.closeEditProductTag();
      }
    },

    deleteProductTag(item) {
      this.editedProductTagIndex = this.syncedProductTagArray.indexOf(item);
      this.dialogDeleteProductTag = true;
    },

    async deleteProductTagConfirm() {
      this.deletingItem = true;
      try {
        await DataStore.delete(
          this.syncedProductTagArray[this.editedProductTagIndex]
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.deletingItem = false;
        this.closeDeleteProductTag();
      }
    },

    manageProductCategories() {
      this.dialogManageProductCategories = true;
    },

    createProductCategory() {
      this.valid = false;
      this.dialogEditProductCategory = true;
    },

    editProductCategory(item, index) {
      this.editedProductCategoryIndex = index;
      this.editedProductCategoryItem = _.cloneDeep(item);
      this.dialogEditProductCategory = true;
    },

    async saveProductCategory() {
      this.loading = true;
      try {
        if (this.editedProductCategoryIndex === -1) {
          await DataStore.save(
            new ProductCategory({
              name: this.editedProductCategoryItem.name.trim(),
              slug: this.editedProductCategoryItem.slug.trim(),
              grade: this.editedProductCategoryItem.grade || null,
              parentId: this.editedProductCategoryItem.parentId,
            })
          );
        } else {
          await DataStore.save(
            ProductCategory.copyOf(
              _.find(this.syncedProductCategoryArray, {
                id: this.editedProductCategoryItem.id,
              }),
              (updateModel) => {
                (updateModel.name = this.editedProductCategoryItem.name.trim()),
                  (updateModel.slug =
                    this.editedProductCategoryItem.slug.trim()),
                  (updateModel.grade =
                    this.editedProductCategoryItem.grade || null),
                  (updateModel.parentId =
                    this.editedProductCategoryItem.parentId);
              }
            )
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.closeEditProductCategory();
      }
    },

    deleteProductCategory(item) {
      this.editedProductCategoryIndex =
        this.syncedProductCategoryArray.indexOf(item);
      this.dialogDeleteProductCategory = true;
    },

    async deleteProductCategoryConfirm() {
      this.deletingItem = true;
      try {
        await DataStore.delete(
          this.syncedProductCategoryArray[this.editedProductCategoryIndex]
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.deletingItem = false;
        this.closeDeleteProductCategory();
      }
    },

    createItem() {
      this.resetForm();
      this.editedItem.publishDate = new Date().toISOString();

      this.productPublishDate = this.editedItem.publishDate.substring(0, 10);
      this.productPublishTime = new Date(
        new Date(this.editedItem.publishDate).getTime() + 2 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(11, 16);
      this.dialog = true;
    },

    async duplicateItem(item) {
      try {
        this.loading = true;

        const dupEditedItem = _.cloneDeep(item);

        dupEditedItem.thumbnails = [];

        dupEditedItem.searchField = JSON.stringify(
          _.values(_.omit(dupEditedItem, this.productSearchExcludeFields))
        ).toLowerCase();

        const newProduct = await DataStore.save(
          new Product({
            title: dupEditedItem.title.trim() + " - COPY",
            description: dupEditedItem.description
              ? dupEditedItem.description.trim()
              : "",
            price:
              typeof dupEditedItem.price === "string"
                ? parseFloat(dupEditedItem.price)
                : dupEditedItem.price,
            discountPercentage:
              typeof dupEditedItem.discountPercentage === "string"
                ? parseFloat(dupEditedItem.discountPercentage)
                : dupEditedItem.discountPercentage,
            thumbnails: [],
            tags: dupEditedItem.tags?.length > 0 ? dupEditedItem.tags : [],
            questions: dupEditedItem.questions,
            publishDate: dupEditedItem.publishDate,
            featured: dupEditedItem.featured,
            physical: dupEditedItem.physical,
            subscriptionProduct: dupEditedItem.subscriptionProduct,
            eCommerceProduct: dupEditedItem.eCommerceProduct,
            searchField: dupEditedItem.searchField,
            categoryId: dupEditedItem.categoryId,
          })
        );

        // this.productContentProductId = newProduct.id;
        // console.log(
        //   "this.productContentProductId",
        //   this.productContentProductId
        // );

        const currentProductContentItem = this.syncedProductContentArray.find(
          (item) => item.productId === dupEditedItem.id
        );

        if (currentProductContentItem) {
          const newProductContentDup = await DataStore.save(
            new ProductContent({
              productId: newProduct.id,
              urls:
                currentProductContentItem.urls?.length > 0
                  ? currentProductContentItem.urls
                  : [],
            })
          );

          if (currentProductContentItem.fileKeys?.length > 0) {
            // for (const fileKey of currentProductContentItem.fileKeys) {
            //   await Storage.remove(fileKey, {
            //     level: "public",
            //   });
            // }
            let duplicatedFileKeys = []; // Initialize the array here
            await Promise.all(
              currentProductContentItem.fileKeys.map(async (fileKey) => {
                const newFileKey =
                  "ProductsContent/" +
                  newProductContentDup.id +
                  "/" +
                  fileKey.split("/").pop();

                const copiedFile = await Storage.copy(
                  { key: fileKey },
                  { key: newFileKey }
                );

                duplicatedFileKeys.push(copiedFile.key);
              })
            );

            await DataStore.save(
              ProductContent.copyOf(newProductContentDup, (updateModel) => {
                updateModel.fileKeys = duplicatedFileKeys;
              })
            );
          }
        } else {
          await DataStore.save(
            new ProductContent({
              productId: newProduct.id,
            })
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async editItem(item) {
      // console.log("editItem:", item);
      this.productContentProductId = item.id;

      this.getProductContent();

      this.editedIndex = this.products.indexOf(item);

      this.editedItem = _.cloneDeep(item);

      if (item.thumbnails) {
        item.thumbnails.forEach(async (thumbnailKey) => {
          if (!_.find(this.thumbnailImagesSrc, { key: thumbnailKey })) {
            const thumbnailImgSrc = await this.getThumbnailImage(thumbnailKey);
            this.thumbnailImagesSrc.push({
              key: thumbnailKey,
              src: thumbnailImgSrc,
            });
          }
        });
      }
      if (item.publishDate) {
        this.productPublishDate = item.publishDate.substring(0, 10);
        this.productPublishTime = new Date(
          new Date(item.publishDate).getTime() + 2 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(11, 16);
      }

      this.unEditedProduct = _.cloneDeep(this.editedItem);
      this.dialog = true;
    },

    deleteItem(item) {
      const itemToDelete = this.syncedProductArray.find(
        (product) => product.id === item.id
      );
      if (!itemToDelete) return;
      this.editedIndex = this.syncedProductArray.indexOf(itemToDelete);
      this.editedItem = _.cloneDeep(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.deletingItem = true;
      try {
        // this.products.splice(this.editedIndex, 1);
        await DataStore.delete(this.syncedProductArray[this.editedIndex]);

        for (const thumbnailKey of this.editedItem.thumbnails) {
          await Storage.remove(thumbnailKey, {
            level: "public",
          });
        }

        await DataStore.query(ProductContent, (c) =>
          c.productId("eq", this.editedItem.id)
        ).then(async (productContent) => {
          if (productContent.length > 0) {
            if (productContent[0].fileKeys?.length > 0) {
              for (const fileKey of productContent[0].fileKeys) {
                await Storage.remove(fileKey, {
                  level: "public",
                });
              }
            }

            await DataStore.delete(productContent[0]);
          }
        });
      } catch (error) {
        console.log(error);
      }

      this.deletingItem = false;
      this.closeDelete();
    },

    async cancel() {
      this.cancelingChanges = true;

      try {
        this.thumbnailImagesSrc.forEach(async (thumbnailImgSrc) => {
          const thumbnailKey = thumbnailImgSrc.key;

          if (!this.unEditedProduct.thumbnails.includes(thumbnailKey)) {
            _.remove(this.thumbnailImagesSrc, { key: thumbnailKey });

            await Storage.remove(thumbnailKey, {
              level: "public",
            });
          }
        });

        if (this.unEditedProduct.thumbnails) {
          this.unEditedProduct.thumbnails.forEach(async (thumbnailKey) => {
            if (!_.find(this.thumbnailImagesSrc, { key: thumbnailKey })) {
              const thumbnailImgSrc = await this.getThumbnailImage(
                thumbnailKey
              );
              this.thumbnailImagesSrc.push({
                key: thumbnailKey,
                src: thumbnailImgSrc,
              });
            }
          });
        }

        this.editedItem = _.cloneDeep(this.unEditedProduct);

        if (
          !this.editedItem.physical &&
          this.editedItem.questions?.length > 0
        ) {
          this.editedItem.questions = [];
        }

        if (this.editedItem.questions?.length > 0) {
          this.editedItem.questions.forEach((question) => {
            if (
              question.type !== "Select" &&
              question.type !== "Multi Select" &&
              question.type !== "Radio" &&
              question.type !== "Checkbox"
            ) {
              question.items = [];
            }
          });
        }

        this.editedItem.searchField = JSON.stringify(
          _.values(_.omit(this.editedItem, this.productSearchExcludeFields))
        ).toLowerCase();

        this.productContentUrls = _.cloneDeep(
          this.productContentDatamodel.urls
        );

        await DataStore.save(
          Product.copyOf(
            _.find(this.syncedProductArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.title = this.editedItem.title.trim()),
                (updateModel.description = this.editedItem.description
                  ? this.editedItem.description.trim()
                  : ""),
                (updateModel.price =
                  typeof this.editedItem.price === "string"
                    ? parseFloat(this.editedItem.price)
                    : this.editedItem.price),
                (updateModel.discountPercentage =
                  typeof this.editedItem.discountPercentage === "string"
                    ? parseFloat(this.editedItem.discountPercentage)
                    : this.editedItem.discountPercentage),
                (updateModel.thumbnails = this.editedItem.thumbnails),
                (updateModel.tags =
                  this.editedItem.tags?.length > 0 ? this.editedItem.tags : []),
                (updateModel.questions = this.editedItem.questions),
                (updateModel.publishDate = this.editedItem.publishDate),
                (updateModel.featured = this.editedItem.featured),
                (updateModel.physical = this.editedItem.physical),
                (updateModel.subscriptionProduct =
                  this.editedItem.subscriptionProduct),
                (updateModel.eCommerceProduct =
                  this.editedItem.eCommerceProduct),
                (updateModel.searchField = this.editedItem.searchField),
                (updateModel.categoryId = this.editedItem.categoryId);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }

      this.$nextTick(() => {
        this.dialogCancel = false;
        this.cancelingChanges = false;
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
        this.thumbnailImagesSrc = _.cloneDeep([]);
        this.productPublishDate = "";
        this.productPublishTime = "";
        this.unEditedProduct = {};
        this.productContentDatamodel = null;
        this.productContentFileKeys = [];
        this.productContentUrls = [];
        this.productContentProductId = "";
        this.uploadingFiles = [];
        // this.syncedProductContentArray = [];
      });
    },

    closeManageProductTags() {
      this.dialogManageProductTags = false;
    },

    closeDeleteProductTag() {
      this.dialogDeleteProductTag = false;
      this.$nextTick(() => {
        this.editedProductTagIndex = -1;
      });
    },

    closeEditProductTag() {
      this.dialogEditProductTag = false;
      this.$nextTick(() => {
        this.editedProductTagItem = _.cloneDeep(this.defaultProductTagItem);
        this.editedProductTagIndex = -1;
      });
    },

    closeManageProductCategories() {
      this.dialogManageProductCategories = false;
    },

    closeDeleteProductCategory() {
      this.dialogDeleteProductCategory = false;
      this.$nextTick(() => {
        this.editedProductCategoryIndex = -1;
      });
    },

    closeEditProductCategory() {
      this.dialogEditProductCategory = false;
      this.$nextTick(() => {
        this.editedProductCategoryItem = _.cloneDeep(
          this.defaultProductCategoryItem
        );
        this.editedProductCategoryIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
        this.thumbnailImagesSrc = _.cloneDeep([]);
        this.productPublishDate = "";
        this.productPublishTime = "";
        this.productContentDatamodel = null;
        this.productContentFileKeys = [];
        this.productContentUrls = [];
        this.productContentProductId = "";
        // this.syncedProductContentArray = [];
      });
    },

    closeProductContentVerify() {
      this.dialogProductContentVerify = false;
      // this.$nextTick(() => {
      //   this.failedProductsContent = [];
      // });
    },

    closeCancel() {
      this.dialogCancel = false;
    },

    async save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateProduct();
      } else {
        // Create new schedule
        await this.createProduct();
      }

      this.unEditedProduct = _.cloneDeep(this.editedItem);

      this.$nextTick(() => {
        this.loading = false;
      });
    },

    async createProduct() {
      try {
        if (
          !this.editedItem.physical &&
          this.editedItem.questions?.length > 0
        ) {
          this.editedItem.questions = [];
        }

        if (this.editedItem.questions?.length > 0) {
          this.editedItem.questions.forEach((question) => {
            if (
              question.type !== "Select" &&
              question.type !== "Multi Select" &&
              question.type !== "Radio" &&
              question.type !== "Checkbox"
            ) {
              question.items = [];
            }
          });
        }

        this.editedItem.searchField = JSON.stringify(
          _.values(_.omit(this.editedItem, this.productSearchExcludeFields))
        ).toLowerCase();

        const newProduct = await DataStore.save(
          new Product({
            title: this.editedItem.title.trim(),
            description: this.editedItem.description
              ? this.editedItem.description.trim()
              : "",
            price:
              typeof this.editedItem.price === "string"
                ? parseFloat(this.editedItem.price)
                : this.editedItem.price,
            discountPercentage:
              typeof this.editedItem.discountPercentage === "string"
                ? parseFloat(this.editedItem.discountPercentage)
                : this.editedItem.discountPercentage,
            thumbnails: [],
            tags: this.editedItem.tags?.length > 0 ? this.editedItem.tags : [],
            questions: this.editedItem.questions,
            publishDate: this.editedItem.publishDate,
            featured: this.editedItem.featured,
            physical: this.editedItem.physical,
            subscriptionProduct: this.editedItem.subscriptionProduct,
            eCommerceProduct: this.editedItem.eCommerceProduct,
            searchField: this.editedItem.searchField,
            categoryId: this.editedItem.categoryId,
          })
        );

        this.productContentProductId = newProduct.id;

        const newProductContentObj = await DataStore.save(
          new ProductContent({
            productId: newProduct.id,
          })
        );

        this.editedItem.id = newProduct.id;
        this.editedIndex = 0;
        this.getProductContent(newProductContentObj);
      } catch (error) {
        console.log(error);
      }
    },

    async updateProduct() {
      try {
        let urlsEqual = true;
        if (this.productContentDatamodel?.urls?.length > 0) {
          urlsEqual = _.isEqual(
            this.productContentUrls,
            this.productContentDatamodel.urls
          );
        } else {
          urlsEqual = false;
        }

        if (!urlsEqual) {
          if (!this.productContentDatamodel) {
            // Create ProductContent if it doesn't exist
            const newProductContentObj = await DataStore.save(
              new ProductContent({
                productId: this.editedItem.id,
                urls:
                  this.productContentUrls.length === 0
                    ? null
                    : this.productContentUrls,
              })
            );
            this.productContentDatamodel = newProductContentObj;

            // // CHECK
            this.productContentProductId = this.editedItem.id;

            this.getProductContent(newProductContentObj);
          } else {
            await DataStore.save(
              ProductContent.copyOf(this.productContentDatamodel, (c) => {
                c.urls =
                  this.productContentUrls.length === 0
                    ? null
                    : this.productContentUrls;
              })
            );
          }
        }

        if (!_.isEqual(this.editedItem, this.unEditedProduct)) {
          if (
            !this.editedItem.physical &&
            this.editedItem.questions?.length > 0
          ) {
            this.editedItem.questions = [];
          }

          if (this.editedItem.questions?.length > 0) {
            this.editedItem.questions.forEach((question) => {
              if (
                question.type !== "Select" &&
                question.type !== "Multi Select" &&
                question.type !== "Radio" &&
                question.type !== "Checkbox"
              ) {
                question.items = [];
              }
            });
          }

          this.editedItem.searchField = JSON.stringify(
            _.values(_.omit(this.editedItem, this.productSearchExcludeFields))
          ).toLowerCase();

          await DataStore.save(
            Product.copyOf(
              _.find(this.syncedProductArray, { id: this.editedItem.id }),
              (updateModel) => {
                (updateModel.title = this.editedItem.title.trim()),
                  (updateModel.description = this.editedItem.description
                    ? this.editedItem.description.trim()
                    : ""),
                  (updateModel.price =
                    typeof this.editedItem.price === "string"
                      ? parseFloat(this.editedItem.price)
                      : this.editedItem.price),
                  (updateModel.discountPercentage =
                    typeof this.editedItem.discountPercentage === "string"
                      ? parseFloat(this.editedItem.discountPercentage)
                      : this.editedItem.discountPercentage),
                  (updateModel.thumbnails = this.editedItem.thumbnails),
                  (updateModel.tags =
                    this.editedItem.tags?.length > 0
                      ? this.editedItem.tags
                      : []),
                  (updateModel.questions = this.editedItem.questions),
                  (updateModel.publishDate = this.editedItem.publishDate),
                  (updateModel.featured = this.editedItem.featured),
                  (updateModel.physical = this.editedItem.physical),
                  (updateModel.subscriptionProduct =
                    this.editedItem.subscriptionProduct),
                  (updateModel.eCommerceProduct =
                    this.editedItem.eCommerceProduct),
                  (updateModel.searchField = this.editedItem.searchField),
                  (updateModel.categoryId = this.editedItem.categoryId);
              }
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    getProductContent(productContentDMProp) {
      try {
        this.loading = true;

        if (productContentDMProp) {
          this.productContentDatamodel = productContentDMProp;
          this.productContentFileKeys =
            productContentDMProp.fileKeys?.length > 0
              ? _.cloneDeep(productContentDMProp.fileKeys)
              : [];

          let productContentUrlsArray =
            productContentDMProp.urls?.length > 0
              ? _.cloneDeep(productContentDMProp.urls)
              : [];

          this.productContentUrls = productContentUrlsArray;
        } else if (this.productContentProductId !== "") {
          const currentProductContentItem = this.syncedProductContentArray.find(
            (item) => item.productId === this.productContentProductId
          );

          if (currentProductContentItem) {
            const productContentModel = currentProductContentItem;
            this.productContentDatamodel = productContentModel;
            this.productContentFileKeys =
              productContentModel.fileKeys?.length > 0
                ? _.cloneDeep(productContentModel.fileKeys)
                : [];

            let productContentUrlsArray =
              productContentModel.urls?.length > 0
                ? _.cloneDeep(productContentModel.urls)
                : [];

            this.productContentUrls = productContentUrlsArray;
          }
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async queryProducts() {
      try {
        const search = this.productsDataTableSearch.split(" ");
        this.selectedProducts = [];

        const searchMissingProductContentProductIds =
          this.failedProductsContent.map((product) => product.id);

        const productCount = (
          await DataStore.query(Product, (product) =>
            product.and((f) => [
              this.productsDataTableSearchTags.every((tag) =>
                f.tags("contains", tag)
              ),
              f.or((f) =>
                this.productsDataTableSearchCategories.reduce(
                  (c, id) => c.categoryId("eq", id),
                  f
                )
              ),
              this.searchMissingProductContent
                ? f.or((f) =>
                    searchMissingProductContentProductIds.reduce(
                      (c, id) => c.id("eq", id),
                      f
                    )
                  )
                : f,
              search
                .map((str) => str.toLowerCase())
                .every((string) => f.searchField("contains", string)),

              this.searchECommerceProduct ? f.eCommerceProduct("eq", true) : f,
              this.searchPhysicalProduct ? f.physical("eq", true) : f,
              this.searchSubscriptionProduct
                ? f.subscriptionProduct("eq", true)
                : f,
            ])
          )
        ).length;

        this.productsDataTableServerItemsLength = productCount || 0;

        const filterObj = {
          page: this.productsDataTablePageCount - 1,
          limit: this.productsDataTableRowsPerPage,
          sort: (s) =>
            s[this.sortBy](
              this.sortDesc ? SortDirection.DESCENDING : SortDirection.ASCENDING
            ),
        };

        if (this.productsDataTableRowsPerPage === -1) {
          delete filterObj.page;
          delete filterObj.limit;
        }

        const products = await DataStore.query(
          Product,
          (product) =>
            product.and((f) => [
              this.productsDataTableSearchTags.every((tag) =>
                f.tags("contains", tag)
              ),
              f.or((f) =>
                this.productsDataTableSearchCategories.reduce(
                  (c, id) => c.categoryId("eq", id),
                  f
                )
              ),
              this.searchMissingProductContent
                ? f.or((f) =>
                    searchMissingProductContentProductIds.reduce(
                      (c, id) => c.id("eq", id),
                      f
                    )
                  )
                : f,
              search
                .map((str) => str.toLowerCase())
                .every((string) => f.searchField("contains", string)),

              this.searchECommerceProduct ? f.eCommerceProduct("eq", true) : f,
              this.searchPhysicalProduct ? f.physical("eq", true) : f,
              this.searchSubscriptionProduct
                ? f.subscriptionProduct("eq", true)
                : f,
            ]),
          filterObj
        );

        this.products = _.cloneDeep(products);

        this.productSubscriptionSynced = true;
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
    if (this.productContentSubscription) {
      this.productContentSubscription.unsubscribe();
    }
  },
};
</script>

<style>
.productsSearchInputCSS .v-input__slot {
  margin-bottom: 0px !important;
}

.productsSearchInputCSS .v-select__selections {
  min-height: 36px !important;
}

.thumbnailvrow {
  margin-left: -12px;
  margin-right: -12px;
}

/* #productsDataTable .v-data-footer__pagination {
  visibility: hidden;
}
#productsDataTable .v-data-footer__select {
  visibility: hidden;
} */

.t-datetime-picker {
  .v-tabs-slider-wrapper {
    top: 0;
  }
  .v-picker__title {
    height: 90px;
  }
  .v-time-picker-title__time * {
    font-size: 60px;
    height: 60px;
  }
  .v-picker__body {
    height: 290px;
  }
  .v-tabs-items {
    height: 380px;
  }
}

#expPanelContent > * {
  padding: 12px !important;
}

.plannerPanelTopBorder > div:nth-child(2) {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active + div {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--next-active {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active {
  border-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active #expPanelContent {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
